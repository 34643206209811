import type { ColorParams } from '@bynder-studio/misc';
import { LineCapTypes } from '../../../Enums/LineCapTypes';
import { BorderAlignmentTypes } from '../../../Enums/BorderAlignmentTypes';
import { SvgCompElement, SvgCompElementParams } from './SvgCompElement';

export type ShapeCompElementParams = SvgCompElementParams & {
    borderColor?: ColorParams | null;
    borderWidth?: number | null;
    borderAlignment?: BorderAlignmentTypes | null;
    borderLineCap?: LineCapTypes | null;
};

export class ShapeCompElement extends SvgCompElement {
    borderColor?: ColorParams | null = null;

    borderWidth?: number | null = null;

    borderAlignment?: BorderAlignmentTypes | null = null;

    borderLineCap?: LineCapTypes | null = null;

    setProperties(params: ShapeCompElementParams) {
        super.setProperties(params);
        this.borderColor = params.borderColor;
        this.borderWidth = params.borderWidth;
        this.borderAlignment = params.borderAlignment;
        this.borderLineCap = params.borderLineCap;
    }

    getLocalHashData() {
        return {
            ...super.getLocalHashData(),
            borderColor: this.borderColor,
            borderWidth: this.borderWidth,
            borderAlignment: this.borderAlignment,
            borderLineCap: this.borderLineCap,
        };
    }

    requiresIntermediateLayer(): boolean {
        const hasOpacity = (color: ColorParams) =>
            color.type === 'SOLID' ? color.opacity !== 1 : color.stops.some((stop) => stop.opacity !== 1);

        return !!(
            !!this.dropShadow ||
            hasOpacity(this.borderColor) ||
            hasOpacity(this.color) ||
            super.requiresIntermediateLayer()
        );
    }

    toObject() {
        return {
            ...super.toObject(),
            type: 'SHAPE',
            borderColor: this.borderColor,
            borderWidth: this.borderWidth,
            borderAlignment: this.borderAlignment,
            borderLineCap: this.borderLineCap,
        };
    }
}
