import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import type { BrowserOptions } from '@sentry/react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import appVersion from '!!raw-loader!../app-version';

export const initSentry = () => {
    if (window.location.hostname === 'localhost') {
        return;
    }

    const client = Sentry.getCurrentHub().getClient();

    if (client && client.getOptions().dsn === SENTRY_DSN) {
        return;
    }

    Sentry.init({
        environment: ENVIRONMENT,
        release: `studio@${appVersion}`,
        dist: appVersion,
        dsn: SENTRY_DSN,
        autoSessionTracking: true,
        integrations: [browserTracingIntegration(), Sentry.captureConsoleIntegration({ levels: ['error'] })],
        tracesSampleRate: 1.0,
        beforeSend(event) {
            if (event.breadcrumbs) {
                event.breadcrumbs.forEach((breadcrumb) => {
                    if (breadcrumb.category === 'fetch' && breadcrumb.data.url.includes('data:application/wasm;')) {
                        breadcrumb.data.url = 'data:application/wasm;(REPLACED_MANUALLY_FOR_MEMORY_SAVE)';
                    }
                });
            }

            return event;
        },
        beforeSendTransaction(event) {
            if (event.spans) {
                event.spans.forEach((span) => {
                    if (span.op === 'http.client' && span?.data?.url?.includes('data:application/wasm;base64')) {
                        span.data.url = 'data:application/wasm;(REPLACED_MANUALLY_FOR_MEMORY_SAVE)';
                        span.data.http.url = 'data:application/wasm;(REPLACED_MANUALLY_FOR_MEMORY_SAVE)';
                    }
                });
            }

            return event;
        },
    } as BrowserOptions);
};
