import React from 'react';
import { HslaColor, HslColor, HslColorPicker } from 'react-colorful';
import styled from 'styled-components';
import { token } from '@bynder/design-system';
import { useThrottledCallback } from './hooks';

type Props = {
    value: HslColor | HslaColor;
    onChange: (color: HslColor | HslaColor) => void;
};

export function Saturation(props: Props) {
    function onChange(newColor: HslColor) {
        props.onChange({ ...props.value, s: newColor.s, l: newColor.l });
    }

    const handleChange = useThrottledCallback(onChange, 10);

    return <SaturationPicker color={props.value} onChange={handleChange} />;
}

const SaturationPicker = styled(HslColorPicker)`
    .react-colorful__saturation {
        height: 156px;
        margin-bottom: ${token.spacing6};
        border-bottom: none;
        border-radius: ${token.radiusBase} ${token.radiusBase} 0 0;
        box-shadow: none;
    }

    .react-colorful__hue {
        display: none;
    }

    .react-colorful__interactive:focus .react-colorful__pointer {
        transform: translate(-50%, -50%);
    }

    .react-colorful__interactive:active .react-colorful__pointer {
        width: 20px;
        height: 20px;
        border-width: 6px;
    }
`;
