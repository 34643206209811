import React from 'react';
import { Table } from '@bynder/design-system';
import { getFormattedTime } from 'packages/pages/components/card/utils';
import { Creative } from 'packages/store/creatives/types';
import { Template } from 'packages/store/templates/types';

const DurationCell = ({ creativeTypeEntity }: { creativeTypeEntity: Creative | Template }) => (
    <Table.Cell textFlow="no-wrap">{getFormattedTime(creativeTypeEntity)}</Table.Cell>
);

export default DurationCell;
