import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Flex, ModalBase, Thumbnail, token } from '@bynder/design-system';
import { IconDownload } from '@bynder/icons';
import useQueryParams from 'packages/hooks/useQueryParams';
import filtersStorage from 'packages/hooks/filtersStorage';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { catchResponseError } from 'packages/helpers/helpers';
import { PageWithAppcues, sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import features from '~/configs/features';
import { sendAmplitudeExportEvent } from '~/store/amplitude/actions';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import {
    ALL_EXPORTS,
    BACK_TO_VARIATIONS,
    CANCEL,
    COMPLETED,
    CONTINUE,
    DONE,
    DOWNLOAD_ALL,
    START_DOWNLOAD,
    START_EXPORT,
    TYPE_AND_SIZE_DOT_SEPARATOR,
} from '~/helpers/textConstants';
import CreativesService from '~/services/CreativesService';
import AllExports, { filterTypes } from '../AllExports';
import { ExportNames, ExportTypes, Tabs } from '../enums';
import RenditionsService from '~/services/RenditionsService';
import TabIcon from '../components/TabIcon';
import SelectFormat from '../SelectFormat/SelectFormat';
import TabTitle from '../components/TabTitle';
import { Filters } from '../Filters';
import { List } from '../List';
import useExport from '../hooks/useExport';
import ExportModalSidebar from './ExportModalSidebar';
import useExportModalTabs, { TAB_QUERY_PARAM } from './useExportModalTab';
import { DeselectAll } from 'packages/variation-export/Header/DeselectAll';
import { useFriendlyTime } from '~/hooks/useFriendlyTime';
import { ExportItemSubtitleSeparator } from 'packages/variation-export/AllExports/AllExports.styled';
import { download, makeUrlToDownload } from 'packages/variation-export/utils';
import { ModalBaseHeaderWrapper } from 'packages/variation-export/Modal/ExportModal.styled';
import modalContainer from 'packages/common/modalContainer';
import { useTranslate } from '@bynder/localization';

const ExportModal = ({ isOpen, onClose, onContinue, isQuickIntegrationMode = false }) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();

    const { getAllQueryParams, restoreQueryParams } = useQueryParams();
    const { getFiltersFromStorage, setFiltersToStorage } = filtersStorage();

    const {
        selectedCount,
        selectedIds,
        setSelectedIds,
        items,
        selectAll,
        isWaitingRoomBlocked,
        isDownloadBlocked,
        selectedJob,
        setSelectedJob,
    } = useExport();
    const { getFriendlyTime } = useFriendlyTime();
    const { creativeId, creativeType } = useDesign();
    const { companies, customerId } = useSelector((state) => ({
        companies: state.user.companies || [],
        customerId: state.user.currentCompany,
    }));

    const { resetQueryParams } = useQueryParams();

    const hasMultiVariationFeature = AuthorizationHelper.isFeatureAvailable(features.MULTI_VARIATION);
    const currentCompany = useMemo(
        () => companies.find((company) => company.id === customerId),
        [companies, customerId],
    );
    const [tab, setTab] = useExportModalTabs();

    // todo: check
    const [isLoadingRenditions, setIsLoadingRenditions] = useState(true);
    const [renditionProfiles, setRenditionProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [isExporting, setIsExporting] = useState(false);
    const [isInExportStep, setInExportStep] = useState(false);
    const [isJobsInProgress, setIsJobsInProgress] = useState(false);
    const location = useLocation();

    const variationIds = useMemo(() => Object.keys(selectedIds), [selectedIds]);
    const prevSelectedIds = useMemo(() => selectedIds, [isOpen]);

    const handleClose = () => {
        onClose();
        setSelectedIds(prevSelectedIds);
    };

    const getAmplitudeData = () => {
        let variationAmount = 0;
        const variationsSizes = {};
        items.forEach((item) =>
            item.entries.forEach((entry) => {
                if (selectedIds[entry.variationId]) {
                    const sizeKey = `${entry.sizeWidth}x${entry.sizeHeight}`;
                    variationsSizes[sizeKey] = true;
                    variationAmount++;
                }
            }),
        );

        return { variationAmount, variationSizes: Object.keys(variationsSizes) };
    };

    const handleExportGeneration = useCallback(() => {
        const payload = {
            name: ExportNames[tab],
            type: ExportTypes[tab],
            variationIds,
        };

        switch (tab) {
            case Tabs.WaitingRoom:
                payload.renditionConfigurationId = selectedProfile?.id;
                payload.bynderAccountId = currentCompany?.bynderAccountId;
                break;
            case Tabs.Download:
                payload.renditionConfigurationId = selectedProfile?.id;
                break;
            default:
        }

        setIsExporting(true);
        const amplitudeData = {
            exportChannel: payload.type,
            exportFormat: selectedProfile.displayName,
            ...getAmplitudeData(),
        };
        dispatch(
            sendAmplitudeExportEvent({
                eventType: AMPLITUDE_TYPES.NEW_EXPORT,
                additionalProps: amplitudeData,
            }),
        );
        CreativesService.createExportJob(creativeId, payload)
            .then(({ status }) => {
                if (status === 200) {
                    setTab(Tabs.AllExports);
                    sendAppcuesEvent('Export job created', {
                        variationIds: payload.variationIds,
                        name: payload.name,
                        type: payload.type,
                    });
                }
            })
            .catch(catchResponseError)
            .finally(() => {
                setIsExporting(false);
            });
    }, [
        hasMultiVariationFeature,
        selectAll,
        tab,
        variationIds,
        selectedProfile,
        creativeId,
        customerId,
        companies,
        selectedIds,
    ]);

    const visibleTabs = useMemo(
        () => ({
            [ExportTypes[Tabs.Download]]: true,
            [ExportTypes[Tabs.WaitingRoom]]: currentCompany.type === 'BYNDER',
        }),
        [filterTypes, currentCompany.type],
    );

    const generateExportModal = (name) => {
        switch (name) {
            case Tabs.WaitingRoom:
            case Tabs.Download:
                return (
                    <PageWithAppcues>
                        <SelectFormat
                            isLoading={isLoadingRenditions}
                            renditionProfiles={renditionProfiles}
                            selectedProfile={selectedProfile}
                            onChange={setSelectedProfile}
                            isDisabled={isExporting}
                        />
                    </PageWithAppcues>
                );
            case Tabs.AllExports:
                return (
                    <PageWithAppcues>
                        <AllExports setIsJobsInProgress={setIsJobsInProgress} visibleTabs={visibleTabs} />
                    </PageWithAppcues>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        RenditionsService.getRenditionProfiles(customerId)
            .then((res) => {
                if (res.status !== 200) {
                    return;
                }

                const filteredRenditions = res.renditionProfiles
                    .filter((p) => p.creativeType === creativeType)
                    .flatMap((p) => p.configurations)
                    .filter(
                        (configuration, index, self) =>
                            index === self.findIndex((item) => item.id === configuration.id),
                    );

                let sortedRenditions = filteredRenditions;

                if (sortedRenditions.length) {
                    sortedRenditions.sort((a, b) => {
                        if (!a.displayOrder && !b.displayOrder) return 0;
                        if (a.displayOrder && !b.displayOrder) return -1;
                        if (!a.displayOrder && b.displayOrder) return 1;

                        return a.displayOrder - b.displayOrder;
                    });

                    setSelectedProfile(sortedRenditions[0]);
                }

                setRenditionProfiles(sortedRenditions);
                setIsLoadingRenditions(false);
            })
            .catch(catchResponseError);
    }, [dispatch, customerId, creativeType]);

    useEffect(() => {
        if (!hasMultiVariationFeature) {
            setInExportStep(true);
        }
    }, [hasMultiVariationFeature]);

    useEffect(() => {
        if (!hasMultiVariationFeature && isOpen) selectAll(true);
    }, [isOpen]);

    useLayoutEffect(() => {
        const storedQueries = getFiltersFromStorage('download');
        restoreQueryParams(storedQueries);

        return () => resetQueryParams([TAB_QUERY_PARAM, 'export', 'search', 'groupBy', 'orderBy', 'view']);
    }, []);

    useEffect(() => {
        if (location.search.includes('tab=download') && location.search.includes('export=true')) {
            setFiltersToStorage('download', getAllQueryParams());
        }
    }, [getAllQueryParams()]);

    const isContinueBlocked = useMemo(() => {
        if (tab === Tabs.Download && isDownloadBlocked) {
            return true;
        }

        if (tab === Tabs.WaitingRoom && isWaitingRoomBlocked) {
            return true;
        }

        return false;
    }, [isDownloadBlocked, isWaitingRoomBlocked, tab]);

    const disabledTabs = useMemo(
        () => ({
            [Tabs.Download]: isDownloadBlocked,
            [Tabs.WaitingRoom]: isWaitingRoomBlocked,
            [Tabs.AllExports]: false,
        }),
        [isDownloadBlocked, isWaitingRoomBlocked],
    );

    useEffect(() => {
        if (tab === Tabs.Download && isDownloadBlocked && !isWaitingRoomBlocked) {
            setTab(Tabs.WaitingRoom);
        }
        if (tab === Tabs.WaitingRoom && isWaitingRoomBlocked && !isDownloadBlocked) {
            setTab(Tabs.Download);
        }
    }, [tab, isDownloadBlocked, isWaitingRoomBlocked]);

    useEffect(() => {
        if (tab !== Tabs.AllExports && selectedJob) {
            setSelectedJob(null);
        }
    }, [tab]);

    return (
        <ModalBase
            container={modalContainer}
            isOpen={isOpen}
            onClose={handleClose}
            size={!hasMultiVariationFeature ? 'medium' : 'fullscreen'}
        >
            {isInExportStep && tab !== Tabs.AllExports && (
                <ModalBase.Header
                    title={<TabTitle tab={tab} />}
                    secondaryInfo={
                        hasMultiVariationFeature
                            ? `${selectedCount} ${creativeType.toLowerCase()}${selectedCount === 1 ? '' : 's'}`
                            : undefined
                    }
                    thumbnail={
                        <Thumbnail
                            icon={<TabIcon tab={tab} />}
                            backgroundColor={token.gray50a}
                            iconColor={token.gray600}
                        />
                    }
                    backButton={
                        hasMultiVariationFeature
                            ? { title: BACK_TO_VARIATIONS, onClick: () => setInExportStep(false) }
                            : undefined
                    }
                />
            )}
            {!isInExportStep && tab !== Tabs.AllExports && (
                <ModalBase.Header
                    backButton={isQuickIntegrationMode && { onClick: handleClose }}
                    title={
                        <Flex gap="4" alignItems="center">
                            {selectedCount === 0 ? 'Select items for export' : `${selectedCount} selected`}
                            <DeselectAll />
                        </Flex>
                    }
                />
            )}
            {tab === Tabs.AllExports &&
                (selectedJob ? (
                    <ModalBaseHeaderWrapper>
                        <ModalBase.Header
                            title={selectedJob.name}
                            secondaryInfo={
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    className="secondary-info-wrapper"
                                >
                                    <span>
                                        {selectedJob.jobVariations.length} {creativeType.toLowerCase()}
                                        {selectedJob.jobVariations.length > 1 ? 's' : ''}
                                        <ExportItemSubtitleSeparator>
                                            {TYPE_AND_SIZE_DOT_SEPARATOR}
                                        </ExportItemSubtitleSeparator>
                                        {`${COMPLETED} ${getFriendlyTime(selectedJob.updated)}`}
                                    </span>
                                    <Button
                                        className="download-all-btn"
                                        isDisabled={isDownloadBlocked}
                                        variant="secondary"
                                        icon={<IconDownload />}
                                        onClick={download(makeUrlToDownload(selectedJob.id))}
                                    >
                                        {DOWNLOAD_ALL}
                                    </Button>
                                </Flex>
                            }
                            thumbnail={
                                <Thumbnail
                                    icon={<TabIcon tab={selectedJob.type.toLowerCase()} />}
                                    backgroundColor={token.gray50a}
                                    iconColor={token.gray600}
                                />
                            }
                            backButton={{ onClick: () => setSelectedJob(null) }}
                        />
                    </ModalBaseHeaderWrapper>
                ) : (
                    <ModalBase.Header title={ALL_EXPORTS} />
                ))}
            {!isQuickIntegrationMode && (
                <ModalBase.Sidebar>
                    <ExportModalSidebar
                        selectedTab={tab}
                        setSelectedTab={setTab}
                        visibleTabs={visibleTabs}
                        disabledTabs={disabledTabs}
                    />
                </ModalBase.Sidebar>
            )}
            <ModalBase.Content>
                {tab === Tabs.AllExports || isInExportStep ? (
                    generateExportModal(tab)
                ) : (
                    <div id="export_modal_body">
                        <Filters />
                        <List />
                    </div>
                )}
            </ModalBase.Content>
            <ModalBase.Footer
                actionPrimary={
                    isQuickIntegrationMode ? (
                        <Button
                            variant="primary"
                            onClick={onContinue}
                            disabled={!variationIds.length}
                            title={translate('modal.export.save-selection')}
                        >
                            {translate('modal.export.save-selection')}
                        </Button>
                    ) : tab === Tabs.AllExports ? (
                        <Button variant="primary" onClick={handleClose} title={DONE}>
                            {DONE}
                        </Button>
                    ) : isInExportStep ? (
                        <Button
                            variant="primary"
                            onClick={handleExportGeneration}
                            icon={<TabIcon tab={tab === Tabs.Download ? Tabs.Download : Tabs.WaitingRoom} />}
                            isLoading={isExporting}
                            title={tab === Tabs.Download ? START_DOWNLOAD : START_EXPORT}
                        >
                            {tab === Tabs.Download ? START_DOWNLOAD : START_EXPORT}
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={() => setInExportStep(true)}
                            disabled={!variationIds.length || isContinueBlocked}
                            title={CONTINUE}
                        >
                            {CONTINUE}
                        </Button>
                    )
                }
                actionSecondary={
                    <Button variant="secondary" onClick={handleClose} title={CANCEL}>
                        {CANCEL}
                    </Button>
                }
            />
        </ModalBase>
    );
};

export default ExportModal;
