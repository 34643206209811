// Updated copy of desing system ColorPicker component
// needed there to be able to use gradient picker
// remove when design system is updated

import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { LayerProps, mergeRefs, TriggerProps, useLayer } from 'react-laag';
import { token } from '@bynder/design-system';
import { Gradient, HexColor, HexWithOpacity } from './types';
import { InternalColorPicker } from './components/InternalColorPicker';
import { PlacementType } from 'react-laag/dist/PlacementType';

export interface ColorPickerTriggerProps extends TriggerProps {
    isOpen: boolean;
    onClick: () => void;
    'aria-haspopup': boolean;
    'aria-expanded': boolean;
    'aria-controls': string;
}

interface PickerProps<T> {
    value: T;
    onChange: (newValue: T) => void;
    isColorTypeChangeAllowed?: boolean;
    /** Component which triggers the opening/closing of the colorpicker. It can be `Input` or `Button`. If nothing is provided, it will render a standalone colorpicker. */
    trigger?: (props: ColorPickerTriggerProps) => React.ReactNode;
    isLeftPositionAllowed?: boolean;
}

type Props = PickerProps<HexColor> | PickerProps<HexWithOpacity> | PickerProps<Gradient>;

export function ColorPicker(props: Props) {
    const { trigger, value: _value, onChange: _onChange, ...restProps } = props;

    const [isOpen, setIsOpen] = useState(false);

    const triggerRef = useRef<HTMLElement>();
    const dropdownId = useId('colorpicker');
    const zIndex = 2000;

    const possiblePlacements: PlacementType[] = ['bottom-start', 'top-start', 'bottom-end', 'top-end'];

    if (props.isLeftPositionAllowed) {
        possiblePlacements.push('left-end');
    }

    const {
        renderLayer,
        triggerProps: { ref: triggerLayerRef },
        layerProps,
    } = useLayer({
        isOpen,
        /**
         * Until https://github.com/everweij/react-laag/issues/84 is fixed,
         * we can't use the onOutsideClick option in the Shadow DOM.
         */
        onOutsideClick: isInShadow(triggerRef.current) ? undefined : () => setIsOpen(false),
        triggerOffset: 8,
        containerOffset: 16,
        auto: true,
        container: getFirstShadowElement(triggerRef.current),
        placement: 'bottom-start',
        preferX: 'left',
        preferY: 'bottom',
        possiblePlacements,
    });

    fixLayerProps(layerProps);

    useKeyDown('Escape', () => {
        if (isOpen) {
            setIsOpen(false);
        }
    });

    return trigger ? (
        <>
            {trigger({
                isOpen,
                onClick: () => setIsOpen((prev) => !prev),
                'aria-haspopup': true,
                'aria-expanded': isOpen,
                'aria-controls': dropdownId,
                ref: mergeRefs(triggerLayerRef, triggerRef),
            })}

            {isOpen &&
                renderLayer(
                    <Container id={dropdownId} $zIndex={zIndex} {...layerProps} {...restProps}>
                        <ColorPickerCard>
                            <InternalColorPicker
                                value={props.value}
                                onChange={props.onChange}
                                isColorTypeChangeAllowed={props.isColorTypeChangeAllowed}
                            />
                        </ColorPickerCard>
                    </Container>,
                )}
        </>
    ) : (
        <ColorPickerCard {...restProps}>
            <InternalColorPicker
                value={props.value}
                onChange={props.onChange}
                isColorTypeChangeAllowed={props.isColorTypeChangeAllowed}
            />
        </ColorPickerCard>
    );
}

const Container = styled.div<{ $zIndex: number }>`
    z-index: ${(props) => props.$zIndex};
`;

const ColorPickerCard = styled.div`
    width: 296px;
    border-radius: ${token.radiusBase};
    background-color: ${token.gray10};
    box-shadow: ${token.elevationStatic}, ${token.elevation2};

    .react-colorful {
        width: 100%;
        height: auto;
    }

    .react-colorful__pointer {
        width: 16px;
        height: 16px;
        border-width: 4px;
        box-shadow: 0 0 0 1px ${token.gray200a};
        cursor: pointer;
    }

    .react-colorful__pointer-fill {
        box-shadow: inset ${token.elevation1};
    }
`;

function isInShadow(node?: Element | null): boolean {
    if (!node) {
        return false;
    }

    return node.getRootNode() instanceof ShadowRoot;
}

function getFirstShadowElement(node?: Element | null): HTMLElement | undefined {
    if (!node || !isInShadow(node)) {
        return;
    }

    const shadowRoot = node?.getRootNode() as ShadowRoot;

    const firstElementChild = Array.from(shadowRoot.children).find((el) => el.tagName !== 'STYLE');

    return firstElementChild as HTMLElement;
}

function useKeyDown(key: KeyboardEvent['key'], callback: (event: KeyboardEvent) => void) {
    useEffect(() => {
        function handler(event: KeyboardEvent) {
            if (event.key === key) {
                callback(event);
            }
        }

        window.addEventListener('keydown', handler);

        return () => {
            window.removeEventListener('keydown', handler);
        };
    }, [callback, key]);
}

function fixLayerProps(layerProps: LayerProps) {
    if (process.env.NODE_ENV === 'test') {
        layerProps.style = {
            ...layerProps.style,
            top: Number.isInteger(layerProps.style.top) ? layerProps.style.top : 0,
            left: Number.isInteger(layerProps.style.left) ? layerProps.style.left : 0,
        };
    }
}

function useId(prefix: string): string {
    return useMemo(() => prefix + '-' + uuidv4(), [prefix]);
}
