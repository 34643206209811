import { useNavigate } from 'react-router-dom';

const useQueryParams = () => {
    const location = window.location;
    const navigate = useNavigate();

    const setQueryParam = (paramKey: string, paramValue: null | string | string[]) => {
        const query = new URLSearchParams(location.search);

        if (Array.isArray(paramValue)) {
            query.delete(paramKey);
            paramValue.forEach((value) => query.append(paramKey, value));
        } else if (paramValue) {
            query.set(paramKey, paramValue);
        } else {
            query.delete(paramKey);
        }

        navigate({ pathname: location.pathname, search: query.toString() }, { replace: true });
    };

    const getQueryParam = (paramKey: string) => {
        return new URLSearchParams(location.search).get(paramKey);
    };

    const getQueryParamArray = (paramKey: string) => {
        return new URLSearchParams(location.search).getAll(paramKey);
    };

    const resetQueryParams = (paramKeys: string[]) => {
        const query = new URLSearchParams(location.search);

        paramKeys.forEach((key) => {
            query.delete(key);
        });

        navigate({ pathname: location.pathname, search: query.toString() }, { replace: true });
    };
    const resetAllQueryParams = () => {
        navigate({ pathname: location.pathname, search: '' }, { replace: true });
    };

    const getAllQueryParams = () => new URLSearchParams(location.search).toString();
    const getAllQueryParamsRaw = () => new URLSearchParams(location.search);

    const restoreQueryParams = (queryString: string) => {
        navigate({ pathname: location.pathname, search: queryString || '' }, { replace: true });
    };

    return {
        setQueryParam,
        getQueryParam,
        getQueryParamArray,
        resetQueryParams,
        resetAllQueryParams,
        getAllQueryParams,
        getAllQueryParamsRaw,
        restoreQueryParams,
    };
};

export default useQueryParams;
