import React from 'react';
import { Buffer, Card } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { useVariationPageThumbnail } from 'packages/pages/design/VariationsContext/useVariationPageThumbnail';
import { isVariationInvalid } from 'packages/pages/design/sidebar/variations/utils';
import { VariationSetItemEntryType } from 'packages/variation-export/types';

type VariationPreviewProps = {
    variation: VariationSetItemEntryType;
    onSelect: () => void;
    isSelected: boolean;
    pageIndex: number;
};

export const VariationPreview = ({ variation, onSelect, isSelected, pageIndex }: VariationPreviewProps) => {
    const { translate } = useTranslate();
    const { variationSetId, containsTransparency } = variation;
    const { variationPageThumbnail, loading } = useVariationPageThumbnail(
        variationSetId,
        pageIndex,
        variationSetId,
        300,
        300,
    );

    const hasError = isVariationInvalid(variation);

    const additionalProps = !hasError
        ? {
              selectButtonAriaLabel: 'Select variation',
              onSelect,
              onClick: onSelect,
              isSelected,
              isDisabled: hasError,
          }
        : {};

    return (
        <div id={`v-${variationSetId}-${pageIndex}`}>
            <Card variant="clean" {...additionalProps}>
                <Card.Frame
                    isCheckeredBackground={containsTransparency}
                    progressBar={loading && <Buffer noCornerRadius />}
                    badge={hasError && translate('modal.quick-export.variation.error')}
                >
                    {variationPageThumbnail?.dataUrl && (
                        <Card.Images>
                            <Card.Img src={variationPageThumbnail?.dataUrl} contentResize="fit" />
                        </Card.Images>
                    )}
                </Card.Frame>
            </Card>
        </div>
    );
};

export default VariationPreview;
