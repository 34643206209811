import React from 'react';
import ColorInput from './components/ColorInput';
import { Color } from 'packages/ds/ColorPicker/types';
import { OpacityInput } from './components/OpacityInput';
import { isGradientColor } from './utils';
import { StyledRow } from './index.styled';

interface Props {
    isMixed?: boolean;
    isAlphaMixed?: boolean;
    value: Color;
    disableAlpha?: boolean;
    disabled?: boolean;
    testId: string;
    id: string;
    handleOnChange: (value: Color) => void;
    label: string;
    isGradientDisabled?: boolean;
}

const ColorPicker = ({
    isMixed = false,
    isAlphaMixed = false,
    value,
    disableAlpha,
    disabled,
    testId,
    id,
    handleOnChange,
    label,
    isGradientDisabled,
}: Props) => {
    return (
        <StyledRow>
            <ColorInput
                isMixed={isMixed || isAlphaMixed}
                id={id}
                value={value}
                onChange={handleOnChange}
                label={label}
                testId={testId}
                isDisabled={disabled}
                isGradientDisabled={isGradientDisabled}
            />
            {!disableAlpha && !isGradientColor(value) && (
                <OpacityInput
                    value={typeof value === 'string' ? 100 : value.opacity}
                    onChange={(opacity) => {
                        const newColor: Color =
                            typeof value === 'string' ? { color: value, opacity } : { ...value, opacity };

                        handleOnChange(newColor);
                    }}
                    id={`${id}__alpha`}
                    testId={`${testId}__alpha`}
                />
            )}
        </StyledRow>
    );
};

export default ColorPicker;
