import * as Sentry from '@sentry/react';
import { notify } from '@bynder/design-system';
import ProxyServerConnectorEvents from './ProxyServerConnectorEvents';
import { ResponseModel } from './ResponseModel';
import sleep from '../../helpers/sleep';

const DOWNLOAD_KEY = Symbol('download');

const reqJson = async (req: Request | Response) => {
    if (!('json' in req)) {
        return;
    }

    try {
        return await req.clone().json();
    } catch (error) {}
};

const cleanUrlOfFingerprint = (url: string) => {
    const values = window.location.href.split('/');
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname.replace(new RegExp(values.filter(Boolean).join('|'), 'g'), '');
    parsedUrl.searchParams.forEach((value, key) => {
        if (values.includes(value)) {
            parsedUrl.searchParams.delete(key);
        }
    });

    return `${pathname}?${parsedUrl.searchParams}`;
};

class ResponseError extends Error {
    constructor(status: number, url: string) {
        super(`STATUS: ${status}, to ${url}`);
    }
}

export default class ProxyServerConnector {
    static isBlocked = false;

    static getToken = (): string => '';

    static tokenRefresher = (requestObject: Request): Promise<any> => Promise.resolve(true);

    // static csrfToken = '';

    // static setCsrfToken = () => {
    //     ProxyServerConnector.isBlocked = true;
    //     fetch(`${PROXY_PREFIX}/csrf-token`)
    //         .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
    //         .then(({ status, body }) => {
    //             if (status !== 200) {
    //                 console.error(status, body);
    //                 ProxyServerConnector.csrfToken = '';

    //                 return;
    //             }

    //             ProxyServerConnector.csrfToken = body.csrfToken;
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //             ProxyServerConnector.csrfToken = '';
    //         })
    //         .finally(() => {
    //             ProxyServerConnector.isBlocked = false;
    //         });
    // };

    static requestsWaitList = [];

    static flushWaitList = () => {
        while (ProxyServerConnector.requestsWaitList.length) {
            ProxyServerConnector.requestsWaitList.shift()();
        }
    };

    static abortControllersWeakMap = new WeakMap<Promise<ResponseModel<any>>, AbortController>();

    resource = '';

    endpointPrefix = '';

    constructor(resource: string, endpointPrefix: string = PROXY_PREFIX) {
        this.resource = resource;
        this.endpointPrefix = endpointPrefix;
    }

    static waitUntilUnblock = async (): Promise<boolean> => {
        if (ProxyServerConnector.isBlocked) {
            await sleep(200);
            await ProxyServerConnector.waitUntilUnblock();
        }

        return true;
    };

    static async handleResponseStatus(request: Request, response: Response, isStrict?: boolean): Promise<Response> {
        if (response.status >= 400) {
            const headers = {};
            (request.headers || []).forEach((value, key) => {
                headers[key] = value;
            });
            Sentry.captureException(new ResponseError(response.status, request.url), {
                fingerprint: ['{{ default }}', response.status.toString(), cleanUrlOfFingerprint(request.url)],
                extra: {
                    request: {
                        url: request.url,
                        method: request.method,
                        headers,
                        body: await reqJson(request),
                    },
                    response: {
                        status: response.status,
                        body: await reqJson(response),
                    },
                },
                level: 'log',
            });
            await Sentry.getCurrentHub()?.getClient()?.flush();
        }

        switch (response.status) {
            case 200: {
                break;
            }
            case 401: {
                window.dispatchEvent(ProxyServerConnectorEvents.onLogout);
                break;
            }
            case 403: {
                if (isStrict) {
                    window.dispatchEvent(ProxyServerConnectorEvents.onForbidden);
                } else {
                    notify({
                        title: 'Something went wrong',
                        variant: 'error',
                    });
                }

                break;
            }
            case 404: {
                if (isStrict) {
                    window.dispatchEvent(ProxyServerConnectorEvents.onNotFound);
                } else {
                    notify({
                        title: 'Something went wrong',
                        variant: 'error',
                    });
                }

                break;
            }
            case 498: {
                window.dispatchEvent(ProxyServerConnectorEvents.onRefreshTokenRequired);
                throw 'REFRESH_TOKEN_NEEDED';
            }
            case 500: {
                if (isStrict) {
                    window.dispatchEvent(ProxyServerConnectorEvents.onInternalError);
                } else {
                }

                break;
            }
        }

        return response;
    }

    static async handleResponseRawBody(response: Response): Promise<ResponseModel> {
        try {
            const jsonBody = await response.json();

            return {
                json: jsonBody,
                status: response.status,
                headers: Object.fromEntries(response.headers),
            };
        } catch (e) {
            throw 'FAILED_JSON_PARSE';
        }
    }

    static async handleResponseDownload(response: Response): Promise<Omit<ResponseModel, 'json'>> {
        try {
            const blob = await response.blob();
            const fileName = ((response.headers.get('content-disposition') || '')
                .split('filename=')[1] || '')
                .slice(0, -1);

            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            a.click();

            return {
                status: response.status,
                headers: Object.fromEntries(response.headers),
            };
        } catch (e) {
            throw 'FAILED_BLOB_PARSE';
        }
    }

    static handleResponseBody(response: ResponseModel): ResponseModel {
        return response;
    }

    get = <T>(route: string, data?: {}, headers?: {}, isStrict?: boolean): Promise<ResponseModel<T>> =>
        this.send(route, 'GET', {}, headers, isStrict);

    put = <T>(route: string, data?: {}, headers?: {}, isStrict?: boolean): Promise<ResponseModel<T>> =>
        this.send(route, 'PUT', data, headers, isStrict);

    post = <T>(route: string, data: {}, headers?: {}, isStrict?: boolean): Promise<ResponseModel<T>> =>
        this.send(route, 'POST', data, headers, isStrict);

    delete = (route: string, data?: {}, headers?: {}, isStrict?: boolean): Promise<ResponseModel> =>
        this.send(route, 'DELETE', data, headers, isStrict);

    download = (route: string, data: {}, headers: {}): Promise<ResponseModel> =>
        this.send(
            route,
            'GET',
            {},
            {
                ...headers,
                [DOWNLOAD_KEY]: true,
            },
        );

    send = <T>(
        url: string,
        requestMethod: string,
        data: Record<string, any> = {},
        headers: Record<string, any> = {},
        isStrict = true,
    ): Promise<ResponseModel<T>> => {
        const abortController = new AbortController();
        const res = this._send<T>(url, requestMethod, data, headers, isStrict, abortController.signal);

        ProxyServerConnector.abortControllersWeakMap.set(res, abortController);

        return res;
    };

    private _send = async <T>(
        url: string,
        requestMethod: string,
        data: Record<string, any>,
        headers: Record<string, any>,
        isStrict: boolean,
        signal: AbortSignal,
    ): Promise<ResponseModel<T>> => {
        await ProxyServerConnector.waitUntilUnblock();

        const requestFullUrl: string = this.endpointPrefix + this.resource + url;
        const isFormData = data instanceof FormData;

        // create body
        let requestBody: FormData | string | null = null;

        if (['POST', 'PUT', 'DELETE'].includes(requestMethod)) {
            requestBody = isFormData ? data : JSON.stringify(data);
        }
        // end: create body

        // create headers
        const requestHeaders: Headers = new Headers();
        const token: string = ProxyServerConnector.getToken();

        if (token) {
            requestHeaders.append('Authorization', 'Bearer ' + token);
        }

        // if (ProxyServerConnector.csrfToken) {
        //     requestHeaders.append('X-CSRF-Token', ProxyServerConnector.csrfToken);
        // }

        if (!isFormData) {
            requestHeaders.append('Content-Type', 'application/json');
        }

        requestHeaders.append('Cache-Control', 'no-cache');
        requestHeaders.append('Pragma', 'no-cache');

        const download = !!headers[DOWNLOAD_KEY];

        if (download) {
            delete headers[DOWNLOAD_KEY];
        }

        for (const headerKey in headers) {
            requestHeaders.append(headerKey, headers[headerKey]);
        }
        // end: create headers

        const requestObject = new Request(requestFullUrl, {
            method: requestMethod,
            credentials: 'same-origin',
            body: requestBody,
            headers: requestHeaders,
            signal,
        });

        return ProxyServerConnector.sendRequestObject(requestObject, download, isStrict);
    };

    static sendRequestObject(requestObject: Request, download?: boolean, isStrict?: boolean): Promise<ResponseModel> {
        window.dispatchEvent(ProxyServerConnectorEvents.onBeforeRequest);

        const makeRequest = () => {
            const req = window
                .fetch(requestObject.clone())
                .then((res) => ProxyServerConnector.handleResponseStatus(requestObject, res, isStrict));

            return download
                ? req.then(ProxyServerConnector.handleResponseDownload)
                : req.then(ProxyServerConnector.handleResponseRawBody).then(ProxyServerConnector.handleResponseBody);
        };

        return makeRequest()
            .catch((error) => {
                if (error.name === 'AbortError') {
                    // do nothing
                } else if (error === 'FAILED_JSON_PARSE') {
                    window.dispatchEvent(ProxyServerConnectorEvents.onFailedToParseJson);
                } else if (error === 'REFRESH_TOKEN_NEEDED') {
                    return ProxyServerConnector.tokenRefresher(requestObject);
                } else if (isNaN(error)) {
                    window.dispatchEvent(ProxyServerConnectorEvents.onFailedToFetch);
                }

                throw error;
            })
            .finally(() => {
                window.dispatchEvent(ProxyServerConnectorEvents.onAfterRequest);
            });
    }

    abortRequest(request: Promise<ResponseModel>) {
        const abortController = ProxyServerConnector.abortControllersWeakMap.get(request);

        if (abortController) {
            abortController.abort();
        }
    }
}
