import React, { useEffect, useState } from 'react';
import { getDropShadowValueFromElement } from '~/common/editor/helpers';
import EditorColor from '../../../FormComponents/EditorColor';

type ShadowColorProps = {
    disabled: boolean;
    onChange: (prop: string, value: any) => void;
    selectedElement: any;
};

const ShadowColor = ({ disabled, onChange, selectedElement }: ShadowColorProps) => {
    const { locked } = selectedElement;

    const [selectedShadowColor, setSelectedShadowColor] = useState(
        getDropShadowValueFromElement(selectedElement).color,
    );

    const onColorChange = (name, newValue) => {
        onChange(name, newValue);
    };

    useEffect(() => {
        setSelectedShadowColor(getDropShadowValueFromElement(selectedElement).color);
    }, [selectedElement, selectedElement.dropShadow?.color]);

    return (
        <EditorColor
            name="dropShadow"
            property="fillColor"
            colorTypes={['SOLID']}
            color={selectedShadowColor}
            disabled={locked || disabled}
            onChange={onColorChange}
            testId="shots_styling_shadow__color"
            selectedElement={selectedElement}
            isGradientDisabled
        />
    );
};

export default ShadowColor;
