import React, { useMemo, useRef, useState } from 'react';
import { HslaColor, HslColor } from 'react-colorful';
import { SolidColor, Color } from '../../types';
import { Saturation } from './Saturation';
import { HueAndOpacity } from './HueAndOpacity';
import { ChangeColorType } from '../ChangeColorType';
import { hslColorToSolidColor, solidColorToHslColor } from '../../utils/hsl';

type Props = {
    value: SolidColor;
    onChange: (newValue: Color) => void;
    isColorTypeChangeAllowed?: boolean;
};

export function SolidColorPicker(props: Props) {
    // doesn't seem to be necessary anymore
    // retest and remove after release
    const latestChangeRef = useRef({
        source: 'hue' as 'hue' | 'saturation',
        date: Date.now(),
    });

    const [latestHue, setLatestHue] = useState(0);

    const hexColor: string = typeof props.value === 'string' ? props.value : props.value.color;
    const opacity = typeof props.value === 'string' ? undefined : props.value.opacity;

    const color: HslaColor | HslColor = useMemo(
        () => solidColorToHslColor(hexColor, opacity, latestHue),
        [hexColor, opacity, latestHue],
    );

    function handleSaturationChange(value: HslColor | HslaColor) {
        const { source, date } = latestChangeRef.current;

        if (source !== 'saturation' && Date.now() - date < 10) {
            return;
        }

        const solidColor = hslColorToSolidColor(value);

        props.onChange(solidColor);

        latestChangeRef.current = {
            source: 'saturation',
            date: Date.now(),
        };
    }

    function handleHueChange(value: HslColor | HslaColor) {
        const { source, date } = latestChangeRef.current;

        if (source !== 'hue' && Date.now() - date < 10) {
            return;
        }

        const solidColor = hslColorToSolidColor(value);

        props.onChange(solidColor);
        setLatestHue(value.h);

        latestChangeRef.current = {
            source: 'hue',
            date: Date.now(),
        };
    }

    return (
        <>
            <Saturation value={color} onChange={handleSaturationChange} />
            {props.isColorTypeChangeAllowed ? <ChangeColorType value={props.value} onChange={props.onChange} /> : <></>}
            <HueAndOpacity value={color} onChange={handleHueChange} />
        </>
    );
}
