import type { HslaColor, HslColor } from 'react-colorful';
import tinycolor from 'tinycolor2';
import { SolidColor } from '../types';

export function solidColorToHslColor(
    color: string,
    opacity: number | undefined,
    latestHue: number | undefined,
): HslColor | HslaColor {
    const parsedColor = tinycolor(color);

    if (opacity !== undefined) {
        parsedColor.setAlpha(opacity / 100);
    }

    const hsl = parsedColor.toHsl();

    const out = {} as HslColor | HslaColor;

    // update color components to work with react-colorful
    Object.keys(hsl).forEach((key) => {
        if (key === 'a') {
            out[key] = hsl[key];
        } else if (key === 'h') {
            if (latestHue) {
                out[key] = Math.round(hsl[key] || latestHue);
            } else {
                out[key] = Math.round(hsl[key]);
            }
        } else {
            out[key] = Math.round(hsl[key] * 100);
        }
    });

    return out;
}

export function hslColorToSolidColor(color: HslColor | HslaColor): SolidColor {
    const hex = tinycolor(color).toHexString().toUpperCase();

    if ('a' in color) {
        return {
            color: hex,
            opacity: color.a * 100,
        };
    }

    return hex;
}
