import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Tooltip } from '@bynder/design-system';
import { IconCancel } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useQueryParams from 'packages/hooks/useQueryParams';
import { ExportFilterProps } from '../Export/types';
import { ActivityFeedStatusFilter } from 'packages/store/activityFeed/types';

const ExportStatus = ({ value, onSelect, onReset, ...props }: ExportFilterProps<ActivityFeedStatusFilter>) => {
    const [option, setOption] = useState('');
    const { translate } = useTranslate();
    const { getQueryParam, resetQueryParams } = useQueryParams();
    const queriedExportStatusFilter = getQueryParam('status')?.toUpperCase();
    const queriedExportTypeFilter = getQueryParam('export')?.toUpperCase();
    const isFiltered = queriedExportStatusFilter || queriedExportTypeFilter;

    const onExportOptionSelect = (option: ActivityFeedStatusFilter) => {
        setOption(option);
        onSelect(option);
    };

    const optionsTitles = {
        [ActivityFeedStatusFilter.ANY]: translate('filters.export.status.any'),
        [ActivityFeedStatusFilter.COMPLETED]: translate('filters.export.status.completed'),
        [ActivityFeedStatusFilter.IN_PROGRESS]: translate('filters.export.status.in-progress'),
    };

    useEffect(() => {
        if (queriedExportStatusFilter && Object.keys(ActivityFeedStatusFilter).includes(queriedExportStatusFilter)) {
            setOption(ActivityFeedStatusFilter[queriedExportStatusFilter]);
        } else {
            setOption(ActivityFeedStatusFilter.ANY);
        }
    }, [queriedExportStatusFilter]);

    return (
        <>
            <Dropdown
                position="top-left"
                trigger={({ isOpen, ...triggerProps }) => (
                    <Button
                        data-testid="activity feed status filter"
                        variant="clean"
                        isPressed={isOpen}
                        {...triggerProps}
                        aria-label={translate('filters.export.status.label')}
                        rightIcon={<Dropdown.Arrow />}
                    >
                        {!option.length ? optionsTitles[ActivityFeedStatusFilter.ANY] : optionsTitles[option]}
                    </Button>
                )}
                {...props}
            >
                <Dropdown.Item
                    onClick={() => onExportOptionSelect(ActivityFeedStatusFilter.ANY)}
                    isChecked={!option.length}
                >
                    {optionsTitles[ActivityFeedStatusFilter.ANY]}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onExportOptionSelect(ActivityFeedStatusFilter.COMPLETED)}
                    isChecked={option === ActivityFeedStatusFilter.COMPLETED}
                >
                    {optionsTitles[ActivityFeedStatusFilter.COMPLETED]}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onExportOptionSelect(ActivityFeedStatusFilter.IN_PROGRESS)}
                    isChecked={option === ActivityFeedStatusFilter.IN_PROGRESS}
                >
                    {optionsTitles[ActivityFeedStatusFilter.IN_PROGRESS]}
                </Dropdown.Item>
            </Dropdown>
            {isFiltered && (
                <Tooltip content={translate('pages.activity.clear-filters')}>
                    <Button variant="clean" title="Clear filter" onClick={onReset} icon={<IconCancel />} />
                </Tooltip>
            )}
        </>
    );
};

export default ExportStatus;
