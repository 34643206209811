import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useQueryParams from 'packages/hooks/useQueryParams';
import { ExportFilterProps } from './types';
import { ActivityFeedTypeFilter } from 'packages/store/activityFeed/types';

const Export = ({ value, onSelect, ...props }: ExportFilterProps<ActivityFeedTypeFilter>) => {
    const [option, setOption] = useState('');
    const { translate } = useTranslate();
    const { getQueryParam } = useQueryParams();
    const queriedExportFilter = getQueryParam('export')?.toUpperCase();

    const onExportOptionSelect = (option: ActivityFeedTypeFilter) => {
        setOption(option);
        onSelect(option);
    };

    const optionsTitles = {
        [ActivityFeedTypeFilter.ALL]: translate('filters.export.all'),
        [ActivityFeedTypeFilter.DOWNLOAD]: translate('filters.export.download'),
        [ActivityFeedTypeFilter.WAITING_ROOM]: translate('filters.export.waiting-room'),
        [ActivityFeedTypeFilter.SHOW_UNREAD]: translate('filters.export.show-unread'),
    };

    useEffect(() => {
        if (queriedExportFilter && Object.keys(ActivityFeedTypeFilter).includes(queriedExportFilter)) {
            setOption(ActivityFeedTypeFilter[queriedExportFilter]);
        } else {
            setOption(ActivityFeedTypeFilter.ALL);
        }
    }, [queriedExportFilter]);

    return (
        <>
            <Dropdown
                position="top-left"
                trigger={({ isOpen, ...triggerProps }) => (
                    <Button
                        data-testid="activity feed export options filter"
                        variant="clean"
                        isPressed={isOpen}
                        {...triggerProps}
                        aria-label={translate('filters.export.label')}
                        rightIcon={<Dropdown.Arrow />}
                    >
                        {!option.length ? optionsTitles[ActivityFeedTypeFilter.ALL] : optionsTitles[option]}
                    </Button>
                )}
                {...props}
            >
                <Dropdown.Item
                    onClick={() => onExportOptionSelect(ActivityFeedTypeFilter.ALL)}
                    isChecked={!option.length}
                >
                    {optionsTitles[ActivityFeedTypeFilter.ALL]}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onExportOptionSelect(ActivityFeedTypeFilter.DOWNLOAD)}
                    isChecked={option === ActivityFeedTypeFilter.DOWNLOAD}
                >
                    {optionsTitles[ActivityFeedTypeFilter.DOWNLOAD]}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onExportOptionSelect(ActivityFeedTypeFilter.WAITING_ROOM)}
                    isChecked={option === ActivityFeedTypeFilter.WAITING_ROOM}
                >
                    {optionsTitles[ActivityFeedTypeFilter.WAITING_ROOM]}
                </Dropdown.Item>
            </Dropdown>
        </>
    );
};

export default Export;
