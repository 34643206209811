import React from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { HslColor, HslColorPicker, HslaColor, HslaColorPicker } from 'react-colorful';
import { token } from '@bynder/design-system';
import { InputContainer } from './InputContainer';
import { ColorHexInput } from './ColorHexInput';
import { NumberInputInlineSuffix } from '../NumberInputInlineSuffix';
import { useThrottledCallback } from './hooks';

type Props = {
    value: HslColor | HslaColor;
    onChange: (color: HslColor | HslaColor) => void;
};

export function HueAndOpacity(props: Props) {
    const hexValue = `#${tinycolor(props.value).toHex().toUpperCase()}`;
    const onChange = useThrottledCallback(props.onChange, 10);

    if (!('a' in props.value)) {
        return (
            <>
                <HuePicker color={props.value} onChange={onChange} />
                <InputContainer>
                    <ColorHexInput value={hexValue} onChange={(val) => onChange(tinycolor(val).toHsl())} />
                </InputContainer>
            </>
        );
    }

    function onHexChange(value: string) {
        const hsla = tinycolor(value).setAlpha(props.value.a).toHsl();

        onChange(hsla);
    }

    function onOpacityChange(value: number) {
        const hsla = tinycolor(props.value)
            .setAlpha(value / 100)
            .toHsl();

        onChange(hsla);
    }

    return (
        <>
            <HuePicker as={HslaColorPicker} color={props.value} onChange={onChange} />
            <InputContainer $hasAlphaChannel>
                <ColorHexInput value={hexValue} opacity={props.value.a * 100} onChange={onHexChange} />
                <NumberInputInlineSuffix
                    value={props.value.a * 100}
                    onChange={onOpacityChange}
                    suffix="%"
                    min={0}
                    max={100}
                />
            </InputContainer>
        </>
    );
}

const HuePicker = styled(HslColorPicker)`
    .react-colorful__saturation {
        display: none;
    }

    .react-colorful__hue,
    .react-colorful__alpha {
        height: 8px;
        margin: 0 ${token.spacing5} ${token.spacing6};
        border-radius: ${token.radiusPill};
    }

    .react-colorful__hue {
        box-shadow: inset 0 0 0 1px ${token.gray100a};
    }

    .react-colorful__alpha {
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Crect height='8' width='8' y='0' x='0' fill='%23ffffff'/%3E%3Crect height='4' width='4' y='0' x='0' fill='%23e9ecee'/%3E%3Crect height='4' width='4' y='4' x='4' fill='%23e9ecee'/%3E%3C/svg%3E%0A");
    }

    .react-colorful__alpha-gradient {
        height: 8px;
        border-radius: ${token.radiusBase};
        box-shadow: inset 0 0 0 1px ${token.gray100a};
    }
`;
