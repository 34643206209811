import React, { useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { InteractiveIcon, Thumbnail } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';
import { StyledInput } from './ColorInput.styled';
import { Color, Gradient, SolidColor } from 'packages/ds/ColorPicker/types';
import { ColorPicker, ColorPickerTriggerProps } from 'packages/ds/ColorPicker/ColorPicker';
import { GradientThumbnail } from 'packages/ds/GradientThumbnail/GradientThumbnail';
import useAccessRights from 'packages/hooks/useAccessRights';
import features from '~/configs/features';
import { isGradientColor } from '../../utils';
import { color2ColorParams, colorToCSS } from '~/common/editor/editorHelper';

type Props = {
    isMixed?: boolean;
    id: string;
    value: Color;
    label: string;
    onChange: (value: Color) => void;
    testId: string;
    isDisabled?: boolean;
    isGradientDisabled?: boolean;
    isLeftPositionAllowed?: boolean;
};

const ColorInput = ({
    isMixed,
    id,
    value,
    onChange,
    label,
    testId,
    isDisabled,
    isGradientDisabled,
    isLeftPositionAllowed,
}: Props) => {
    const { isPlatformAllowed } = useAccessRights();
    const valueWithOpacity = useMemo(
        () => (typeof value === 'string' ? { color: value, opacity: 100 } : value),
        [value],
    );

    const isGradientsAllowed = isPlatformAllowed([features.GRADIENTS]);

    return (
        <ColorPicker
            value={valueWithOpacity}
            onChange={onChange}
            isColorTypeChangeAllowed={isGradientsAllowed && !isGradientDisabled}
            isLeftPositionAllowed={isLeftPositionAllowed}
            trigger={({ onClick, isOpen, ref, ...triggerProps }: ColorPickerTriggerProps) => (
                // popup position (from react-laag) uses it for calculations
                // and not recalculates on ref change
                <div {...triggerProps} ref={ref}>
                    {isGradientColor(valueWithOpacity) ? (
                        <GradientColorInput
                            onClick={onClick}
                            value={valueWithOpacity}
                            isMixed={isMixed}
                            id={id}
                            label={label}
                            testId={testId}
                            isDisabled={isDisabled}
                        />
                    ) : (
                        <SolidColorInput
                            onClick={onClick}
                            value={valueWithOpacity}
                            onChange={onChange}
                            isDisabled={isDisabled}
                            isMixed={isMixed}
                            label={label}
                            id={id}
                            testId={testId}
                        />
                    )}
                </div>
            )}
        />
    );
};

type SolidColorInputProps = {
    value: SolidColor;
    onChange: (color: Color) => void;
    isDisabled?: boolean;
    isMixed?: boolean;
    label: string;
    id: string;
    testId: string;
    onClick: () => void;
};

function SolidColorInput({ value, onChange, isDisabled, isMixed, testId, id, label, onClick }: SolidColorInputProps) {
    const [inputState, setInputState] = useState(getHexFromSolidColor(value, isMixed));

    useMemo(() => {
        setInputState(getHexFromSolidColor(value, isMixed));
    }, [value, isMixed]);

    const thumbnailColor = isMixed ? undefined : colorToCSS(color2ColorParams(value));

    const onFocus = () => {
        if (isMixed) {
            setInputState('#______');
        }
    };

    const onBlur = () => {
        const sanitizedValue = inputState.replace('_', '');

        if (sanitizedValue.length !== 7) {
            setInputState(getHexFromSolidColor(value, isMixed));

            return;
        }

        if (typeof value === 'string') {
            onChange(sanitizedValue);
        } else {
            onChange({ color: sanitizedValue, opacity: value.opacity });
        }
    };

    return (
        <InputMask
            mask="\#******"
            maskChar="_"
            formatChars={{
                '*': '[A-Fa-f0-9]',
            }}
            onChange={setInputState}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    e.target.blur();
                }
            }}
            placeholder={isMixed ? 'Mixed' : ''}
            value={inputState}
            disabled={isDisabled}
            {...generateTestId(testId)}
        >
            {(inputProps) => (
                <StyledInput
                    {...inputProps}
                    $isUppercase={!(isMixed && inputState === '')}
                    id={id}
                    icon={
                        <InteractiveIcon
                            label={label}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick();
                            }}
                            icon={
                                <Thumbnail
                                    variant="content"
                                    backgroundColor={thumbnailColor}
                                    shape="circle"
                                    size="xs"
                                />
                            }
                            isDisabled={isDisabled}
                        />
                    }
                    aria-label={label}
                    isDisabled={isDisabled}
                />
            )}
        </InputMask>
    );
}

type GradientColorInputProps = {
    onClick: () => void;
    value: Gradient;
    isDisabled?: boolean;
    isMixed?: boolean;
    label: string;
    id: string;
    testId: string;
};

function GradientColorInput({ value, isMixed, id, label, isDisabled, testId, onClick }: GradientColorInputProps) {
    return (
        <StyledInput
            id={id}
            icon={
                <InteractiveIcon
                    label={label}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                    }}
                    icon={<GradientThumbnail shape="circle" size="xs" {...value} />}
                    isDisabled={isDisabled}
                />
            }
            aria-label={label}
            value={isMixed ? 'Mixed' : ''}
            placeholder={value.type}
            isDisabled
            {...generateTestId(testId)}
        />
    );
}

function getHexFromSolidColor(color: SolidColor, isMixed?: boolean): string {
    if (isMixed) {
        return '';
    }

    if (typeof color === 'string') {
        return color;
    }

    return color.color;
}

export default ColorInput;
