import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const TimelineContainer = styled.div`
    min-width: 864px;
    max-width: calc(100vw - 360px); /* take away sidebar width */
    height: 178px;
    background-color: ${token.white};
    width: 100%;
    flex-direction: column;
`;

export const ZoomControlWrapper = styled.div`
    padding: 0 ${token.spacing6};
`;
