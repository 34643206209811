import { Category } from 'packages/store/categories/types';
import { Collection, UploadsCollection } from 'packages/store/collections/types';

// Constants for the thumbnail status for designs and templates
export const PROCESSED_STATUSES = ['PROCESSED', 'UNKNOWN', 'NOT_FOUND', 'FAILED'];

export const hasPreviewImageURL = (item: Collection | Category | UploadsCollection | null) =>
    item?.previewItems?.length &&
    item?.previewItems[item?.previewItems.length - 1]?.previewUrl &&
    item?.previewItems[item?.previewItems.length - 1]?.previewUrl?.length > 0;

export const isProcessingDone = (item: Collection | Category | UploadsCollection | null) =>
    (item?.previewItems?.length &&
        item.previewItems.every((preview) => PROCESSED_STATUSES.includes(preview.thumbnailStatus ?? preview.status))) ||
    !item?.previewItems?.length;
