import React, { useEffect, useState } from 'react';
import { BackgroundColorParams, BaseMultiPageModel, ElementTypes } from '@bynder-studio/render-core';
import { Dropdown, Form } from '@bynder/design-system';
import { IconPlaylistAdd } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { useColorStyleModal } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor/components/modals/useColorStylesModal';
import { useShotColor } from 'packages/pages/design/sidebar/shots/useShotColor';
import FormLabelLock from '../../FormComponents/FormLabelWithLock';
import EditorColor from '../../FormComponents/EditorColor';
import { FormSectionContent } from '../../index.styled';

type Props = {
    creativeModel: BaseMultiPageModel;
    disabled: boolean;
};

const BackgroundColorInput = ({ creativeModel, disabled }: Props) => {
    const { translate } = useTranslate();
    const [backgroundColor, setBackgroundColor] = useState<BackgroundColorParams>(creativeModel?.getBackgroundColor());

    const baseColorParams = {
        selectedElement: backgroundColor,
        brandColorProperty: 'brandColors',
        propertyName: 'color',
        globalPropertyName: ElementTypes.BACKGROUND_COLOR,
    };

    const { appliedCustomColors, elementColors, onAppliedClick, handleChangeColor } = useShotColor({
        ...baseColorParams,
        creativeModel,
    });
    const { renderColorStylesModal, openConfigureColorsModal } = useColorStyleModal({
        ...baseColorParams,
        title: translate('editor.sidebar.globals.background_color.configure.modal.title'),
    });

    const toggleLock = () => {
        const newValue = { locked: !backgroundColor.locked };

        setBackgroundColor({ ...backgroundColor, ...newValue });
        creativeModel.updateGlobalProperty(ElementTypes.BACKGROUND_COLOR, newValue);
    };

    useEffect(() => {
        const listener = () => {
            setBackgroundColor({ ...creativeModel.getBackgroundColor() });
        };
        creativeModel.on('backgroundColorUpdated', listener);

        return () => {
            creativeModel.off('backgroundColorUpdated', listener);
        };
    }, [creativeModel]);

    useEffect(() => {
        setBackgroundColor({ ...creativeModel.getBackgroundColor() });
    }, [creativeModel.getBackgroundColor()]);

    return (
        <>
            <FormSectionContent onSubmit={(event) => event.preventDefault()}>
                <Form.Group>
                    <FormLabelLock
                        testId="global_background_color"
                        label={translate('editor.sidebar.globals.background_color.label')}
                        locked={backgroundColor.locked}
                        toggleLock={toggleLock}
                        htmlFor="global_background_color"
                        isDisabled={disabled}
                    />
                    <EditorColor
                        name="global_background_color"
                        disabled={disabled}
                        color={backgroundColor.color}
                        selectedElement={backgroundColor}
                        onChange={handleChangeColor}
                        elementColors={elementColors}
                        onAppliedClick={onAppliedClick}
                        selectedCustomColors={appliedCustomColors}
                        property="color"
                        testId="global_background_color"
                        configureColorsButton={
                            <Dropdown.Item icon={<IconPlaylistAdd />} onClick={openConfigureColorsModal}>
                                {translate('editor.sidebar.globals.background_color.configure-background-styles')}
                            </Dropdown.Item>
                        }
                    />
                </Form.Group>
            </FormSectionContent>
            {renderColorStylesModal()}
        </>
    );
};

export default BackgroundColorInput;
