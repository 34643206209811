import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ElementUpdateTypes, GroupElement } from '@bynder-studio/render-web';
import { IconHideSmall, IconLockOpenSmall, IconLockSmall, IconShowSmall } from '@bynder/icons';
import EditableLayerItem from '~/common/editor/layers/layerItem/editableLayerItem/EditableLayerItem';
import { collectTopLevelElements, isElementLocked } from '~/common/editor/timeline/timeline-actions/components/utils';
import { isCtrlKey } from '~/helpers/hotKeys';
import useElementRename from 'packages/hooks/useElementRename';
import useEditor from '~/hooks/useEditor';
import useForceUpdate from '~/hooks/useForceUpdate';
import { getElementType, isUsedAsMask } from '~/common/editor/helpers/elementtree';
import generateTestId from '~/helpers/testIdHelpers';
import GenerateIcon from './GenerateIcon';
import { LayerItemContainer } from './LayerItem.styled';

const LayerItem = ({
    element,
    childrenLength,
    onGroupExpand,
    level = 0,
    isDragging = false,
    isGroupExpand = false,
    isDragOverlay = false,
    isDragOver = false,
}) => {
    const { name: layerName, id, contentPropertyId } = element;
    const forceUpdate = useForceUpdate();
    const { creativeModel, manipulationRenderer } = useEditor();
    const [selected, setSelected] = useState(() => manipulationRenderer?.isElementSelected(id) ?? false);
    const [enableEditor, setEnableEditor] = useState(false);
    useElementRename({ id, callback: () => setEnableEditor(true) });

    const isGroup = element instanceof GroupElement;
    const isHidden = element.hidden;
    const elementLockStatus = isElementLocked(element);

    const visibilityIcon = isHidden ? <IconHideSmall /> : <IconShowSmall />;
    const lockedIcon = elementLockStatus ? <IconLockSmall /> : <IconLockOpenSmall />;
    const groupClassName = isGroup && 'layers--item-group';
    const isSelected = selected && 'layers--item-selected';
    const isContentProperty = contentPropertyId && 'layers--item-content-property';
    const visibilityClassName = isHidden && 'layers--item-hidden';
    const lockClassName = elementLockStatus && 'layers--item-locked';
    const dragOverlayClassName = isDragOverlay && 'layers--item-drag-overlay';
    const draggingClassName = isDragging && 'layers--item-dragging';
    const dragOverClassName = isDragOver && 'layers--item-drag-over';

    const getElementTypeForTestId = (el) => {
        const isMask = isUsedAsMask(creativeModel, el);
        const isElementMasked = el?.mask !== null;
        const type = getElementType(el);
        const postfix = isMask ? '_mask' : isElementMasked ? '_masked' : '';

        return `${type}${postfix}`;
    };

    const type = getElementTypeForTestId(element).toLowerCase();
    const testId = useMemo(() => generateTestId(`layer_element_${type}_${id}`), [type, id]);

    if ('data-testid' in testId && isGroup) {
        testId['data-children-length'] = childrenLength;
    }
    const handleClick = useCallback(
        (event) => {
            const isMultiSelectMode = isCtrlKey(event);
            manipulationRenderer.selectElement(id, isMultiSelectMode);
        },
        [id, manipulationRenderer],
    );

    const handleGroupExpansion = useCallback(() => {
        onGroupExpand(element.id);
    }, [element, onGroupExpand]);

    const toggleLock = useCallback(
        (event) => {
            event.preventDefault();

            manipulationRenderer.selectElement(id);
            manipulationRenderer.getSelectedElements().forEach((el) => {
                creativeModel.updateElement(el.id, { locked: !elementLockStatus });
            });
            forceUpdate();
        },
        [id, elementLockStatus, creativeModel, manipulationRenderer],
    );

    const toggleVisibility = useCallback(
        (event) => {
            event.preventDefault();

            manipulationRenderer.selectElement(id);
            collectTopLevelElements(manipulationRenderer.getSelectedElements()).forEach((el) => {
                creativeModel.updateElement(el.id, { hidden: !isHidden });
            });
            forceUpdate();
        },
        [manipulationRenderer, creativeModel, id, isHidden],
    );

    const handleDblClick = () => {
        setEnableEditor(true);
    };

    const handleElementNameChange = useCallback(
        (name) => {
            if (name === layerName) {
                return;
            }

            creativeModel.updateElement(id, { name });
            setEnableEditor(false);
        },
        [creativeModel, id],
    );

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        setSelected(manipulationRenderer.isElementSelected(id));

        const elementSelectedListener = () => setSelected(manipulationRenderer.isElementSelected(id));

        manipulationRenderer.eventEmitter.on('elementSelected', elementSelectedListener);

        return () => manipulationRenderer.eventEmitter.off('elementSelected', elementSelectedListener);
    }, [id, manipulationRenderer]);

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        const unsubscribeName = creativeModel.onElementPartialUpdate(id, ElementUpdateTypes.NAME, forceUpdate);
        const unsubscribeLock = creativeModel.onElementPartialUpdate(id, ElementUpdateTypes.LOCK, forceUpdate);
        const unsubscribeHidden = creativeModel.onElementPartialUpdate(id, ElementUpdateTypes.VISIBILITY, forceUpdate);
        const unsubscribeMask = creativeModel.onElementPartialUpdate(id, ElementUpdateTypes.MASK, forceUpdate);

        return () => {
            unsubscribeName();
            unsubscribeLock();
            unsubscribeHidden();
            unsubscribeMask();
        };
    }, [id, creativeModel, forceUpdate]);

    if (enableEditor) {
        return (
            <div className="layers--item-wrapper">
                <EditableLayerItem
                    element={element}
                    name={layerName}
                    groupClassName={groupClassName}
                    isGroup={isGroup}
                    setEnableEditor={setEnableEditor}
                    handleElementNameChange={handleElementNameChange}
                />
            </div>
        );
    }

    return (
        <div className="layers--item-wrapper">
            <LayerItemContainer
                onClick={handleClick}
                onDoubleClick={handleDblClick}
                className={`${groupClassName || ''} ${isSelected || ''} ${visibilityClassName || ''} ${
                    lockClassName || ''
                } ${dragOverlayClassName || ''} ${draggingClassName || ''} ${
                    dragOverClassName || ''
                } d-flex align-items-center ${isContentProperty || ''}`}
                level={level}
                {...testId}
            >
                {isGroup && (
                    <button
                        type="button"
                        className={`layers--item-group-expand btn p-0 border-0 ${isGroupExpand && 'active'}`}
                        onClick={handleGroupExpansion}
                    >
                        <span className="triangle--down" />
                    </button>
                )}
                <GenerateIcon element={element} src={element.src} />
                <div className="layers--item-content">
                    <span>{layerName}</span>
                </div>
                <div className="layers--item-action-btns d-flex align-items-center">
                    <button
                        type="button"
                        disabled={false}
                        className={`btn layers--item-btn p-0 m-0 ${
                            elementLockStatus ? 'layers--item-btn-visible' : ''
                        }`}
                        onClick={toggleLock}
                    >
                        {lockedIcon}
                    </button>
                    <button
                        type="button"
                        className={`btn layers--item-btn p-0 m-0 ${isHidden ? 'layers--item-btn-visible' : ''}`}
                        onClick={toggleVisibility}
                    >
                        {visibilityIcon}
                    </button>
                </div>
            </LayerItemContainer>
        </div>
    );
};

export default LayerItem;
