import { token, Flex } from '@bynder/design-system';
import styled from 'styled-components';
import { IconCheck } from '@bynder/icons';

export const EditorColorContainer = styled(Flex)`
    & > div:first-child {
        flex-grow: 1;
    }
`;

export const SidebarSection = styled.div`
    padding: ${token.spacing3} 0; // TODO: I would need to remove this padding or to find workaround for text element sidebar
    grid-template-columns: minmax(10px, 250px) repeat(auto-fill, 50px);
    gap: ${token.spacing4};
    display: grid;
`;

export const ListItemCheckMark = styled(IconCheck)`
    fill: ${token.gray800};
`;
