import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { token } from '@bynder/design-system';
import { useGradient } from './GradientContext';
import { makeHexWithOpacity } from '../../utils/makeHexWithOpacity';

interface Props {
    index: number;
}

export function Thumb({ index }: Props) {
    const { dispatch, gradient, selected } = useGradient();
    const { color, position } = gradient.stops[index];
    const hexColor = typeof color === 'string' ? color : makeHexWithOpacity(color);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const safariProps = isSafari ? { onPointerDown: onFocus } : {};

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const value = parseInt(e.target.value, 10);

        if (Number.isNaN(value)) {
            return;
        }

        dispatch({ type: 'update-selected-stop', payload: { position: value, lastUpdateSource: 'position' } });
    }

    function onFocus() {
        dispatch({ type: 'select-stop', payload: index });
    }

    return (
        <SliderInput
            $color={hexColor}
            $isSelected={selected === index}
            type="range"
            min="0"
            max="100"
            step={1}
            value={position}
            onChange={onChange}
            onFocus={onFocus}
            // safari doesn't trigger the onFocus event when clicking on the thumb
            {...safariProps}
        />
    );
}

interface SliderInputProps {
    $isSelected?: boolean;
    $color: string;
}

const track = css`
    position: relative;
    box-sizing: border-box;
    height: 24px;
    padding: 0;
    border: 10px solid transparent;
    background: transparent;
    border-inline: none;
    pointer-events: none !important;
    appearance: none;
`;

const thumb = css<SliderInputProps>`
    position: relative;
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    padding: 0;
    background: ${(props) => props.$color};
    pointer-events: auto;
    transform: translateY(-50%);
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    appearance: none;
    border-radius: 50%;
    outline: ${(props) => (props.$isSelected ? '2px' : '1px')} solid
        ${(props) => (props.$isSelected ? props.$color : token.gray100)};
    border: 4px solid ${token.white};
    transition:
        width 100ms ease,
        height 100ms ease,
        border 100ms ease;

    &:active {
        width: 20px;
        height: 20px;
        border: 6px solid ${token.white};
    }
`;

export const SliderInput = styled.input<SliderInputProps>`
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    cursor: 'pointer';
    pointer-events: none;
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    appearance: none;

    &:focus,
    &:active {
        z-index: 2;
    }

    &:focus {
        outline: none;
    }

    &::-webkit-slider-thumb {
        ${thumb}
    }

    &::-moz-range-thumb {
        ${thumb}
        transform: none;
        width: 8px;
        height: 8px;

        &:active {
            width: 8px;
            height: 8px;
            border: 4px solid ${token.white};
        }
    }

    &::-webkit-slider-runnable-track {
        ${track}
    }

    &::-moz-range-track {
        ${track}
    }
`;
