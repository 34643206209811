import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { equals } from 'rambda';
import { OrderFilterType, SortingFilterType } from 'packages/pages/components/filters/components/Ordering/types';
import useDesign from 'packages/pages/design/hooks/useDesign';
import useVariations from 'packages/pages/design/hooks/useVariations';
import useQueryParams from 'packages/hooks/useQueryParams';
import { catchResponseError } from 'packages/helpers/helpers';
import { isVariationInvalid } from 'packages/pages/design/sidebar/variations/utils';
import usePreviousValue from 'packages/hooks/usePrevious';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import features from '~/configs/features';
import VariationSetsService from '~/services/VariationSetsService';
import { VariationSetItemType } from '../types';
import { Props } from './types';
import useAccessRights from 'packages/hooks/useAccessRights';

export const ExportContext = createContext({});

const usePages = (creativeModel) =>
    useMemo(() => {
        const models = creativeModel.getModels();

        return creativeModel
            .getModelsMetaData()
            .map((page, index) => {
                const model = models[index];
                const dimension = model.getDimensions();
                const width = dimension.getWidth();
                const height = dimension.getHeight();
                return {
                    ...page,
                    index,
                    width,
                    height,
                };
            })
            .sort((a, b) => a.displayOrder - b.displayOrder);
    }, [creativeModel]);

export function ExportProvider({ children }: Props) {
    const { isBynderScopeAllowed } = useAccessRights();
    const { oversetVariationsSet, variations, sortVariations } = useVariations();
    const { getQueryParam } = useQueryParams();
    const queriedSearch = getQueryParam('search');
    const queriedGroupBy = getQueryParam('groupBy');
    const queriedOrderBy = getQueryParam('orderBy');
    const queriedView = getQueryParam('view');
    const pageLimit = 50;
    const hasMultiVariationFeature = AuthorizationHelper.isFeatureAvailable(features.MULTI_VARIATION);
    const { creativeVersionId, creativeModel, creativeType, template } = useDesign();
    const pages = usePages(creativeModel);
    const [loading, setLoading] = useState(true);
    const [hadLoading, setHardLoading] = useState(true);
    const [loadingNextPart, setLoadingNextPart] = useState(true);
    const [items, setItems] = useState([] as VariationSetItemType[]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedIds, setSelectedIds] = useState({} as { [key: VariationSetItemType['variationSetId']]: boolean });
    const [searchValue, setSearchValue] = useState<string>(queriedSearch || '');
    const [view, setView] = useState<string>(queriedView || 'grid');
    const [groupBy, setGroupBy] = useState<string>(queriedGroupBy || 'variation');
    const [orderBy, setOrderBy] = useState<string>(queriedOrderBy || 'updated');
    const [pageIds, setPageIds] = useState<VariationSetItemType['variationSetId'][]>(() =>
        pages.map((page) => page.id),
    );
    const [variationSetsLoading, setVariationSetsLoading] = useState(false);
    const [variationSetsTotal, setVariationSetsTotal] = useState(0);
    const [variationSets, setVariationSets] = useState([] as VariationSetItemType[]);
    const [variationSetIds, setVariationSetIds] = useState([] as VariationSetItemType['variationSetId'][]);
    const [selectedJob, setSelectedJob] = useState(null);
    const totalItems = useMemo(() => items.reduce((acc, item) => acc + item.entries.length, 0), [items]);
    const pageSets = items.every((item) => item.aggregateBase === 'PAGE') ? items : [];
    const [fetchVariations, setFetchVariations] = useState(false);

    const totalValidItems = useMemo(
        () =>
            items.reduce(
                (acc, item) =>
                    acc + (isVariationInvalid(item) ? 0 : item.entries.filter((e) => !isVariationInvalid(e)).length),
                0,
            ),
        [items],
    );
    const variationSetsParam = useMemo(() => {
        if (!variationSetIds.length) {
            return null;
        }
        if (variationSets.length === variationSetIds.length) {
            return null;
        }
        return variationSetIds;
    }, [variationSets, variationSetIds]);

    const params = useMemo(
        () => ({
            name: searchValue.trim(),
            pages: pageIds,
            variationSets: variationSetsParam || [],
            includeHasDeletedAssetFlag: true,
            groupBy,
            orderBy,
            sortingOrder:
                orderBy === SortingFilterType.UPDATED ? OrderFilterType.DESCENDING : OrderFilterType.ASCENDING,
        }),
        [searchValue, groupBy, orderBy, pageIds, variationSetsParam],
    );

    const prevParams = usePreviousValue(params);

    const selectItemEntries = useCallback((item, select) => {
        setSelectedIds((selected) => {
            let diffCount = 0;
            item.entries.forEach((entry) => {
                const { variationId } = entry;

                if (select) {
                    if (!(variationId in selected) && !isVariationInvalid(entry)) {
                        diffCount++;
                        selected[variationId] = true;
                    }
                } else {
                    if (variationId in selected) {
                        diffCount--;
                        delete selected[variationId];
                    }
                }
            });
            setSelectedCount((count) => count + diffCount);
            return { ...selected };
        });
    }, []);

    const toggleSelect = useCallback((id) => {
        setSelectedIds((selected) => {
            if (id in selected) {
                delete selected[id];
                setSelectedCount((count) => count - 1);
                return { ...selected };
            }

            setSelectedCount((count) => count + 1);
            return { ...selected, [id]: true };
        });
    }, []);

    const selectFirst = useCallback(() => {
        setSelectedIds(() => {
            const selected = {};

            items[0].entries.forEach((entry) => {
                if (isVariationInvalid(entry) || Object.keys(selected).length) return;
                selected[entry.variationId] = true;
            });
            setSelectedCount(Object.values(selected).length);
            return selected;
        });
    }, [items]);

    const selectAll = useCallback(() => {
        setSelectedIds(() => {
            const selected = {};

            items.forEach((item, idx) => {
                if (isVariationInvalid(item)) {
                    return;
                }

                item.entries.forEach((entry) => {
                    if (isVariationInvalid(entry)) {
                        return;
                    }

                    selected[entry.variationId] = true;
                });
            });

            // todo: move it out from here
            // setState callback should be a pure function
            setSelectedCount(Object.values(selected).length);
            return selected;
        });
    }, [items]);

    const deselectAll = useCallback(() => {
        setSelectedIds(() => ({}));
        setSelectedCount(0);
    }, []);

    const populateWithOversetValidation = useCallback(
        (items: VariationSetItemType[]) => {
            if (groupBy === 'variation') {
                return items.map((item) => ({
                    ...item,
                    hasOversetText: oversetVariationsSet.has(item.variationSetId),
                }));
            }

            return items.map((item) => ({
                ...item,
                entries: item.entries.map((entry) => ({
                    ...entry,
                    hasOversetText: oversetVariationsSet.has(entry.variationSetId),
                })),
            }));
        },
        [oversetVariationsSet, groupBy],
    );

    const transformVariations = useCallback(() => {
        return variations.reduce((acc, variation, index) => {
            acc.push({
                ...variation,
                entries: [...Object.values(variation.sizes)],
            });

            delete acc[index].sizes;

            return acc;
        }, []);
    }, [variations]);

    useEffect(() => {
        if (total === 1) {
            selectAll();
        }
    }, [total]);

    useEffect(() => {
        if (items.length && !hasMultiVariationFeature) {
            selectFirst();
        }
    }, [items]);

    useEffect(() => {
        setLoading(true);
        setPage(0);
    }, [creativeVersionId, params]);

    useEffect(() => {
        if (variations && (!queriedGroupBy || queriedGroupBy === 'variation') && !queriedSearch) {
            setLoading(false);
            setHardLoading(false);
            setLoadingNextPart(false);
            setTotal(variations.length);

            setItems(() => populateWithOversetValidation(transformVariations()));
        }
    }, [variations, queriedGroupBy, populateWithOversetValidation, queriedSearch]);

    useEffect(() => {
        if (variations && (!queriedGroupBy || queriedGroupBy === 'variation') && !queriedSearch) {
            sortVariations(orderBy);
            setLoading(false);
            setHardLoading(false);
            setLoadingNextPart(false);
            setTotal(variations.length);

            if (!variationSets.length) {
                setVariationSets(variations);
                setVariationSetsTotal(variations.length);
                setVariationSetsLoading(false);
            }

            return;
        }

        if (equals(prevParams, params) && queriedGroupBy === 'variation') {
            setLoading(false);

            return;
        }

        VariationSetsService.fetchVariationSetsByAggregate(creativeVersionId, {
            ...params,
            page,
            limit: pageLimit,
        })
            .then(({ status, json }) => {
                unstable_batchedUpdates(() => {
                    setLoading(false);
                    setHardLoading(false);
                    setLoadingNextPart(false);
                    const data = status === 200 ? json.items : [];
                    const total = status === 200 ? json.totalItems : 0;
                    setItems((items) => populateWithOversetValidation(!page ? data : [...items, ...data]));
                    setTotal(total);
                });
            })
            .catch(catchResponseError);
    }, [params, page, queriedGroupBy]);

    useEffect(() => {
        setItems((items) => populateWithOversetValidation(items));
    }, [oversetVariationsSet]);

    useEffect(() => {
        setItems(() => populateWithOversetValidation(transformVariations()));

        if (variations.length === 1) {
            const firstSizeVariationIds = Object.values(variations[0].sizes).reduce(
                (acc: Record<string, boolean>, size: any) => {
                    return { ...acc, [size.variationId]: true };
                },
                {},
            );

            setSelectedIds(firstSizeVariationIds);
        }
    }, [variations]);

    const loadNextPart = useCallback(() => {
        if (items.length >= total) {
            return;
        }

        setLoadingNextPart(true);
        setPage((p) => p + 1);
    }, [total, items]);

    const getNextVariationSetsPart = useCallback(() => {
        if (variationSetsTotal === variationSets.length || variationSetsLoading) {
            return;
        }

        setVariationSetsLoading(true);
        VariationSetsService.fetchVariationSetsByAggregate(creativeVersionId, {
            groupBy: 'variation',
            orderBy: 'updated',
            sortingOrder: 'DESC',
            page: Math.floor(variationSets.length / 10),
            limit: 10,
        })
            .then(({ status, json }) => {
                const data = status === 200 ? json.items : [];
                const totalCount = status === 200 ? json.totalItems : 0;

                setVariationSets([...variationSets, ...data]);
                setVariationSetsTotal(totalCount);
                setVariationSetsLoading(false);
            })
            .catch(catchResponseError);
    }, [creativeVersionId, variationSets, variationSetsTotal, variationSetsLoading]);

    const { exportIntegrations } = template;
    const isDownloadBlocked = useMemo(
        () => exportIntegrations?.find((item) => item?.channelType === 'DOWNLOAD')?.enabled === false,
        [exportIntegrations],
    );
    const isWaitingRoomBlocked = useMemo(
        () =>
            !isBynderScopeAllowed(['asset:write']) ||
            exportIntegrations?.find((item) => item?.channelType === 'BYNDER')?.enabled === false,
        [exportIntegrations],
    );

    const value = {
        loading,
        hadLoading,
        loadNextPart,
        loadingNextPart,
        view,
        setView,
        groupBy,
        setGroupBy,
        orderBy,
        setOrderBy,
        searchValue,
        setSearchValue,
        pages,
        pageIds,
        setPageIds,
        variationSets,
        variationSetIds,
        setVariationSetIds,
        variationSetsLoading,
        getNextVariationSetsPart,
        total,
        totalItems,
        totalValidItems,
        items,
        selectedIds,
        selectedCount,
        toggleSelect,
        selectAll,
        deselectAll,
        selectItemEntries,
        creativeType,
        isDownloadBlocked,
        isWaitingRoomBlocked,
        selectedJob,
        setSelectedJob,
        setSelectedIds,
        setSelectedCount,
        pageSets,
        fetchVariations,
        setFetchVariations,
        selectFirst,
    };

    return <ExportContext.Provider value={value}>{children}</ExportContext.Provider>;
}
