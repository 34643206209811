import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { BaseMultiPageModel, ContentPropertiesManager, ContentProperty, TextElement } from '@bynder-studio/render-core';
import { getFontById } from '@bynder-studio/structured-text';
import { Button, List, ModalBase, Tabs, Thumbnail, token } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import { markAsUnsaved } from 'packages/store/creativeEditor/creativeEditor.actions';
import {
    collectBrandColorIds,
    collectCustomColors,
    collectElementBrandColorIds,
    collectElementStyleIds,
    collectStyleIds,
    textStyleSubtext,
} from '../utils';
import FontThumbnail from '../shared/FontThumbnail';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import { hexToRgb, isGradientColor } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import modalContainer from 'packages/common/modalContainer';
import { brandColor2ColorParams, colorParams2Color } from '~/common/editor/editorHelper';
import { GradientThumbnail } from 'packages/ds/GradientThumbnail/GradientThumbnail';

type ElementStylesModalProps = {
    isOpen: boolean;
    defaultTab?: 'textStyles' | 'colors';
    onClose: () => void;
    creativeModel: BaseMultiPageModel;
    element?: TextElement;
};

const Box = styled.div`
    padding-bottom: ${token.spacing4};
    padding-left: ${token.spacing6};
    padding-right: ${token.spacing6};
`;

const Body = styled.div`
    max-height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: ${token.spacing6};
    padding-right: ${token.spacing6};
    position: relative;
`;

const CustomModalContent = styled.div`
    grid-area: content;
`;

const pass = () => {};

export const ElementStylesModal: FC<ElementStylesModalProps> = ({
    isOpen,
    defaultTab,
    onClose,
    creativeModel,
    element,
}) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const [isSaveAllowed, setIsSaveAllowed] = useState(false);
    const textStyles = creativeModel.getTextStyles();
    const appliedStyles = useMemo(() => collectStyleIds(element), [element?.id, isOpen]);
    const appliedColors = useMemo(() => collectBrandColorIds(element), [element?.id, isOpen]);
    const customColors = useMemo(() => collectCustomColors(element), [element?.id, isOpen]);
    const [selectedTextStyles, setSelectedTextStyles] = useState<string[]>([]);
    const [selectedBrandColors, setSelectedBrandColors] = useState<number[]>([]);
    const [tab, setTab] = useState<'textStyles' | 'colors'>(defaultTab || 'textStyles');
    const { palettes: rawPalettes } = useSelector(colorPalettes);

    const palettes = useMemo(() => {
        return rawPalettes
            .map((palette) => {
                const filteredColors = palette.brandColors.filter((c) => ['SOLID']?.includes(c.type));
                return {
                    ...palette,
                    brandColors: filteredColors,
                };
            })
            .filter((palette) => !!palette.brandColors.length);
    }, [rawPalettes]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        setIsSaveAllowed(false);
        setTab(defaultTab || tab);
        setSelectedTextStyles([...collectElementStyleIds(element)]);
        setSelectedBrandColors([...collectElementBrandColorIds(element)]);
    }, [isOpen, element?.id]);

    const handleSave = useCallback(() => {
        if (!(element instanceof TextElement)) {
            return;
        }

        if (element.contentPropertyId) {
            const { contentPropertyId } = element;
            const contentPropertyManager: ContentPropertiesManager = creativeModel.getContentPropertiesManager();
            const contentProperty = contentPropertyManager.getContentProperty(contentPropertyId);

            if (contentProperty) {
                contentPropertyManager.updateContentProperty({
                    ...contentProperty,
                    properties: {
                        ...contentProperty?.properties,
                        textStyles: [...selectedTextStyles],
                        brandColors: [...selectedBrandColors],
                    },
                } as Partial<ContentProperty>);
            }
        } else {
            creativeModel.updateElement(element.id, {
                textStyles: [...selectedTextStyles],
                brandColors: [...selectedBrandColors],
            });
        }

        dispatch(markAsUnsaved());
        onClose();
    }, [creativeModel, element?.id, selectedTextStyles, selectedBrandColors, dispatch, onClose]);

    const toggleTextStyle = useCallback<(uuid: string) => void>((uuid) => {
        setIsSaveAllowed(true);
        setSelectedTextStyles((prev) => {
            if (prev.includes(uuid)) {
                return prev.filter((id) => id !== uuid);
            }

            return [...prev, uuid];
        });
    }, []);

    const toggleSelectAllTextStyles = useCallback(() => {
        setIsSaveAllowed(true);
        setSelectedTextStyles((prev) => {
            if (prev.length === textStyles.list.length) {
                return [...appliedStyles].filter((s) => !!s) as string[];
            }

            return textStyles.list.map((textStyle) => textStyle.uuid);
        });
    }, [textStyles, appliedStyles]);

    const toggleBrandColor = useCallback<(brandColorId: number) => void>((brandColorId) => {
        setIsSaveAllowed(true);
        setSelectedBrandColors((prev) => {
            if (prev.includes(brandColorId)) {
                return prev.filter((id) => id !== brandColorId);
            }

            return [...prev, brandColorId];
        });
    }, []);

    const toggleSelectAllBrandColors = useCallback<(paletteId: number) => void>(
        (paletteId) => {
            setIsSaveAllowed(true);
            const palette = palettes.find((p) => p.id === paletteId);

            if (!palette) {
                return;
            }

            const brandColorIdSet = new Set(palette.brandColors.map((color) => color.id));

            setSelectedBrandColors((prev) => {
                const isAllPaletteColorsSelected = palette.brandColors.every((color) => prev.includes(color.id));

                if (isAllPaletteColorsSelected) {
                    return prev.filter((id) => appliedColors.has(id) || !brandColorIdSet.has(id));
                }

                return [...new Set([...prev, ...palette.brandColors.map((color) => color.id)])];
            });
        },
        [appliedColors, palettes],
    );

    return (
        <ModalBase container={modalContainer} isOpen={isOpen} onClose={onClose}>
            <ModalBase.Header title={translate('editor.sidebar.shots.text.textStyle.modal.elementStyles.title')} />
            <CustomModalContent>
                <Box>
                    <Tabs>
                        <Tabs.Tab
                            label={translate('editor.sidebar.shots.text.textStyle.modal.elementStyles.text-styles')}
                            isActive={tab === 'textStyles'}
                            onClick={() => {
                                setTab('textStyles');
                            }}
                        />
                        <Tabs.Tab
                            label={translate('editor.sidebar.shots.text.textStyle.modal.elementStyles.color-styles')}
                            isActive={tab === 'colors'}
                            onClick={() => {
                                setTab('colors');
                            }}
                        />
                    </Tabs>
                </Box>
                <Body>
                    {tab === 'textStyles' && (
                        <List>
                            <List.Item
                                checkedVariant="checkbox"
                                isChecked={selectedTextStyles.length === textStyles.list.length}
                                isIndeterminateCheckbox={
                                    selectedTextStyles.length > 0 && selectedTextStyles.length < textStyles.list.length
                                }
                                isDisabled={textStyles.list.every((textStyle) => appliedStyles.has(textStyle.uuid))}
                                onClick={toggleSelectAllTextStyles}
                                subtext={translate(
                                    'editor.sidebar.shots.text.textStyle.modal.elementStyles.selected-styles',
                                    {
                                        selected: selectedTextStyles.length,
                                        total: textStyles.list.length,
                                    },
                                )}
                            >
                                {translate('editor.sidebar.shots.text.textStyle.modal.elementStyles.all-styles')}
                            </List.Item>
                            {textStyles.list.map((textStyle, i) => (
                                <Fragment key={textStyle.uuid}>
                                    <List.Item
                                        checkedVariant="checkbox"
                                        isChecked={
                                            appliedStyles.has(textStyle.uuid) ||
                                            selectedTextStyles.includes(textStyle.uuid)
                                        }
                                        isDisabled={appliedStyles.has(textStyle.uuid)}
                                        onClick={() => toggleTextStyle(textStyle.uuid)}
                                        subtext={textStyleSubtext(textStyle)}
                                        thumbnail={
                                            <FontThumbnail
                                                id={textStyle.uuid + textStyle.fontId}
                                                url={getFontById(textStyle.fontId)?.url ?? ''}
                                            />
                                        }
                                    >
                                        {textStyle.name}
                                    </List.Item>
                                    {i < textStyles.list.length - 1 && <List.Divider />}
                                </Fragment>
                            ))}
                        </List>
                    )}
                    {tab === 'colors' && (
                        <List>
                            {!!customColors.length && (
                                <List.Item
                                    checkedVariant="checkbox"
                                    isChecked
                                    isDisabled
                                    onClick={pass}
                                    subtext={translate(
                                        'editor.sidebar.shots.text.textStyle.modal.elementColors.selected-custom-colors',
                                        {
                                            selected: customColors.length,
                                            total: customColors.length,
                                        },
                                    )}
                                >
                                    {translate(
                                        'editor.sidebar.shots.text.textStyle.modal.elementColors.all-custom-colors',
                                    )}
                                </List.Item>
                            )}
                            {[...customColors].map((color) => (
                                <List.Item
                                    key={color.hexCode}
                                    checkedVariant="checkbox"
                                    isChecked
                                    isDisabled
                                    onClick={pass}
                                    thumbnail={
                                        <Thumbnail
                                            shape="circle"
                                            backgroundColor={hexToRgb(color.hexCode, color.alpha)}
                                            variant="content"
                                            size="s"
                                        />
                                    }
                                >
                                    {color.hexCode.toLocaleUpperCase()}
                                </List.Item>
                            ))}

                            {palettes
                                .filter((data) => !!data.brandColors.length)
                                .map((palette, index) => (
                                    <Fragment key={palette.id}>
                                        {(index > 0 || !!customColors.length) && <List.Divider />}
                                        <List.Item
                                            checkedVariant="checkbox"
                                            isChecked={palette.brandColors.every((color) =>
                                                selectedBrandColors.includes(color.id),
                                            )}
                                            isIndeterminateCheckbox={
                                                !palette.brandColors.every((color) =>
                                                    selectedBrandColors.includes(color.id),
                                                ) &&
                                                palette.brandColors.some((color) =>
                                                    selectedBrandColors.includes(color.id),
                                                )
                                            }
                                            isDisabled={palette.brandColors.every((color) =>
                                                appliedColors.has(color.id),
                                            )}
                                            onClick={() => toggleSelectAllBrandColors(palette.id)}
                                            subtext={translate(
                                                'editor.sidebar.shots.text.textStyle.modal.elementColors.selected-colors',
                                                {
                                                    selected: palette.brandColors.filter((color) =>
                                                        selectedBrandColors.includes(color.id),
                                                    ).length,
                                                    total: palette.brandColors.length,
                                                },
                                            )}
                                        >
                                            {palette.name}
                                        </List.Item>
                                        {palette.brandColors.map((color) => (
                                            <List.Item
                                                key={color.id}
                                                checkedVariant="checkbox"
                                                isChecked={selectedBrandColors.includes(color.id)}
                                                isDisabled={appliedColors.has(color.id)}
                                                onClick={() => toggleBrandColor(color.id)}
                                                thumbnail={
                                                    isGradientColor(color) ? (
                                                        <GradientThumbnail
                                                            size="s"
                                                            {...(colorParams2Color(
                                                                brandColor2ColorParams(color),
                                                            ) as object)}
                                                        />
                                                    ) : (
                                                        <Thumbnail
                                                            shape="circle"
                                                            backgroundColor={hexToRgb(color.hexCode, color.alpha)}
                                                            variant="content"
                                                            size="s"
                                                        />
                                                    )
                                                }
                                            >
                                                {color.name}
                                            </List.Item>
                                        ))}
                                    </Fragment>
                                ))}
                        </List>
                    )}
                </Body>
            </CustomModalContent>
            <ModalBase.Footer
                actionPrimary={
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        isDisabled={!isSaveAllowed}
                        {...generateTestId('shots_text_element_styles_modal_save')}
                    >
                        {translate('editor.sidebar.shots.text.textStyle.modal.elementStyles.save')}
                    </Button>
                }
                actionSecondary={
                    <Button
                        variant="secondary"
                        onClick={onClose}
                        {...generateTestId('shots_text_element_styles_modal_cancel')}
                    >
                        {translate('editor.sidebar.shots.text.textStyle.modal.elementStyles.cancel')}
                    </Button>
                }
            />
        </ModalBase>
    );
};
