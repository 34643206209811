import { useCallback, useMemo } from 'react';
import {
    type BackgroundColorParams,
    BaseMultiPageModel,
    type ShapeElement,
    type TextElement,
} from '@bynder-studio/render-core';
import { ColorParams } from '@bynder-studio/misc';
import { BrandColor } from 'packages/store/brandColors/types';
import { brandColor2ColorParams } from '~/common/editor/editorHelper';
import { brandColorsIdsToColors } from 'packages/pages/editor/RightSideMenu/Shots/utils';
import { usePalettes } from 'packages/pages/design/hooks/usePalettes';

type Props = {
    creativeModel: BaseMultiPageModel;
    selectedElement: ShapeElement | TextElement | BackgroundColorParams;
    propertyName: string;
    brandColorProperty: string;
    globalPropertyName?: string;
};
/**
 * propertyName can be nested, e.g. 'shape.fill.color'
 */
export const useShotColor = ({
    creativeModel,
    selectedElement,
    propertyName,
    brandColorProperty,
    globalPropertyName = '',
}: Props) => {
    const { byId } = usePalettes();

    const propertyToChange = useMemo(() => propertyName.split('.')[0], [propertyName]);

    const currentColor: ColorParams = useMemo(
        () => propertyName.split('.').reduce((accum, p) => accum[p], selectedElement),
        [selectedElement[propertyToChange]],
    );

    const handleChangeColor = useCallback(
        (name: string, value: ColorParams) => {
            let valueToApply = { [name]: value };

            if (globalPropertyName) {
                creativeModel.updateGlobalProperty(globalPropertyName, valueToApply);
            } else {
                const propertyNamePath = propertyName.split('.');

                if (propertyNamePath.length > 1) {
                    const propToChange = propertyNamePath[propertyNamePath.length - 1];
                    const propToSave = propertyNamePath[0];
                    const clonedObj = propertyNamePath.slice(1, propertyNamePath.length).reduce(
                        (accum, current) => ({
                            ...accum,
                            [current]: { ...accum[current] },
                        }),
                        selectedElement[propToSave],
                    );
                    valueToApply = {
                        [propToSave]: {
                            ...clonedObj,
                            [propToChange]: value,
                        },
                    };
                }

                creativeModel.updateElement(selectedElement.id, valueToApply);
            }
        },
        [globalPropertyName, creativeModel, selectedElement],
    );

    const onAppliedClick = useCallback(
        (color: BrandColor | ColorParams) => {
            const isBrandColor = 'id' in color;
            const value = isBrandColor ? brandColor2ColorParams(color) : color;

            handleChangeColor(propertyName, value);
        },
        [handleChangeColor, propertyName],
    );

    const appliedCustomColors = useMemo(() => (currentColor?.brandColorId ? [] : [currentColor]), [currentColor]);

    const elementColors = useMemo(() => {
        return [
            ...appliedCustomColors,
            ...brandColorsIdsToColors(
                [...new Set([...selectedElement[brandColorProperty], currentColor?.brandColorId])],
                byId,
            ),
        ];
    }, [appliedCustomColors, selectedElement[brandColorProperty], selectedElement, byId, currentColor?.brandColorId]);

    return {
        appliedCustomColors,
        elementColors,
        onAppliedClick,
        handleChangeColor,
    };
};
