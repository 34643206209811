import React from 'react';
import { useGradient } from './GradientContext';
import { Thumb } from './Thumb';
import { Track } from './Track';

export function Slider() {
    const { gradient } = useGradient();

    return (
        <Track>
            {gradient.stops.map((_, index) => (
                // We don't have a unique key for the color stops
                // eslint-disable-next-line react/no-array-index-key
                <Thumb key={index} index={index} />
            ))}
        </Track>
    );
}
