import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { type ColorParams, type TextElement as RenderTextElement, type TextStyles } from '@bynder-studio/render-core';
import { useTranslate } from '@bynder/localization';
import { Divider, Form } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';
import {
    collectColorsFromTemplate,
    collectStylIdsFromTemplate,
} from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/utils';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import {
    hexToRgb,
    isGradientColor,
    rgbToHex,
} from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import { flattenTree } from '~/common/editor/helpers/elementtree';
import { brandColor2ColorParams, colorToCSS, rgbaToObject } from '~/common/editor/editorHelper';
import { envVar } from '~/helpers/envVar';
import { getDiff } from './diff';
import { useTextareaSelectionChange } from './useTextareaSelectionChange';
import useElement from '../../hooks/useElement';
import useDesign from '../../hooks/useDesign';
import useVariations from '../../hooks/useVariations';
import { TextColor, TextColorPRef } from './TextColor';
import { TextStyle, TextStyleRef } from './TextStyle';
import useMultiPageValidation from '~/hooks/useMultiPageValidation';
import useElementValidation from '~/hooks/useElementValidation';
import { Textarea, TextareaToolBar, TextareaWrapper } from './Component.styled';
import BackgroundColor from './BackgroundColor';
import { TextDirection } from 'packages/pages/design/components/TextElement/TextDirection';
import { useTextElementDirection } from 'packages/pages/design/hooks/useTextElementDirection';

type TextElementProps = {
    elementId: number;
    elementName: string;
    isLoading: boolean;
    disabled?: boolean;
};

const preventSubmit = (event) => event.preventDefault();

export const TextElement: FC<TextElementProps> = ({ elementId, elementName, disabled, isLoading }) => {
    const { translate } = useTranslate();
    const { key, element, syncContentProperty, updateElement } = useElement(elementId);
    const { creativeModel, creativeType, template } = useDesign();
    const { currentVariation, updateVariationOverset } = useVariations();
    const textStyles = creativeModel.getTextStyles() as TextStyles;
    const testId = useMemo(() => generateTestId(`element_text_${elementId}`), [elementId]);
    const text = (element as RenderTextElement).getTextProps().value;
    const [value, setValue] = useState(text);
    const [focus, setFocus] = useState(false);
    const textStyleRef = useRef<TextStyleRef>(null);
    const textColorRef = useRef<TextColorPRef>(null);
    const dataRef = useRef({ value, start: 0, end: 0, focused: false, blurTimeout: 0 });
    dataRef.current.value = value;
    const { palettes } = useSelector(colorPalettes);
    const maxLength = envVar.designMaxCharacters(creativeType);
    const placeholder = text || elementName;
    const selectedPageIndex = creativeModel.getCurrentPageIndex();
    const shiftKeyRef = useRef(false);
    const { showDirectionMode, direction } = useTextElementDirection(elementId);

    const handleKeyUp = useCallback((event: React.KeyboardEvent) => {
        const isShift = event.key === 'Shift';

        if (!isShift) {
            return;
        }

        shiftKeyRef.current = !isShift;
    }, []);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        const isShift = event.key === 'Shift';

        if (!isShift) {
            return;
        }

        shiftKeyRef.current = isShift;
    }, []);

    const isValid = useMultiPageValidation();
    const { isValid: isElementValid } = useElementValidation(elementId);

    const templateStyleIds = useMemo(
        () => collectStylIdsFromTemplate(flattenTree(template.pages[selectedPageIndex].elements)[elementId]),
        [elementId, selectedPageIndex, template.pages],
    );
    const list = textStyles.list.filter((style) => templateStyleIds.has(style.uuid));

    const templateColors = useMemo(
        () => collectColorsFromTemplate(flattenTree(template.pages[selectedPageIndex].elements)[elementId], palettes),
        [template.pages, selectedPageIndex, elementId, palettes],
    );

    const groups = useMemo(() => {
        const brandColorIds = new Set(templateColors.map((c) => c.brandColorId));
        const result: { name: string; colors: { name: string; color: ColorParams }[] }[] = [];

        palettes.forEach((group) => {
            const groupItem: { name: string; colors: { name: string; color: ColorParams }[] } = {
                name: group.name,
                colors: [],
            };

            groupItem.colors = group.brandColors
                .filter((color) => brandColorIds.has(color.id))
                .map((color) => ({
                    name: color.name,
                    color: isGradientColor(color)
                        ? {
                              brandColorId: color.id,
                              ...brandColor2ColorParams(color),
                          }
                        : {
                              brandColorId: color.id,
                              ...rgbaToObject(hexToRgb(color.hexCode, color.alpha)),
                          },
                }));

            if (groupItem.colors.length > 0) {
                result.push(groupItem);
            }
        });

        const usedBrandColorIds = new Set(
            result.flatMap((item) => item.colors.map((color) => color.color.brandColorId)),
        );
        const customColors = templateColors.filter((c) => !c.brandColorId || !usedBrandColorIds.has(c.brandColorId));

        if (customColors.length > 0) {
            result.push({
                name: 'Custom',
                colors: customColors.map((color) => ({ name: rgbToHex(colorToCSS(color)).toLocaleUpperCase(), color })),
            });
        }

        return result;
    }, [templateColors, palettes]);

    useEffect(() => {
        if (isLoading) {
            setValue('');

            return;
        }

        setValue(text);
        dataRef.current.start = 0;
        dataRef.current.end = text.length;

        if (!textStyleRef.current && !textColorRef.current) {
            return;
        }

        const textSettings = (element as RenderTextElement).getTextSettingForSelection();

        if (textStyleRef.current) {
            const styles = (textSettings.styleId ?? []).filter((id) => id !== null) as string[];
            textStyleRef.current.setStyles(styles);
        }

        if (textColorRef.current) {
            textColorRef.current.setColors(textSettings.color || []);
        }
    }, [key, currentVariation, isLoading]);

    const handleSelectionChange = useCallback<(start: number, end: number) => void>(
        (start, end) => {
            dataRef.current.start = start;
            dataRef.current.end = end;

            if (!textStyleRef.current && !textColorRef.current) {
                return;
            }

            const textSettings = element.getTextSettingForSelection(start, end);

            if (textStyleRef.current) {
                textStyleRef.current.setStyles(textSettings.styleId || []);
            }

            if (textColorRef.current) {
                textColorRef.current.setColors(textSettings.color || []);
            }
        },
        [element],
    );

    const textarea = useTextareaSelectionChange(elementId.toString(), handleSelectionChange);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>) => {
            if (!textarea.current) {
                return;
            }

            const newValue = event.currentTarget.value;
            setValue(newValue);

            const { selectionStart, selectionEnd } = textarea.current;

            let { start, end, diff } = getDiff(dataRef.current.value, newValue, selectionStart, selectionEnd);

            dataRef.current.start = selectionStart;
            dataRef.current.end = selectionEnd;
            dataRef.current.value = newValue;

            if (event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType === 'insertFromDrop') {
                start = selectionStart;
                end = start;
                diff = newValue.substring(dataRef.current.start, dataRef.current.end);
            }

            const data = {
                updateTextForSelection: {
                    start,
                    end,
                    diff,
                    updateLayout: !shiftKeyRef.current,
                },
            };

            if (element.contentPropertyId) {
                syncContentProperty(data);
            } else {
                updateElement(data);
            }
        },
        [textarea, element.contentPropertyId, syncContentProperty, updateElement],
    );

    const handleChangeStyle = useCallback(
        (style: any) => {
            if (!textarea.current) {
                return;
            }

            const {
                start,
                end,
                value: { length },
                focused,
            } = dataRef.current;
            const params = { start, end, settings: style };

            if (!focused) {
                params.start = 0;
                params.end = length;
            }

            if (element.contentPropertyId) {
                syncContentProperty({ updateTextSettingForSelection: params });
            } else {
                updateElement({ updateTextSettingForSelection: params });
            }
        },
        [textarea, element.contentPropertyId, syncContentProperty, updateElement],
    );

    const relocateFocus = useCallback(() => {
        if (dataRef.current.focused) {
            clearTimeout(dataRef.current.blurTimeout);
            textarea.current?.focus();
        }
    }, [textarea]);

    const handleTextareaFocus = () => {
        if (element.limitTextToBounds) {
            creativeModel.updateElement(element.id, { showOversetBox: true });
        }

        dataRef.current.focused = true;
        setFocus(true);
    };

    const handleTextareaBlur = useCallback(() => {
        if (element.limitTextToBounds) {
            creativeModel.updateElement(element.id, { showOversetBox: false });
        }

        dataRef.current.blurTimeout = setTimeout(() => {
            dataRef.current.focused = false;
            handleSelectionChange(0, dataRef.current.value.length);
            setFocus(false);
        }) as unknown as number;
    }, [handleSelectionChange, creativeModel, element]);

    const handleAutoGrow = useCallback(() => {
        if (!textarea.current) {
            return;
        }

        const textareaEl = textarea.current;
        textareaEl.style.height = 'auto';
        textareaEl.style.height = `${textareaEl.scrollHeight}px`;
    }, [textarea]);

    useEffect(() => {
        if (element.limitTextToBounds) {
            updateVariationOverset({ isValid, currentVariation });
        }
    }, [isValid]);

    useEffect(() => {
        handleAutoGrow();
    }, [value]);

    useEffect(() => {
        if (!element.contentPropertyId) {
            return;
        }

        setValue(text);
    }, [text, element.contentPropertyId]);

    useEffect(() => {
        if (element.contentPropertyId) {
            const { start, end } = dataRef.current;

            const textSettings = element.getTextSettingForSelection(start, end);

            if (textStyleRef.current) {
                textStyleRef.current.setStyles(textSettings.styleId || []);
            }

            if (textColorRef.current) {
                textColorRef.current.setColors(textSettings.color || []);
            }
        }
    }, [element.formattedText.runs]);

    return (
        <Form onSubmit={preventSubmit}>
            <Form.Group>
                <TextareaWrapper
                    isInvalid={!isElementValid}
                    isDisabled={disabled}
                    focus={focus}
                    onFocus={relocateFocus}
                    onBlur={handleTextareaBlur}
                >
                    {(list.length > 1 || templateColors.length > 1 || showDirectionMode) && (
                        <TextareaToolBar tabIndex={-1} onFocus={relocateFocus}>
                            {list.length > 1 && (
                                <>
                                    <TextStyle
                                        ref={textStyleRef}
                                        list={list}
                                        elementId={elementId.toString()}
                                        onChange={handleChangeStyle}
                                        disabled={disabled}
                                    />
                                    <Divider direction="vertical" />
                                </>
                            )}
                            {templateColors.length > 1 && (
                                <TextColor
                                    ref={textColorRef}
                                    colors={groups}
                                    elementId={elementId.toString()}
                                    onChange={handleChangeStyle}
                                    disabled={disabled}
                                />
                            )}
                            {showDirectionMode && <TextDirection elementId={elementId} />}
                        </TextareaToolBar>
                    )}
                    <Textarea
                        ref={textarea}
                        disabled={disabled}
                        key={elementId}
                        placeholder={placeholder}
                        value={value}
                        rows={1}
                        onFocus={handleTextareaFocus}
                        onChange={handleChange}
                        onInput={handleAutoGrow}
                        onKeyUp={handleKeyUp}
                        onKeyDown={handleKeyDown}
                        id={`textarea-${elementId}`}
                        maxLength={maxLength}
                        dir={direction.toLowerCase()}
                        {...testId}
                    />
                </TextareaWrapper>
                <BackgroundColor template={template} elementId={elementId} selectedPageIndex={selectedPageIndex} />
                {!isElementValid && (
                    <Form.HelperText isInvalid>{translate('design.sidebar.shots.text.overset.label')}</Form.HelperText>
                )}
            </Form.Group>
        </Form>
    );
};
