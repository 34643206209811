import React from 'react';
import { Table } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';

const MediaTypeCell = ({ type }: { type: 'IMAGE' | 'AUDIO' | 'VIDEO' }) => {
    const { translate } = useTranslate();

    return <Table.Cell textFlow="no-wrap">{translate(`listview.uploads.column.type.${type}`)}</Table.Cell>;
};

export default MediaTypeCell;
