import { JOB_PROGRESS_STATUS } from 'packages/pages/activity/types';
import { actionTypes, ActivityFeedTypeFilter, ActivityFeedState } from './types';
import { ExportJobItemType } from 'packages/variation-export/AllExports/types';

const initialState: ActivityFeedState = {
    options: {
        page: 0,
        size: 20,
        provideProgress: true,
        includeDeleted: false,
        sortingField: 'UPDATED',
        sortingOrder: 'DESC',
        search: '',
        exportJobType: ActivityFeedTypeFilter.ALL,
        exportJobVariationStatus: [
            JOB_PROGRESS_STATUS.COMPLETED,
            JOB_PROGRESS_STATUS.FAILED,
            JOB_PROGRESS_STATUS.UPLOADING_FAILED,
        ],
    },
    progressOptions: {
        page: 0,
        size: 5,
        provideProgress: true,
        includeDeleted: false,
        sortingField: 'UPDATED',
        sortingOrder: 'DESC',
        search: '',
        exportJobType: ActivityFeedTypeFilter.ALL,
        exportJobVariationStatus: [
            JOB_PROGRESS_STATUS.CREATING,
            JOB_PROGRESS_STATUS.PREPARING,
            JOB_PROGRESS_STATUS.UPLOADING,
        ],
    },
    loading: {
        completedItems: false,
        inProgressItems: false,
    },
    totalItems: 0,
    items: [],
    inProgressItems: [],
    errors: undefined,
};

export default function ActivityFeed(state = initialState, action) {
    switch (action.type) {
        case actionTypes.INIT_ACTIVITY_FEED: {
            return {
                ...initialState,
                options: { ...initialState.options, customerId: action.customerId },
                inProgressOptions: { ...initialState.progressOptions, customerId: action.customerId },
                items: [],
                inProgressItems: [],
            };
        }
        case actionTypes.GET_ACTIVITY_FEED_REQUEST: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    completedItems: true,
                },
                options: action.options,
                items: action.fromStart ? [] : state.items,
                errors: undefined,
            };
        }
        case actionTypes.GET_ACTIVITY_FEED_SUCCESS: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    completedItems: false,
                },
                items: action.fromStart ? action.items : [...state.items, ...action.items],
                totalItems: action.totalItems,
            };
        }
        case actionTypes.GET_ACTIVITY_FEED_FAILURE: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    completedItems: false,
                },
                errors: action.errors,
            };
        }
        case actionTypes.GET_IN_PROGRESS_ACTIVITY_FEED_REQUEST: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    inProgressItems: true,
                },
                progressOptions: action.options,
                inProgressItems: [],
                errors: undefined,
            };
        }
        case actionTypes.GET_IN_PROGRESS_ACTIVITY_FEED_SUCCESS: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    inProgressItems: false,
                },
                inProgressItems: action.items,
            };
        }
        case actionTypes.GET_IN_PROGRESS_ACTIVITY_FEED_FAILURE: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    inProgressItems: false,
                },
                errors: action.errors,
            };
        }
        case actionTypes.DELETE_ACTIVITY_FEED_ITEM_SUCCESS: {
            return {
                ...state,
                items: action.items,
            };
        }
        case actionTypes.DELETE_ACTIVITY_FEED_ITEM_FAILURE: {
            return {
                ...state,
                errors: action.errors,
            };
        }
        case actionTypes.PROGRESS_COMPLETE: {
            return {
                ...state,
                inProgressItems: state.inProgressItems.filter((item: ExportJobItemType) => item.id !== action.id),
            };
        }
        default:
            return state;
    }
}
