import { toFloat } from '../../EditorAutocorrectRules';
import { BrandColor } from 'packages/store/brandColors/types';
import { ColorParams } from '@bynder-studio/misc';
import { hexToRgb, isGradientColor } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import { toRGBA } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/utils';
import { brandColor2ColorParams, rgbaToObject } from '~/common/editor/editorHelper';

export const brandColorsIdsToColors = (brandColorsIds: number[], byId: { [brandColorId: number]: BrandColor }) => {
    const styles = new Set<string | null>();
    const colors: ColorParams[] = [];

    brandColorsIds.forEach((brandColorId: number) => {
        const brandColor = byId[brandColorId];

        if (!brandColor) {
            return;
        }

        // TODO: Adjust colors and brandColors' types
        if (isGradientColor(brandColor)) {
            const convertedColor = brandColor2ColorParams(brandColor);
            const newColor: ColorParams = {
                brandColorId: brandColor.id,
                ...convertedColor,
            };

            const key = newColor.stops.reduce((acc, curr) => acc + toRGBA(curr), newColor.brandColorId);

            if (!styles.has(key)) {
                styles.add(key);
                colors.push(newColor);
            }
        } else {
            const color: ColorParams = {
                brandColorId: brandColor.id,
                ...rgbaToObject(hexToRgb(brandColor.hexCode, brandColor.alpha)),
            };
            const key = toRGBA(color) + color.brandColorId;
            if (!styles.has(key)) {
                styles.add(key);
                colors.push(color);
            }
        }
    });

    return colors;
};

export const parseAlignments = (value) => {
    let customValue;

    switch (value) {
        case 'LEFT':
        case 'TOP':
            customValue = -1;
            break;
        case 'CENTER':
        case 'MIDDLE':
            customValue = 0;
            break;
        case 'RIGHT':
        case 'BOTTOM':
            customValue = 1;
            break;
        default:
            customValue = 0;
            break;
    }

    return customValue;
};

export const toSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const toPercent = (v) => Math.round(toFloat(v) * 100);
export const toValue = (v) => toFloat(v) / 100;
