import React, { useEffect, useRef, useState } from 'react';
import { Input } from '@bynder/design-system';
import generateTestId from '~/helpers/testIdHelpers';

type Props = {
    id: string;
    value: number;
    onChange: (value: number) => void;
    testId: string;
};

export function OpacityInput({ value, onChange, testId, id }: Props) {
    const [displayValue, setDisplayValue] = useState(toInputValue(value));
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (document.activeElement === ref.current) {
            return;
        }

        setDisplayValue(toInputValue(value));
    }, [value]);

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.target.blur();

            return;
        }

        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();

            const sanitized = sanitizeInputValue(displayValue);

            setDisplayValue(sanitized);

            const parsed = parseInputValue(sanitized);

            if (isNaN(parsed)) {
                return;
            }

            const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
            const factor = event.shiftKey ? 10 : 1;

            const val = parsed + numberToAdd * factor;

            onChange(val);
            setDisplayValue(val.toString());
        }
    };

    return (
        <Input
            id={id}
            type="text"
            inputRef={ref}
            value={displayValue}
            onFocus={() => setDisplayValue((prev) => prev.replace('%', ''))}
            onChange={(newValue) => {
                // Remove all non-numeric characters
                const sanitized = sanitizeInputValue(newValue);

                setDisplayValue(sanitized);
            }}
            onBlur={() => {
                const parsed = parseInputValue(displayValue);
                const normalized = isNaN(parsed) ? 0 : parsed;

                setDisplayValue(toInputValue(clamp(normalized, 0, 100)));
                onChange(clamp(normalized, 0, 100));
            }}
            onKeyDown={onKeyDown}
            {...generateTestId(testId)}
        />
    );
}

function parseInputValue(value: string) {
    return parseInt(value || '0', 10);
}

function toInputValue(value: number) {
    return value.toString() + '%';
}

function sanitizeInputValue(value: string) {
    // remove leading zeros and non-numeric characters
    return value.replace(/^0*/g, '').replace(/[^0-9]/g, '');
}

function clamp(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
}
