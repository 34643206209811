import React, { type ComponentProps } from 'react';
import { Thumbnail } from '@bynder/design-system';
import { getPreview, toDataUrl } from './gradientUtils';
import { Gradient } from '../ColorPicker/types';

type Props = Pick<ComponentProps<typeof Thumbnail>, 'size' | 'shape' | 'children'> & Gradient;

export function GradientThumbnail(props: Props) {
    const svgString = getPreview(props);
    const imageUrl = toDataUrl('image/svg+xml', svgString);

    return <Thumbnail {...props} variant="content" imageUrl={imageUrl} />;
}
