import { type VisualElement, getAllElementsQuantityRecursively } from '@bynder-studio/render-core';
import type { ImageManipulationRenderer, PlaybackManipulationRenderer } from '@bynder-studio/render-web';

type Direction = 'up' | 'down';

type Props = {
    manipulationRenderer: PlaybackManipulationRenderer | ImageManipulationRenderer;
};

const useArrangeActions = ({ manipulationRenderer }: Props) => {
    const creativeModel = manipulationRenderer?.creativeModel;

    const getSibling = ({
        el,
        direction,
        selectedElements,
    }: {
        el: VisualElement;
        direction: Direction;
        selectedElements: VisualElement[];
    }) => {
        if (!creativeModel) {
            return null;
        }

        const sibling =
            direction === 'down'
                ? creativeModel.getPrevElementSibling(el.id)
                : creativeModel.getNextElementSibling(el.id);

        if (sibling && selectedElements.some((e) => e.id === sibling.id)) {
            return getSibling({ el: sibling, direction, selectedElements });
        }

        return sibling;
    };

    const swapUpDown = (direction: Direction) => {
        if (!manipulationRenderer || !creativeModel) {
            return;
        }

        const selectedElements = manipulationRenderer.getSelectedElements();
        const increaseMap = new Map();

        creativeModel.beginAccumulation();
        selectedElements.forEach((element) => {
            const sibling = getSibling({ el: element, direction, selectedElements });

            if (!sibling) {
                return;
            }

            let numberToIncrease = getAllElementsQuantityRecursively(element) + 1;

            if (direction === 'up') {
                numberToIncrease += getAllElementsQuantityRecursively(sibling);
            }

            increaseMap.set(sibling.id, (increaseMap.get(sibling.id) || 0) + numberToIncrease);
        });

        for (const [id, increase] of increaseMap) {
            // eslint-disable-next-line unicorn/prefer-query-selector
            const el = creativeModel.getElementById(id);
            const valueToAdd = direction === 'down' ? increase + 0.1 : -increase - 0.1;

            creativeModel.updateElement(id, { renderOrder: el.renderOrder + valueToAdd });
        }

        creativeModel.endAccumulation();
    };

    const moveUpDown = (direction: Direction) => {
        if (!manipulationRenderer || !creativeModel) {
            return;
        }

        const selectedElements = manipulationRenderer.getSelectedElements();

        creativeModel.beginAccumulation();

        selectedElements
            .map((element) => {
                const sibling = getSibling({ el: element, direction, selectedElements });

                if (!sibling) {
                    return null;
                }

                return {
                    id: element.id,
                    renderOrder: sibling.renderOrder,
                };
            })
            .forEach((element) => {
                if (element) {
                    creativeModel.updateElement(element.id, { renderOrder: element.renderOrder });
                }
            });

        creativeModel.endAccumulation();
    };

    const handleBringToFront = () => {
        if (!manipulationRenderer || !creativeModel) {
            return;
        }

        const elements = manipulationRenderer.creativeModel.getAllElementsRecursively();
        const selectedElements = manipulationRenderer.getSelectedElements();
        const firstElementOrder = Math.max(...elements.map((el) => el.renderOrder ?? 0));

        let topElementOrder = 0;

        creativeModel.beginAccumulation();
        selectedElements
            .sort((a, b) => (b.renderOrder ?? 0) - (a.renderOrder ?? 0))
            .forEach((selection, index) => {
                const { parent } = selection;

                // Prevent unwanted shifting of the elements inside the groups;
                // when the whole group is selected – elements inside this group are immutable
                if (parent && selectedElements.some((el) => el.id === parent.id)) {
                    return;
                }

                if (index === 0) {
                    topElementOrder = firstElementOrder + selectedElements.length + 1;
                    creativeModel.updateElement(selection.id, { renderOrder: topElementOrder });
                } else {
                    creativeModel.updateElement(selection.id, { renderOrder: topElementOrder - index });
                }
            });
        creativeModel.endAccumulation();
    };

    const handleSendToBack = () => {
        if (!manipulationRenderer || !creativeModel) {
            return;
        }

        const selectedElements = manipulationRenderer.getSelectedElements();
        const topElementOrder = 0.9;

        creativeModel.beginAccumulation();
        selectedElements
            .sort((a, b) => (b.renderOrder ?? 0) - (a.renderOrder ?? 0))
            .forEach((selection, index) => {
                const { parent } = selection;

                // Prevent unwanted shifting of the elements inside the groups;
                // when the whole group is selected – elements inside this group are immutable
                if (parent && selectedElements.some((el) => el.id === parent.id)) {
                    return;
                }

                if (index === 0) {
                    creativeModel.updateElement(selection.id, { renderOrder: topElementOrder });
                } else {
                    creativeModel.updateElement(selection.id, {
                        renderOrder: topElementOrder + index / selectedElements.length,
                    });
                }
            });
        creativeModel.endAccumulation();
    };

    return {
        swapUpDown,
        moveUpDown,
        handleBringToFront,
        handleSendToBack,
    };
};

export default useArrangeActions;
