import type { ColorStop, Gradient, LinearGradient } from '../../types';

type UpdateSource = 'position' | 'saturation' | 'hue';

type State = {
    gradient: Gradient;
    selected: number;
    latestHue?: number;
    lastUpdate?: number;
    lastUpdateSource?: UpdateSource;
};

export type Action =
    | {
          type: 'add-stop';
          payload: ColorStop['position'];
      }
    | {
          type: 'select-stop';
          payload: number;
      }
    | {
          type: 'update-selected-stop';
          payload: Partial<ColorStop> & { lastUpdateSource: UpdateSource; latestHue?: number };
      }
    | {
          type: 'remove-selected-stop';
      }
    | {
          type: 'change-angle';
          payload: LinearGradient['angle'];
      }
    | {
          type: 'change-gradient';
          payload: Gradient;
      };

export function reducer(state: State, action: Action): State {
    if (action.type === 'add-stop') {
        const newStop = getNewColorStop(action.payload, state.gradient.stops);

        return {
            ...state,
            selected: state.gradient.stops.length,
            gradient: {
                ...state.gradient,
                stops: [...state.gradient.stops, newStop],
            },
            lastUpdate: Date.now(),
        };
    }

    if (action.type === 'select-stop') {
        return {
            ...state,
            selected: action.payload,
        };
    }

    if (action.type === 'remove-selected-stop') {
        if (state.gradient.stops.length === 1) {
            // There should be at least one stop
            return state;
        }

        const stops = state.gradient.stops.filter((_, i) => i !== state.selected);
        const newSelectedStop = getClosestStop(stops, state.gradient.stops[state.selected].position);
        const selected = stops.indexOf(newSelectedStop);

        return {
            ...state,
            selected,
            gradient: {
                ...state.gradient,
                stops,
            },
            lastUpdate: Date.now(),
        };
    }

    if (action.type === 'update-selected-stop') {
        // fix flickering when updating the same stop from different sources
        const isUpdateSourceChanged = state.lastUpdateSource !== action.payload.lastUpdateSource;
        const isUpdateAllowed = !isUpdateSourceChanged || Date.now() - (state.lastUpdate || 0) > 10;

        if (!isUpdateAllowed) {
            return state;
        }

        const { latestHue, ...stopData } = action.payload;

        const newStops = [...state.gradient.stops];
        const stop = newStops[state.selected];
        const newStop = { ...stop, ...stopData };

        newStops[state.selected] = newStop;

        return {
            ...state,
            gradient: {
                ...state.gradient,
                stops: newStops,
            },
            lastUpdate: Date.now(),
            latestHue: action.payload.latestHue ?? state.latestHue,
            lastUpdateSource: action.payload.lastUpdateSource,
        };
    }

    if (action.type === 'change-angle') {
        if (state.gradient.type !== 'linear') {
            return state;
        }

        return {
            ...state,
            gradient: {
                ...state.gradient,
                angle: action.payload,
            },
            lastUpdate: Date.now(),
        };
    }

    if (action.type === 'change-gradient') {
        const isSelectionOutOfBounds = action.payload.stops.length <= state.selected;
        const selected = isSelectionOutOfBounds ? action.payload.stops.length - 1 : state.selected;

        return {
            ...state,
            gradient: action.payload,
            selected,
        };
    }

    throw new Error('Unknown action');
}

function getClosestStop(stops: ColorStop[], position: ColorStop['position']): ColorStop {
    const { prevStop, prevDistance, nextStop, nextDistance } = getNeighboringStops(stops, position);

    if (!prevStop) {
        if (!nextStop) {
            throw new Error('There should be at least one stop');
        }

        return nextStop;
    }

    if (!nextStop) {
        return prevStop;
    }

    const isPrevCloser = prevDistance + nextDistance <= 0;

    return isPrevCloser ? prevStop : nextStop;
}

function getNewColorStop(position: number, stops: ColorStop[]): ColorStop {
    const { prevStop, nextStop } = getNeighboringStops(stops, position);

    if (!prevStop) {
        if (!nextStop) {
            throw new Error('There should be at least one stop');
        }

        return {
            color: nextStop.color,
            position,
        };
    }

    if (!nextStop) {
        return {
            color: prevStop.color,
            position,
        };
    }

    const distance = nextStop.position - prevStop.position;
    const relativeOffset = (position - prevStop.position) / distance;

    return {
        color: mergeColors(prevStop.color, nextStop.color, relativeOffset),
        position,
    };
}

function getNeighboringStops(stops: ColorStop[], position: number) {
    let prevStop: ColorStop | undefined;
    let prevDistance = Number.NEGATIVE_INFINITY;
    let nextStop: ColorStop | undefined;
    let nextDistance = Number.POSITIVE_INFINITY;

    function getDistance(stop: ColorStop) {
        return stop.position - position;
    }

    function tryUpdatePrev(stop: ColorStop, distance: number) {
        if (!prevStop || prevDistance < distance) {
            prevStop = stop;
            prevDistance = distance;
        }
    }

    function tryUpdateNext(stop: ColorStop, distance: number) {
        if (!nextStop || nextDistance > distance) {
            nextStop = stop;
            nextDistance = distance;
        }
    }

    for (const stop of stops) {
        const distance = getDistance(stop);

        if (distance <= 0) {
            tryUpdatePrev(stop, distance);
        }

        if (distance >= 0) {
            tryUpdateNext(stop, distance);
        }
    }

    return {
        prevStop,
        prevDistance,
        nextStop,
        nextDistance,
    };
}

function mergeColors(prevColor: ColorStop['color'], nextColor: ColorStop['color'], offset: number): ColorStop['color'] {
    const prev = getHexColorComponents(prevColor);
    const next = getHexColorComponents(nextColor);

    const r = encodeHexComponent(mergeColorComponent(prev.r, next.r, offset));
    const g = encodeHexComponent(mergeColorComponent(prev.g, next.g, offset));
    const b = encodeHexComponent(mergeColorComponent(prev.b, next.b, offset));

    if (typeof prevColor !== 'string' || typeof nextColor !== 'string') {
        const opacity = mergeColorComponent(prev.a, next.a, offset);

        return {
            color: `#${r}${g}${b}`,
            opacity,
        };
    }

    return `#${r}${g}${b}`;
}

function encodeHexComponent(component: number): string {
    return component.toString(16).padStart(2, '0');
}

function getHexColorComponents(color: ColorStop['color']) {
    const colorString = typeof color === 'string' ? color : color.color;
    const a = typeof color === 'string' ? 1 : color.opacity;

    if (colorString.length === 4) {
        return {
            r: parseInt(colorString[1] + colorString[1], 16),
            g: parseInt(colorString[2] + colorString[2], 16),
            b: parseInt(colorString[3] + colorString[3], 16),
            a,
        };
    }

    if (colorString.length === 7) {
        return {
            r: parseInt(colorString[1] + colorString[2], 16),
            g: parseInt(colorString[3] + colorString[4], 16),
            b: parseInt(colorString[5] + colorString[6], 16),
            a,
        };
    }

    throw new Error('Invalid color');
}

function mergeColorComponent(start: number, end: number, offset: number) {
    if (start > end) {
        return Math.round(start - (start - end) * offset);
    }

    return Math.round(start + (end - start) * offset);
}
