import React, { createContext, useContext, useEffect, useMemo, useReducer, type JSX } from 'react';
import type { HslaColor, HslColor } from 'react-colorful';
import { equals } from 'rambda';
import debounce from '~/helpers/debounce';
import type { Gradient } from '../../types';
import { reducer, Action } from './gradientReducer';
import { solidColorToHslColor } from '../../utils/hsl';

type GradientContextValue = {
    gradient: Gradient;
    selected: number;
    dispatch: (action: Action) => void;
    currentColorValue: HslaColor | HslColor;
};

const GradientContext = createContext<GradientContextValue | null>(null);

type ProviderProps = {
    value: Gradient;
    onChange: (gradient: Gradient) => void;
    children: JSX.Element | JSX.Element[];
};

export function GradientProvider({ children, value, onChange }: ProviderProps) {
    const [state, dispatch] = useReducer(reducer, {
        gradient: value,
        selected: 0,
    });

    const onChangeDebounced = useMemo(() => debounce(onChange, 50), [onChange]);

    const currentColorStop = state.gradient.stops[state.selected];
    const currentColor =
        typeof currentColorStop.color === 'string' ? currentColorStop.color : currentColorStop.color.color;
    const currentOpacity = typeof currentColorStop.color === 'string' ? undefined : currentColorStop.color.opacity;

    const currentColorValue = useMemo(
        () => solidColorToHslColor(currentColor, currentOpacity, state.latestHue),
        [currentColor, currentOpacity, state.latestHue],
    );

    const contextValue = useMemo(() => {
        return {
            ...state,
            currentColorValue,
            dispatch,
        };
    }, [state, currentColorValue]);

    useEffect(() => {
        if (state.lastUpdate && Date.now() - state.lastUpdate < 10) {
            return;
        }

        dispatch({
            type: 'change-gradient',
            payload: value,
        });
    }, [value]);

    useEffect(() => {
        if (!equals(state.gradient, value)) {
            onChangeDebounced(state.gradient);
        }
    }, [state.gradient]);

    return <GradientContext.Provider value={contextValue}>{children}</GradientContext.Provider>;
}

export function useGradient() {
    const gradient = useContext(GradientContext);

    if (!gradient) {
        throw new Error('useGradient must be used within a GradientProvider');
    }

    return gradient;
}
