import React, { useEffect, useContext, useMemo } from 'react';
import { VariationsThumbnailsContext } from 'packages/pages/design/VariationsContext/VariationsThumbnailsProvider';
import { variationsThumbnailsStore } from 'packages/pages/design/VariationsContext/variationsThumbnailsStore';
import useExport from 'packages/variation-export/hooks/useExport';
import useVariations from 'packages/pages/design/hooks/useVariations';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { VariationPreview } from './VariationPreview';
import { VariationSetItemEntryType, VariationSetItemType } from 'packages/variation-export/types';
import { StyledFlex } from './FilmStrip.styled';

type VariationItem = VariationSetItemType & { hasOversetText: boolean };

type FilmStripProps = {
    items: VariationItem[];
    validVariations: VariationItem[];
    selectedIds: { [key: string]: boolean }[];
    onItemSelect: (id: string) => void;
};

export const FilmStrip: React.FC<FilmStripProps> = ({ items, onItemSelect, selectedIds, validVariations }) => {
    const { variationThumbnailRenderer } = useContext(VariationsThumbnailsContext);
    const { setSelectedIds } = useExport();
    const { creativeModel } = useDesign();
    const { currentVariation: selectedVariationSetId } = useVariations();

    const selectedPageIndex = creativeModel?.getCurrentPageIndex() || 0;

    const allVariations = useMemo(
        () =>
            [...items]
                .sort((a, b) => b.displayOrder - a.displayOrder)
                .reduce(
                    (acc, item, index) => {
                        const sortedVariations = item.entries.sort((a, b) => a.displayOrder - b.displayOrder);

                        sortedVariations.forEach((variation) => {
                            if (!acc[`${index}-${variation.displayOrder}`]) {
                                acc[`${index}-${variation.displayOrder}`] = [];
                            }

                            acc[`${index}-${variation.displayOrder}`].push({
                                ...variation,
                                variationSetId: item.variationSetId,
                                hasOversetText: item.hasOversetText,
                            });
                        });

                        return acc;
                    },
                    {} as Record<number, VariationSetItemEntryType[]>,
                ),
        [items],
    );

    const selectedVariationPage = useMemo(
        () =>
            items
                .find((item) => item.variationSetId === selectedVariationSetId)
                ?.entries.find((entry) => entry.displayOrder === selectedPageIndex + 1),
        [items, selectedVariationSetId, selectedPageIndex],
    );

    useEffect(() => {
        variationThumbnailRenderer.getVariationIds().forEach((variationSetId) => {
            const variation = items.find((variation) => variation.variationSetId === variationSetId);

            if (!variation) {
                return;
            }

            variation.entries.forEach((entry) => {
                const displayOrder = Number(entry.displayOrder);
                const pageIndex = displayOrder - 1;

                if (variationsThumbnailsStore.isThumbnailsReady(variationSetId, pageIndex, 300, 300)) {
                    return;
                }

                variationThumbnailRenderer.render(variationSetId, pageIndex, 300, 300);
            });
        });
    }, [variationsThumbnailsStore, variationThumbnailRenderer, items]);

    useEffect(() => {
        if (!Object.keys(selectedIds).length) {
            if (validVariations.find((variation) => variation.variationSetId === selectedVariationSetId)) {
                setSelectedIds({ [`${selectedVariationPage?.variationId}`]: true });
            } else {
                setSelectedIds([]);
            }
        }

        const selectorDiv = document.querySelector(`#v-${selectedVariationSetId}-${selectedPageIndex}`);

        if (selectorDiv) {
            selectorDiv?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
    }, [selectedVariationSetId, selectedPageIndex]);

    return (
        <StyledFlex direction="row" gap="5" wrap="nowrap">
            {Object.entries(allVariations).map(([variationKey, variationEntries]) =>
                variationEntries.map((variation) => {
                    const pageIndex = Number(variationKey.split('-')[1]) - 1;

                    return (
                        <VariationPreview
                            key={variation.variationId}
                            variation={variation}
                            isSelected={selectedIds[variation.variationId]}
                            onSelect={() => onItemSelect(variation.variationId)}
                            pageIndex={pageIndex}
                        />
                    );
                }),
            )}
        </StyledFlex>
    );
};

export default FilmStrip;
