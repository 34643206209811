export type BaseColor = {
    red: number;
    green: number;
    blue: number;
    opacity: number;
};

export type BrandColor = {
    brandColorId: number | null;
};

export type SolidColor = BaseColor &
    BrandColor & {
        type: 'SOLID';
    };

export type ColorType = 'SOLID' | 'LINEAR_GRADIENT' | 'RADIAL_GRADIENT';

export type ColorStop = BaseColor & { offset: number };

export type Gradient = BrandColor & {
    type: 'LINEAR_GRADIENT' | 'RADIAL_GRADIENT';
    angle: number;
    stops: ColorStop[];
};

export type ColorParams = SolidColor | Gradient;

export type UnifiedCommonColor = { hexCode: string; opacity: number; id?: number; name?: string };

export type ExtendedColorParams = ColorParams & { name: string };

export const defaultColor: ColorParams = {
    type: 'SOLID',
    red: 0,
    green: 0,
    blue: 0,
    opacity: 1,
    brandColorId: null,
};

export const toRGBA = (color: ColorParams | BaseColor) => {
    const c = !('type' in color) || color.type === 'SOLID' ? color : color.stops[0];

    return `rgba(${c.red}, ${c.green}, ${c.blue}, ${c.opacity})`;
};

// todo: check when implementing gradients for text
export const toRGBAWithBrandId = (color: ColorParams): string => {
    const c = color.type === 'SOLID' ? color : color.stops[0];

    return `rgba(${c.red}, ${c.green}, ${c.blue}, ${c.opacity}, ${color.brandColorId || 0})`;
};

// todo: check when implementing gradients for text
export const toRGBAWithBrandIdObj = (rgbaString: string): ColorParams => {
    const [red, green, blue, opacity, brandColorId] = rgbaString.slice('rgba('.length, -1).split(',');

    return {
        type: 'SOLID',
        red: Number(red.trim()),
        green: Number(green.trim()),
        blue: Number(blue.trim()),
        opacity: Number(opacity.trim()),
        brandColorId: brandColorId === '0' ? null : Number(brandColorId.trim()),
    };
};

export const ORDINARY_STROKE_COLOR_OBJ: ColorParams = {
    type: 'SOLID',
    red: 0,
    green: 170,
    blue: 255,
    opacity: 1,
    brandColorId: null,
};

export const DEFAULT_BG_TEXT_COLOR: ColorParams = {
    type: 'SOLID',
    red: 204,
    green: 204,
    blue: 204,
    opacity: 1,
    brandColorId: null,
};
