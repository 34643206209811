import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ElementUpdateTypes } from '@bynder-studio/render-core';

import { findElementById } from '../../helpers/elementtree';
import { useToggleExpand } from '../../helpers/groups';

import { ElementRenderer } from './element-renderer';
import { createTrackRepresentations } from './track-renderer';
import { Track } from './track/Track';
import { Element } from './element/Element';
import { getDropCommand, leftToStartFrame, TRACK_HEIGHT, widthToDuration } from './helper';

import { useResizeLine, useResizeStats } from './dragging/useResizing';
import { computeDropResults } from './dragging/dragging';
import { useSnap } from './dragging/useSnap';
import { findElementByIdForSnap } from './dragging/snapping';

import { isCtrlKey } from '~/helpers/hotKeys';
import { Timestamps } from './timestamps/Timestamps';
import { ResizeStats } from './resize-stats/ResizeStats';
import Playhead from './playhead/Playhead';
import useEditor from '../../../../hooks/useEditor';
import useForceUpdate from '../../../../hooks/useForceUpdate';
import useEditorTimeframe from '../../../../hooks/useEditorTimeframe';
import {
    TimelineOverflowOverlay,
    TimelineResizingLine,
    TimelineElementsContainer,
    TimelineSnapLine,
    TimelineElements,
    TimelineTracksList,
    TimelineTracksWrapper,
    TimelineTimestampsContainer,
    TimelineTracksContainer,
    TimelineGroupBackground,
} from './Tracks.styled';

const elementRenderer = new ElementRenderer(TRACK_HEIGHT);

function createGroupElement(element, expandedGroupIds, expandedAnimationElementsIds) {
    const expanded = !!~expandedGroupIds.indexOf(element.id);
    const children = prepareElements(element.children, expandedGroupIds, expandedAnimationElementsIds);

    return { expanded, children };
}

function prepareElements(elements, expandedGroupIds, expandedAnimationElementsIds) {
    const compareByRenderOrder = (itemA, itemB) => itemB.element.renderOrder - itemA.element.renderOrder;

    return elements
        .map((element) => {
            const animationsExpanded = !!~expandedAnimationElementsIds.indexOf(element.id);

            const item = {
                element,
                animationsExpanded,
            };

            if (element.children) {
                const { expanded, children } = createGroupElement(
                    element,
                    expandedGroupIds,
                    expandedAnimationElementsIds,
                );
                item.expanded = expanded;
                item.children = children;
            }

            return item;
        })
        .sort(compareByRenderOrder);
}

const computeResizeSnapResults = (
    elements,
    { toLeft, fromRight, elementId: snapElementId },
    elementId,
    leftPercentage,
    width,
    trackDuration,
) => {
    let startFrame = 0;
    let duration = 0;

    if (snapElementId) {
        const snapElementMetaData = findElementByIdForSnap(elements, snapElementId, trackDuration);
        const element = findElementById(elements, elementId);

        if (fromRight) {
            duration = snapElementMetaData.startFrame + !toLeft * snapElementMetaData.duration - element.startFrame;
            startFrame = element.startFrame;
        } else {
            startFrame = snapElementMetaData.startFrame + !toLeft * snapElementMetaData.duration;
            const deltaStartFrame = element.startFrame - startFrame;
            duration = element.duration + deltaStartFrame;
        }
    } else {
        duration = widthToDuration(trackDuration, width);
        startFrame = leftToStartFrame(trackDuration, leftPercentage);
    }

    return { startFrame, duration };
};

type Props = {
    elementContainerRef: React.RefObject<HTMLDivElement | null>;
    timestampsContainerRef: React.RefObject<HTMLDivElement | null>;
    elementWrapperRef: React.RefObject<HTMLDivElement | null>;
};

const Tracks = ({ elementContainerRef, timestampsContainerRef, elementWrapperRef }: Props) => {
    const { creativeModel, manipulationRenderer } = useEditor();
    const { duration, timelineDuration } = useEditorTimeframe(creativeModel);
    const forceUpdate = useForceUpdate();
    const data = useRef({ elements: [] });
    const isDragging = useRef(false);
    const isResizing = useRef(false);
    const { elements } = data.current;
    const [currentSize, setCurrentSize] = useState(0);

    const elementsUpdateListener = useCallback(() => {
        data.current.elements = creativeModel.getElements().slice();
        forceUpdate();
    }, [creativeModel, forceUpdate]);

    useEffect(() => {
        if (creativeModel) {
            data.current.elements = creativeModel.getElements();
            forceUpdate();
        }
    }, [creativeModel, forceUpdate, currentSize]);

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        setCurrentSize(creativeModel.getCurrentPageIndex());
        const setCurrentPage = (index) => {
            setCurrentSize(index);
            data.current.elements = creativeModel.getElements();
            forceUpdate();
        };
        creativeModel.on('currentPageChange', setCurrentPage);

        return () => creativeModel.off('currentPageChange', setCurrentPage);
    }, [creativeModel, forceUpdate]);

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        const unsubscribeAnimation = creativeModel.onElementsPartialUpdate(
            ElementUpdateTypes.ANIMATIONS,
            elementsUpdateListener,
        );
        const unsubscribeAnimationIn = creativeModel.onElementsPartialUpdate(
            ElementUpdateTypes.ANIMATION_IN,
            elementsUpdateListener,
        );
        const unsubscribeAnimationOut = creativeModel.onElementsPartialUpdate(
            ElementUpdateTypes.ANIMATION_OUT,
            elementsUpdateListener,
        );
        const unsubscribeContentProperty = creativeModel.onElementsPartialUpdate(
            ElementUpdateTypes.CONTENT_PROPERTY,
            elementsUpdateListener,
        );
        creativeModel.on('elementsTreeUpdated', elementsUpdateListener);

        return () => {
            unsubscribeAnimation();
            unsubscribeAnimationIn();
            unsubscribeAnimationOut();
            unsubscribeContentProperty();
            creativeModel.off('elementsTreeUpdated', elementsUpdateListener);
        };
    }, [creativeModel, elementsUpdateListener]);

    const [expandedGroupIds, toggleGroupExpand] = useToggleExpand([]);
    const [expandedAnimationElementsIds, toggleAnimation] = useToggleExpand([]);

    const handleAnimationExpand = useCallback(
        (item, e) => {
            e.stopPropagation();
            toggleAnimation(item.element.id);
        },
        [toggleAnimation],
    );
    const handleGroupExpand = useCallback((item) => toggleGroupExpand(item.element.id), [toggleGroupExpand]);

    const tracksRef = useRef(null);
    const timelineTracksContainerRef = useRef(null);

    const [resizeLineRef, onLineResize, onLineResizeEnd] = useResizeLine();
    const [resizeStatsRef, onStatsResize, onStatsResizeEnd] = useResizeStats(duration);
    const [snapLineRef, snapOnDrag, snapOnDragEnd, getSnapPoint] = useSnap(elementContainerRef);

    const items = useMemo(
        () => prepareElements(elements, expandedGroupIds, expandedAnimationElementsIds),
        [elements, expandedGroupIds, expandedAnimationElementsIds],
    );
    const tracks = useMemo(() => createTrackRepresentations(items), [items]);
    const renderAbleItems = useMemo(() => elementRenderer.createRenderAbleItems(items, duration), [items, duration]);

    const handleSelection = useCallback(
        (item, evt) => {
            evt.preventDefault();
            evt.stopPropagation();

            const isMultiSelectMode = isCtrlKey(evt);
            manipulationRenderer.selectElement(item.element.id, isMultiSelectMode);
        },
        [manipulationRenderer],
    );

    const handleResizeEnd = useCallback(
        (item, elementRef, leftPercentage, width, renderOrder) => {
            if (!isResizing.current) {
                return;
            }

            isResizing.current = false;
            const { element } = item;
            snapOnDragEnd();
            onLineResizeEnd();
            onStatsResizeEnd();
            const { duration: elemDuration, startFrame } = computeResizeSnapResults(
                elements,
                getSnapPoint(),
                element.id,
                leftPercentage,
                width,
                duration,
            );

            const elementLeft = `${(startFrame / duration) * 100}%`;
            const elementWidth = `${(elemDuration / duration) * 100}%`;

            elementRef.current.style.left = elementLeft;
            elementRef.current.style.width = elementWidth;

            creativeModel.updateElement(element.id, {
                duration: elemDuration,
                startFrame,
                renderOrder,
            });
        },
        [elements, snapOnDragEnd, onLineResizeEnd, onStatsResizeEnd, getSnapPoint, creativeModel, duration],
    );

    const handleDrag = useCallback(
        (item, left, boundingBox) => {
            isDragging.current = true;
            const { element } = item;
            snapOnDrag(element.id, element.parent?.id, null, boundingBox, left);
        },
        [snapOnDrag],
    );

    const handleDrop = useCallback(
        (item, top, leftPercentage, dropParams, isBetweenTracks) => {
            let elementId = item.element.id;

            if (!isDragging.current === true) {
                manipulationRenderer.selectElement(elementId);

                return;
            }

            isDragging.current = false;

            snapOnDragEnd();
            const { groupId, onElement, onGroup } = dropParams;
            const snapPoint = getSnapPoint();
            const [renderOrder, startFrame] = computeDropResults(
                elements,
                renderAbleItems,
                duration,
                elementId,
                top,
                leftPercentage,
                groupId,
                isBetweenTracks,
                snapPoint,
            );

            const command = getDropCommand(dropParams);

            switch (command) {
                case 'createGroup': {
                    creativeModel.beginAccumulation();
                    creativeModel.updateElement(elementId, { renderOrder: renderOrder + 0.5, startFrame });
                    creativeModel.updateElement(Number(onElement), { renderOrder: renderOrder - 0.5 });
                    const newGroupEl = creativeModel.wrapElementsIntoGroup([Number(onElement), elementId]);
                    creativeModel.endAccumulation();
                    manipulationRenderer.selectElement(newGroupEl.id);
                    break;
                }
                case 'addToGroup': {
                    creativeModel.beginAccumulation();
                    creativeModel.updateElement(elementId, {
                        renderOrder,
                        startFrame,
                    });

                    if (elementId !== Number(groupId)) {
                        creativeModel.addExistingElementToGroup(elementId, Number(groupId));
                    }

                    creativeModel.endAccumulation();
                    manipulationRenderer.selectElement(groupId);

                    break;
                }
                case 'update':
                default: {
                    const changeParent = item.element.parent && !onGroup && item.element.parent.id !== Number(groupId);
                    manipulationRenderer.deselectElement(elementId);
                    creativeModel.beginAccumulation();

                    if (changeParent) {
                        elementId = creativeModel.unwrapElementFromGroup(elementId).id;
                    }

                    creativeModel.updateElement(elementId, {
                        startFrame,
                        renderOrder,
                    });

                    creativeModel.endAccumulation();
                    break;
                }
            }

            manipulationRenderer.selectElement(elementId, false);
        },
        [getSnapPoint, renderAbleItems, creativeModel, manipulationRenderer],
    );

    const handleCancelDragging = useCallback(() => {
        isDragging.current = false;
        snapOnDragEnd();
    }, [snapOnDragEnd]);

    const handleResize = useCallback(
        (item, fromRight, left, width, leftPercentage, widthPercentage, containerBoundingBox, boundingBox) => {
            isResizing.current = true;
            const { element } = item;
            snapOnDrag(element.id, element.parent?.id, fromRight, boundingBox, left, width);
            onLineResize(fromRight, left, width);
            onStatsResize(
                element,
                fromRight,
                left,
                width,
                leftPercentage,
                widthPercentage,
                containerBoundingBox,
                boundingBox,
            );
        },
        [snapOnDrag, onLineResize, onStatsResize],
    );

    return (
        <TimelineTracksContainer ref={timelineTracksContainerRef}>
            <TimelineTimestampsContainer>
                <Timestamps
                    duration={duration}
                    elementContainerRef={elementContainerRef}
                    timestampsContainerRef={timestampsContainerRef}
                />
            </TimelineTimestampsContainer>
            <TimelineTracksWrapper>
                <TimelineTracksList ref={tracksRef}>
                    {tracks.map((track, idx) => (
                        <Track key={idx} track={track} />
                    ))}
                </TimelineTracksList>
                <TimelineElementsContainer ref={elementWrapperRef}>
                    <TimelineElements ref={elementContainerRef}>
                        {renderAbleItems
                            .filter((item) => item.background)
                            .map((item) => (
                                <TimelineGroupBackground
                                    key={item.element.id}
                                    style={{
                                        ...item.background,
                                        // 3px - space of each REAL element from the top
                                        top: `calc(${item.background.top} + 3px)`,
                                    }}
                                    data-group-id={item.element.id}
                                />
                            ))}
                        {renderAbleItems.map((item) => (
                            <Element
                                key={item.element.id}
                                item={item}
                                containerRef={elementContainerRef}
                                tracksRef={tracksRef}
                                onClick={handleSelection}
                                onExpandClick={handleGroupExpand}
                                onAnimationExpand={handleAnimationExpand}
                                onResize={handleResize}
                                onResizeEnd={handleResizeEnd}
                                onDrag={handleDrag}
                                onDrop={handleDrop}
                                onCancelDragging={handleCancelDragging}
                                trackDuration={duration} // we pass actual duration here in case of overlapping of content to prevent issues with animations timeline
                                scrollContainerRef={timelineTracksContainerRef}
                            />
                        ))}
                        <TimelineResizingLine ref={resizeLineRef} />
                        <TimelineSnapLine ref={snapLineRef} />
                        <ResizeStats resizeStatsRef={resizeStatsRef} />
                        <TimelineOverflowOverlay />
                        <Playhead
                            containerRef={elementWrapperRef}
                            elementContainerRef={elementContainerRef}
                            duration={duration}
                            timelineDuration={timelineDuration}
                            scrollContainerRef={timelineTracksContainerRef}
                        />
                    </TimelineElements>
                </TimelineElementsContainer>
            </TimelineTracksWrapper>
        </TimelineTracksContainer>
    );
};

export default Tracks;
