import React, { useState, useRef, useEffect } from 'react';
import { Card, Buffer } from '@bynder/design-system';
import defaultImage from '../../../../../src/assets/images/placeholder-card.jpg';
import { contentIconMap, getFormattedTime } from 'packages/pages/components/card/utils';
import { TemplateCardImage, TemplateCardImageWrapper, TemplateCardPlaceholder } from './TemplateThumbnail.styled';
import { Template } from 'packages/store/templates/types';

type Props = {
    template: Template;
};

const PROCESSED_STATUSES = ['PROCESSED', 'UNKNOWN', 'NOT_FOUND', 'FAILED'];

const TemplateThumbnail = ({ template }: Props) => {
    const hasTemplateTumbnail = !!template.thumbnailImage;
    const isProcessingDone = PROCESSED_STATUSES.includes(template.thumbnailStatus);

    const { cardRef, fitting } = useCardFitting(template.thumbnailImage);

    const getCardImageUrl = () => {
        if (!isProcessingDone) {
            return defaultImage;
        }

        return template.thumbnailImage;
    };

    return (
        <Card.Frame
            height={hasTemplateTumbnail ? 'fluid' : 'fixed'}
            icon={contentIconMap[template.creativeType]}
            badge={getFormattedTime(template)}
            isCheckeredBackground={template.containsTransparency}
            progressBar={!isProcessingDone && <Buffer noCornerRadius />}
        >
            {hasTemplateTumbnail ? (
                <TemplateCardImageWrapper ref={cardRef}>
                    <Card.Images>
                        <TemplateCardImage
                            src={getCardImageUrl()}
                            fitting={fitting}
                            onError={(e) => (e.target.src = defaultImage)}
                        />
                    </Card.Images>
                </TemplateCardImageWrapper>
            ) : (
                <TemplateCardPlaceholder>{contentIconMap[template.creativeType]}</TemplateCardPlaceholder>
            )}
        </Card.Frame>
    );
};

export default TemplateThumbnail;

function useImageDimensions(src: string) {
    const [dimensions, setDimensions] = useState<[number, number] | undefined>(undefined);

    useEffect(() => {
        setDimensions(undefined);
        const img = new Image();

        const onLoad = () => {
            setDimensions([img.width, img.height]);
        };

        img.addEventListener('load', onLoad, { once: true });
        img.src = src;

        return () => img.removeEventListener('load', onLoad);
    }, [src]);

    return dimensions;
}

function useCardFitting(src: string) {
    const cardRef = useRef<HTMLDivElement>(null);
    const dimensions = useImageDimensions(src);

    const getCardFitting = () => {
        if (cardRef?.current && dimensions) {
            const cardRatio = cardRef?.current?.clientWidth / cardRef?.current?.clientHeight;
            const imageRatio = dimensions[0] / dimensions[1];

            return cardRatio < imageRatio ? 'cover' : 'contain';
        }

        return 'cover';
    };

    return {
        cardRef,
        fitting: getCardFitting(),
    };
}
