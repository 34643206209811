import React from 'react';
import styled from 'styled-components';
import { Button, Form, token } from '@bynder/design-system';
import { IconDelete } from '@bynder/icons';
import { useGradient } from './GradientContext';
import { NumberInputInlineSuffix } from '../NumberInputInlineSuffix';
import { GradientThumbnail } from 'packages/ds/GradientThumbnail/GradientThumbnail';

const RowStyled = styled(Form.Row)`
    grid-template-columns: repeat(auto-fit, minmax(10px, auto));
    gap: ${token.spacing3};
`;

export function GradientOptions() {
    return (
        <RowStyled>
            <Thumbnail />
            <LinearGradientOptions />
            <CommonGradientOptions />
        </RowStyled>
    );
}

function Thumbnail() {
    const { gradient } = useGradient();

    return <GradientThumbnail {...gradient} />;
}

function LinearGradientOptions() {
    const { gradient, dispatch } = useGradient();

    if (gradient.type !== 'linear') {
        return null;
    }

    const angle = gradient.angle ?? 0;

    return (
        <NumberInputInlineSuffix
            value={angle}
            min={0}
            max={360}
            suffix="°"
            onChange={(value) => {
                dispatch({ type: 'change-angle', payload: value });
            }}
        />
    );
}

function CommonGradientOptions() {
    const { gradient, selected, dispatch } = useGradient();

    const selectedStop = gradient.stops[selected];

    const position = selectedStop.position;
    const hasMultipleStops = gradient.stops.length > 1;

    return (
        <>
            <NumberInputInlineSuffix
                value={position}
                min={0}
                max={100}
                suffix="%"
                onChange={(value) => {
                    dispatch({
                        type: 'update-selected-stop',
                        payload: { position: value, lastUpdateSource: 'position' },
                    });
                }}
            />

            <Button
                variant="clean"
                icon={<IconDelete />}
                aria-label="Delete stop"
                isDisabled={!hasMultipleStops}
                onClick={() => dispatch({ type: 'remove-selected-stop' })}
            />
        </>
    );
}
