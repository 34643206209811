import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { equals } from 'rambda';
import { type BackgroundColorParams, type ColorParams, ShapeElement, TextElement } from '@bynder-studio/render-core';
import type { ColorType } from '@bynder-studio/misc';
import { useTranslate } from '@bynder/localization';
import { BrandColor } from 'packages/store/brandColors/types';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import { brandColor2ColorParams, color2ColorParams, colorParams2Color } from '~/common/editor/editorHelper';
import { EditorColorContainer, SidebarSection } from './EditorColor.styled';
import ColorSearch from './components/ColorSearch';
import ColorPicker from '../ColorPicker';
import { getExistingBrandColor } from './utils';
import ColorDropdown from './components/ColorsDropdown';
import ColorDetach from './components/ColorDetachDropdown';
import { Color } from 'packages/ds/ColorPicker/types';

export type BrandColorOnElement = BrandColor & { paletteName: string };

type Props = {
    disabled: boolean;
    color: ColorParams;
    colorTypes?: ColorType[];
    onChange: (name: string, value: ColorParams) => void;
    name?: string;
    property: string;
    testId: string;
    selectedElement?: any;
    configureColorsButton?: JSX.Element | null;
    elementColors?: ColorParams[] | [];
    selectedCustomColors?: ColorParams[] | [];
    onAppliedClick?: (color: BrandColorOnElement | ColorParams) => void;
    isGradientDisabled?: boolean;
};

const parsePropertyName = (element: TextElement | ShapeElement | BackgroundColorParams, propertyName: string) =>
    propertyName.split('.').reduce((accum, current) => accum[current], element);

export const EditorColor = ({
    color,
    onChange,
    disabled,
    selectedElement,
    name,
    property,
    testId,
    configureColorsButton = null,
    elementColors = [],
    selectedCustomColors = [],
    onAppliedClick = () => {},
    colorTypes = ['SOLID', 'LINEAR_GRADIENT', 'RADIAL_GRADIENT'],
    isGradientDisabled,
}: Props) => {
    const { translate } = useTranslate();
    const { palettes: rawPalettes } = useSelector(colorPalettes);

    const palettes = useMemo(() => {
        return rawPalettes
            .map((palette) => {
                const filteredColors = palette.brandColors.filter((c) => colorTypes?.includes(c.type));
                return {
                    ...palette,
                    brandColors: filteredColors,
                };
            })
            .filter((palette) => !!palette.brandColors.length);
    }, [rawPalettes, colorTypes]);

    const selectedColorProperty =
        name === 'dropShadow' ? selectedElement[name]?.color : parsePropertyName(selectedElement, property);

    const [selectedBrandColor, setSelectedBrandColor] = useState<BrandColorOnElement | null>(
        getExistingBrandColor(palettes, selectedColorProperty?.brandColorId) || null,
    );

    const emitChange = (value: ColorParams) => {
        if (equals(color, value)) {
            return;
        }

        onChange(property, value);
    };

    const setColor = (newValue: ColorParams) => {
        emitChange({ ...newValue, brandColorId: null });
    };

    const setBrandColor = (brandColor: BrandColor) => {
        const { id } = brandColor;
        const pickedBrandColor = getExistingBrandColor(palettes, id);

        if (pickedBrandColor) {
            setSelectedBrandColor(pickedBrandColor);
        }

        onChange(property, brandColor2ColorParams(brandColor));
    };

    const onColorDetach = () => {
        if (selectedBrandColor) {
            setSelectedBrandColor(null);
            const value = brandColor2ColorParams(selectedBrandColor);

            emitChange({ ...value, brandColorId: null });
        }
    };

    const colorPickerValue = (): Color => {
        const colorParams = selectedBrandColor ? brandColor2ColorParams(selectedBrandColor) : color;

        return colorParams2Color(colorParams);
    };

    const selectedBrandColors = selectedBrandColor ? [selectedBrandColor] : [];

    useEffect(() => {
        if (!selectedColorProperty) {
            return;
        }

        if (!selectedColorProperty?.brandColorId) {
            setSelectedBrandColor(null);
        } else {
            const brandColor = getExistingBrandColor(palettes, selectedColorProperty?.brandColorId);
            setSelectedBrandColor(brandColor);
        }
    }, [selectedColorProperty]);

    return (
        <EditorColorContainer justifyContent="space-between" alignItems="center">
            {!selectedBrandColor?.id ? (
                <SidebarSection>
                    <ColorPicker
                        id={selectedElement?.id}
                        testId={testId}
                        handleOnChange={(newValue: Color) => setColor(color2ColorParams(newValue))}
                        value={colorPickerValue()}
                        disabled={selectedElement?.locked || disabled}
                        label={translate('editor.sidebar.shots.colorpicker.label')}
                        isGradientDisabled={isGradientDisabled}
                    />
                    <ColorDropdown
                        testId={testId}
                        colors={[color]}
                        palettes={palettes}
                        selectedBrandColors={selectedBrandColors}
                        disabled={selectedElement?.locked || disabled}
                    >
                        <ColorSearch
                            onAppliedClick={onAppliedClick}
                            elementColors={elementColors}
                            configureColorsButton={configureColorsButton}
                            selectedBrandColors={selectedBrandColors}
                            selectedCustomColors={selectedCustomColors}
                            setBrandColor={setBrandColor}
                            palettes={palettes}
                            testId={testId}
                        />
                    </ColorDropdown>
                </SidebarSection>
            ) : (
                <>
                    <ColorDropdown
                        testId={testId}
                        colors={[color]}
                        palettes={palettes}
                        selectedBrandColors={selectedBrandColors}
                        disabled={selectedElement?.locked || disabled}
                    >
                        <ColorSearch
                            onAppliedClick={onAppliedClick}
                            elementColors={elementColors}
                            configureColorsButton={configureColorsButton}
                            selectedBrandColors={selectedBrandColors}
                            setBrandColor={setBrandColor}
                            palettes={palettes}
                            testId={testId}
                        />
                    </ColorDropdown>
                    <ColorDetach
                        testId={testId}
                        onColorDetach={onColorDetach}
                        disabled={selectedElement?.locked || disabled}
                    />
                </>
            )}
        </EditorColorContainer>
    );
};

export default EditorColor;
