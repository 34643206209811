import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '@bynder/localization';
import { IconDownload, IconUpload } from '@bynder/icons';
import useExport from 'packages/variation-export/hooks/useExport';
import { getUser } from 'packages/store/user/user.selectors';

export enum ExportPresetValue {
    DOWNLOAD = 'DOWNLOAD',
    BYNDER = 'BYNDER',
}

export type ExportPresets = {
    value: ExportPresetValue;
    label: string;
    icon: React.ReactElement;
    disabled: boolean;
};

const useExportPreset = () => {
    const { translate } = useTranslate();
    const { companies, currentCompany } = useSelector(getUser);
    const currentCompanyObj = useMemo(
        () => companies.find((company) => company.id === currentCompany),
        [companies, currentCompany],
    );
    const { isWaitingRoomBlocked, isDownloadBlocked } = useExport();

    const isBynder = currentCompanyObj.type === 'BYNDER';
    const presets = isBynder
        ? ([
              {
                  value: 'DOWNLOAD',
                  label: translate('modal.quick-export.preset.download'),
                  icon: <IconDownload />,
                  disabled: isDownloadBlocked,
              },
              {
                  value: 'BYNDER',
                  label: translate('modal.quick-export.preset.bynder'),
                  icon: <IconUpload />,
                  disabled: isWaitingRoomBlocked,
              },
          ] as ExportPresets[])
        : ([
              {
                  value: 'DOWNLOAD',
                  label: translate('modal.quick-export.preset.download'),
                  icon: <IconDownload />,
                  disabled: isDownloadBlocked,
              },
          ] as ExportPresets[]);
    const firstNotDisabledPreset = presets.find((preset) => !preset.disabled) || presets[0];
    const [selectedPreset, setSelectedPreset] = useState<ExportPresets>(firstNotDisabledPreset);

    const deselectPreset = () => {
        setSelectedPreset(firstNotDisabledPreset);
    };

    return {
        presets,
        selectedPreset,
        setSelectedPreset,
        deselectPreset,
        bynderAccountId: currentCompanyObj.bynderAccountId,
    };
};

export default useExportPreset;
