import React from 'react';

export enum SortingFilterType {
    DATECREATED = 'date_created',
    LASTMODIFIED = 'date_updated',
    TITLE = 'name',
    UPDATED = 'updated',
    DURATION = 'duration',
    RESOLUTION = 'resolution',
    EXTENSION = 'type',
}

export enum OrderFilterType {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export type OrderingFilterProps = {
    sortBy?: SortingFilterType;
    sortOrder?: OrderFilterType;
    isDisabled?: boolean;
    isCreativeType?: boolean;
    isUploadType?: boolean;
    sortTitleMap?: { [key in string]: React.ReactNode };
    onSort: (sortBy: SortingFilterType, sortOrder: OrderFilterType) => void;
};

export type OrderButtonProps = { order: OrderFilterType };
