import React, { useEffect, useRef, useState } from 'react';
import { Input } from '@bynder/design-system';

interface Props {
    value: number;
    min: number;
    max: number;
    suffix: string;
    onChange: (value: number) => void;
}

export function NumberInputInlineSuffix({ value, min, max, suffix, onChange }: Props) {
    const [displayValue, setDisplayValue] = useState(toInputValue(value, suffix));
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (document.activeElement === ref.current) {
            return;
        }

        setDisplayValue(toInputValue(value, suffix));
    }, [value, suffix]);

    return (
        <Input
            type="text"
            inputRef={ref}
            value={displayValue}
            onFocus={() => setDisplayValue((prev) => prev.replace(suffix, ''))}
            onChange={(newValue) => {
                // Remove all non-numeric characters
                const sanitized = sanitizeInputValue(newValue);

                setDisplayValue(sanitized);

                const parsed = parseInputValue(sanitized);

                if (isNaN(parsed)) {
                    return;
                }

                onChange(clamp(parsed, min, max));
            }}
            onBlur={() => {
                const parsed = parseInputValue(displayValue);
                const normalized = isNaN(parsed) ? 0 : parsed;

                setDisplayValue(toInputValue(clamp(normalized, min, max), suffix));
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.blur();
                }
            }}
            aria-label="colorpicker.color-stop-position"
        />
    );
}

function parseInputValue(deg: string) {
    return parseInt(deg || '0', 10);
}

function toInputValue(value: number, suffix: string) {
    return Math.floor(value).toString() + suffix;
}

function sanitizeInputValue(value: string) {
    // remove leading zeros and non-numeric characters
    return value.replace(/^0*/g, '').replace(/[^0-9]/g, '');
}

function clamp(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
}
