import React from 'react';
import { Table, Thumbnail } from '@bynder/design-system';
import { Template } from 'packages/store/templates/types';
import { IdentityRole } from 'packages/store/sharing/types';
import { Box } from './TitleCell.styled';
import { contentIconMap, getCreativeSharingIcon } from '../../card/utils';

const PROCESSED_STATUSES = ['PROCESSED', 'NOT_FOUND', 'FAILED'];

const TemplateTitleCell = ({
    template,
    isCategoryDetails,
    includeSharedIndicator,
}: {
    template: Template;
    isCategoryDetails: boolean;
    includeSharedIndicator?: boolean;
}) => {
    const isProcessingDone = PROCESSED_STATUSES.includes(template.thumbnailStatus);

    const imageUrl = () => {
        if (template.thumbnailImage) {
            return template.thumbnailImage;
        }

        return '';
    };

    return (
        <Table.Cell
            thumbnail={
                !isProcessingDone ? (
                    <Thumbnail variant="clean" icon={contentIconMap[template.creativeType]}>
                        <Thumbnail.Overlay isLoading />
                    </Thumbnail>
                ) : (
                    <>
                        {imageUrl() ? (
                            <Thumbnail variant="content" imageUrl={imageUrl()} />
                        ) : (
                            <Thumbnail variant="clean" icon={contentIconMap[template.creativeType]} />
                        )}
                    </>
                )
            }
        >
            <Box style={{ position: 'relative' }}>
                <div>{template.name}</div>
                <div>
                    {includeSharedIndicator &&
                        getCreativeSharingIcon(template, isCategoryDetails, IdentityRole.CAN_USE)}
                </div>
            </Box>
        </Table.Cell>
    );
};

export default TemplateTitleCell;
