import React from 'react';
import { Buffer, Card } from '@bynder/design-system';
import {
    contentIconMap,
    getFormattedTime,
    NO_PENDING_TYPES,
    NO_PREVIEW_TYPES,
} from 'packages/pages/components/card/utils';
import { formatBytes } from 'packages/pages/utils';
import { millisecondToFrames } from '~/common/editor/editorHelper';
import {
    AssetCardPlaceholder,
    AssetCardImage,
} from 'packages/pages/components/AssetsPickerModal/components/AssetsGridCard/index.styled';

type AssetsGridCardProps = {
    asset: any;
    onSelect: (asset: any) => void;
    onClick: (asset: any) => void;
    isSelected: boolean;
};

const AssetsGridCard = ({ asset, onSelect, onClick, isSelected }: AssetsGridCardProps) => {
    const hasPreview = !NO_PREVIEW_TYPES.includes(asset.type);
    const canHaveProgress = !NO_PENDING_TYPES.includes(asset.type);

    const getPreviewThumbnail = () => {
        if (hasPreview && asset.thumbnails && asset.thumbnails['320x320']) return asset.thumbnails['320x320'];
        return '';
    };

    const getSubText = () =>
        hasPreview && asset.width && asset.height
            ? `${asset.width} × ${asset.height} px ${'  •  '} ${formatBytes(asset.fileSize)}`
            : `${formatBytes(asset.fileSize)}`;

    const getIcon = () => contentIconMap[asset.type];

    const isUploadDone =
        asset.status === 'PROCESSED' ||
        asset.status === 'COMPLETED' ||
        asset.status === 'FAILED' ||
        asset.status === 'CANCELLED';

    return (
        <Card
            variant="clean"
            selectButtonAriaLabel="Select asset"
            isSelected={isSelected}
            onSelect={isUploadDone ? onSelect : undefined}
            onClick={onClick}
        >
            <Card.Frame
                height="fluid"
                icon={getIcon()}
                progressBar={canHaveProgress && !isUploadDone && <Buffer noCornerRadius />}
                badge={
                    asset.duration
                        ? getFormattedTime({
                              duration: millisecondToFrames(asset.duration, asset.frameRate),
                              frameRate: asset.frameRate,
                          })
                        : undefined
                }
                isCheckeredBackground
            >
                {getPreviewThumbnail() ? (
                    <Card.Images>
                        <AssetCardImage src={getPreviewThumbnail()} />
                    </Card.Images>
                ) : (
                    <AssetCardPlaceholder>{getIcon()}</AssetCardPlaceholder>
                )}
            </Card.Frame>
            <Card.Header isNotTruncated title={asset.fileName} detail={getSubText()} />
        </Card>
    );
};

export default AssetsGridCard;
