import React from 'react';
import { useTranslate } from '@bynder/localization';
import TemplateCardBase, { type TemplateCardBaseProps } from './TemplateCardBase';
import { Template } from 'packages/store/templates/types';
import { PreviewActionButton } from './PreviewActionButton';

export type GalleryTemplateCardProps = Omit<
    TemplateCardBaseProps,
    'testId' | 'selectButtonAriaLabel' | 'contextAction' | 'headerIcon'
> & {
    showPreview: (template: Template) => void;
};

export const GalleryTemplateCard = ({
    template,
    isSelected,
    onSelect,
    onClick,
    showPreview,
}: GalleryTemplateCardProps) => {
    const { translate } = useTranslate();
    const hasTemplateThumbnail = !!template.thumbnailImage;

    return (
        <TemplateCardBase
            template={template}
            isSelected={isSelected}
            onSelect={onSelect}
            onClick={onClick}
            selectButtonAriaLabel={translate('modal.design.create.design.gallery.label', { name: template.name })}
            testId="gallery card"
            contextAction={
                <PreviewActionButton template={template} onClick={showPreview} isDisabled={!hasTemplateThumbnail} />
            }
        />
    );
};
