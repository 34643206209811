import { hexFromPercentage } from './hexFromPercentage';

const shortFormatHexRegex = /^#([a-f\d])([a-f\d])([a-f\d])$/i;
// longFormatHexReplacement is used to replace a match made by the regular expression.
// Converts a short-format hexadecimal color code(such as #0af) into a long-format hexadecimal color code(such as #00aaff).
const longFormatHexReplacement = '#$1$1$2$2$3$3';

export function makeHexWithOpacity({ color, opacity }: { color: string; opacity: number }): string {
    let value = color;

    // Check if the hex value is in short-format
    if (shortFormatHexRegex.test(color)) {
        // Convert the short-format hex value to its long-format equivalent
        value = value.replace(shortFormatHexRegex, longFormatHexReplacement);
    }

    return value + hexFromPercentage(opacity.toString());
}
