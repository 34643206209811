import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Checkbox, Dropdown, Flex, Form, InlineBanner, ModalBase } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { VariationsContext } from 'packages/pages/design/VariationsContext/VariationsContext';
import useExport from 'packages/variation-export/hooks/useExport';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { catchResponseError } from 'packages/helpers/helpers';
import useAccessRights from 'packages/hooks/useAccessRights';
import { sendAmplitudeExportEvent } from '~/store/amplitude/actions';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import CreativesService from '~/services/CreativesService';
import features from '~/configs/features';
import useRenditionProfiles from './useRenditionProfiles';
import useExportPreset, { ExportPresets, ExportPresetValue } from './useExportPreset';
import FilmStrip from './FilmStrip';
import { StyledInlineBanner } from './variationQuickExport.styled';

type ExportPayload = {
    name: ExportPresets['label'];
    type: ExportPresets['value'];
    variationIds: string[];
    renditionConfigurationId?: number;
    bynderAccountId?: number;
};

const QuickExportModal = ({ isOpen, onClose, onSwitchToAdvancedSelection }) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const hasMultiVariationFeature = isPlatformAllowed([features.MULTI_VARIATION]);

    const [isExporting, setIsExporting] = useState(false);

    const { creativeId } = useDesign();
    const { selectedIds, items, deselectAll, setSelectedIds } = useExport();
    const { getVariations } = useContext(VariationsContext);
    const { presets, selectedPreset, setSelectedPreset, deselectPreset, bynderAccountId } = useExportPreset();
    const { renditionProfiles, selectedProfile, isLoadingRenditions, setSelectedProfile, deselectProfile } =
        useRenditionProfiles();

    const hasErrors = useMemo(
        () =>
            items.some(
                (item) =>
                    item.hasOversetText ||
                    item.entries.some((variation) => variation.hasDeletedAsset || variation.failureReason),
            ),
        [items],
    );

    const handleOnCloseInModal = () => {
        deselectAll();
        deselectPreset();
        deselectProfile();
        onClose();
    };

    const onPageVariationSelect = (variationId) => {
        if (variationId in selectedIds) {
            setSelectedIds(Object.fromEntries(Object.entries(selectedIds).filter(([key, _]) => key !== variationId)));
        } else {
            setSelectedIds({ ...selectedIds, [variationId]: true });
        }
    };

    const variationIds = useMemo(() => Object.keys(selectedIds), [selectedIds]);
    const total = items.reduce((acc, item) => acc + item.entries.length, 0);
    const validItems = useMemo(
        () =>
            items.filter(
                (item) =>
                    !item.hasOversetText &&
                    item.entries.some((variation) => !variation.hasDeletedAsset && !variation.failureReason),
            ),
        [items],
    );
    const totalValidItems = validItems.reduce((acc, item) => acc + item.entries.length, 0);
    const selectedCount = variationIds.length;

    const selectAll = () =>
        setSelectedIds(
            Object.fromEntries(validItems.flatMap((item) => item.entries).map((entry) => [entry.variationId, true])),
        );
    const getDownloadButtonTitle = () => {
        if (selectedPreset.value === ExportPresetValue.DOWNLOAD) {
            return selectedCount > 0
                ? translate('quick-export.modal.download', { count: selectedCount })
                : translate('quick-export.modal.download.zero');
        }

        return translate('quick-export.modal.waiting-room');
    };

    const handleExportGeneration = () => {
        let payload: ExportPayload = {
            name: selectedPreset.label,
            type: selectedPreset.value,
            variationIds,
        };

        if (selectedPreset.value === ExportPresetValue.BYNDER) {
            payload = {
                ...payload,
                renditionConfigurationId: selectedProfile.id,
                bynderAccountId,
            };
        } else if (selectedPreset.value === ExportPresetValue.DOWNLOAD) {
            payload = {
                ...payload,
                renditionConfigurationId: selectedProfile.id,
            };
        }

        setIsExporting(true);

        const getAmplitudeData = () => {
            let variationAmount = 0;
            const variationPages = {};
            items.forEach((item) =>
                item.entries.forEach((entry) => {
                    if (selectedIds[entry.variationId]) {
                        const pageKey = `${entry.sizeWidth}x${entry.sizeHeight}`;
                        variationPages[pageKey] = true;
                        variationAmount++;
                    }
                }),
            );

            return { variationAmount, variationPages: Object.keys(variationPages) };
        };

        const amplitudeData = {
            exportChannel: payload.type,
            exportFormat: selectedProfile.displayName,
            ...getAmplitudeData(),
        };

        dispatch(
            sendAmplitudeExportEvent({
                eventType: AMPLITUDE_TYPES.NEW_EXPORT,
                additionalProps: amplitudeData,
            }),
        );
        CreativesService.createExportJob(creativeId, payload)
            .then(({ status }) => {
                if (status === 200) {
                    sendAppcuesEvent('Export job created', {
                        variationIds: payload.variationIds,
                        name: payload.name,
                        type: payload.type,
                    });
                }
            })
            .catch(catchResponseError)
            .finally(() => {
                setIsExporting(false);
                handleOnCloseInModal();
            });
    };

    useEffect(() => {
        if (!hasMultiVariationFeature && isOpen) {
            selectAll();
        }
    }, [isOpen]);

    useEffect(() => {
        // after any changes made variationSetId updates
        // and we need to get a fresh copy of a data
        if (isOpen) {
            getVariations();
        }
    }, [isOpen]);

    return (
        <ModalBase isOpen={isOpen} onClose={handleOnCloseInModal} size="small">
            <ModalBase.Header title={translate('quick-export.modal.title')} />
            <ModalBase.Content>
                <Form>
                    {hasMultiVariationFeature && (
                        <>
                            {hasErrors && (
                                <StyledInlineBanner variant="error" withMargin={!selectedCount}>
                                    {translate('modal.quick-export.selection-error-banner')}
                                </StyledInlineBanner>
                            )}
                            {!selectedCount && (
                                <InlineBanner variant="info">
                                    {translate('modal.quick-export.selection-banner')}
                                </InlineBanner>
                            )}
                            <Form.Row>
                                <Form.Group>
                                    <Flex alignItems="center">
                                        <Checkbox
                                            isChecked={selectedCount === totalValidItems}
                                            onChange={selectedCount === totalValidItems ? deselectAll : selectAll}
                                            isIndeterminate={selectedCount !== totalValidItems && selectedCount > 0}
                                        >
                                            {translate('modal.quick-export.select-all', {
                                                count: selectedCount,
                                                total,
                                            })}
                                        </Checkbox>
                                    </Flex>
                                </Form.Group>
                                <Form.Group>
                                    <Flex justifyContent="flex-end">
                                        <Button variant="clean" onClick={onSwitchToAdvancedSelection}>
                                            {translate('modal.quick-export.change-selection')}
                                        </Button>
                                    </Flex>
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <FilmStrip
                                    items={items}
                                    validVariations={validItems}
                                    selectedIds={selectedIds}
                                    onItemSelect={onPageVariationSelect}
                                />
                            </Form.Group>
                        </>
                    )}
                    <Form.Group>
                        <Form.Label>{translate('modal.quick-export.preset')}</Form.Label>
                        <Dropdown
                            trigger={({ isOpen, ...triggerProps }) => (
                                <Button
                                    isPressed={isOpen}
                                    isDisabled={isExporting}
                                    icon={selectedPreset.icon}
                                    rightIcon={<Dropdown.Arrow />}
                                    {...triggerProps}
                                    isFullWidth
                                >
                                    <Dropdown.SelectButtonText>
                                        {isLoadingRenditions
                                            ? translate('modal.quick-export.loading')
                                            : selectedPreset?.label}
                                    </Dropdown.SelectButtonText>
                                </Button>
                            )}
                            position="bottom"
                        >
                            {presets.map((preset) => (
                                <Dropdown.Item
                                    key={preset.value}
                                    onClick={() => setSelectedPreset(preset)}
                                    isChecked={preset.value === selectedPreset.value}
                                    isDisabled={preset.disabled}
                                    icon={preset.icon}
                                >
                                    {preset.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{translate('modal.quick-export.format')}</Form.Label>
                        <Dropdown
                            trigger={({ isOpen, ...triggerProps }) => (
                                <Button
                                    isPressed={isOpen}
                                    isDisabled={isLoadingRenditions}
                                    rightIcon={<Dropdown.Arrow />}
                                    {...triggerProps}
                                    isFullWidth
                                >
                                    <Dropdown.SelectButtonText>
                                        {isLoadingRenditions
                                            ? translate('modal.quick-export.loading')
                                            : selectedProfile?.displayName}
                                    </Dropdown.SelectButtonText>
                                </Button>
                            )}
                            position="bottom"
                        >
                            {renditionProfiles.map((profile) => (
                                <Dropdown.Item
                                    key={profile.id}
                                    onClick={() => setSelectedProfile(profile)}
                                    isChecked={profile.id === selectedProfile?.id}
                                >
                                    {profile.displayName}
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                    </Form.Group>
                </Form>
            </ModalBase.Content>
            <ModalBase.Footer
                actionPrimary={
                    <Button
                        variant="primary"
                        onClick={handleExportGeneration}
                        isLoading={isExporting}
                        isDisabled={!variationIds.length}
                        title={getDownloadButtonTitle()}
                    >
                        {getDownloadButtonTitle()}
                    </Button>
                }
                actionSecondary={
                    <Button
                        variant="secondary"
                        onClick={handleOnCloseInModal}
                        title={translate('quick-export.modal.cancel')}
                    >
                        {translate('quick-export.modal.cancel')}
                    </Button>
                }
            />
        </ModalBase>
    );
};

export default QuickExportModal;
