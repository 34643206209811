import React, { memo, useCallback } from 'react';
import { VerticalNav } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import TabIcon from '../components/TabIcon';
import TabTitle from '../components/TabTitle';
import { ExportTypes, Tabs } from '../enums';
import { ExportModalTabLabel, ExportModalTabWrapper } from './ExportModal.styled';
import { ExportModalTabConfig, TabVal } from './ExportModal.types';
import MaybeTooltip from 'packages/common/MaybeTooltip';

type ExportModalTabProps = {
    value: TabVal;
    selected?: boolean;
    onSelect: (tab: TabVal) => void;
} & ExportModalTabConfig;

type ExportModalSidebarProps = {
    selectedTab: TabVal;
    setSelectedTab: ExportModalTabProps['onSelect'];
    visibleTabs: Record<(typeof ExportTypes)[keyof typeof ExportTypes], boolean>;
    disabledTabs: Record<(typeof Tabs)[keyof typeof Tabs], boolean>;
};

const isTabVisible = (tabs: ExportModalSidebarProps['visibleTabs'], tab: TabVal): boolean => tabs[ExportTypes[tab]];

const ExportModalTab = memo(
    ({ value, onSelect, selected = false, disabled = false, visible = true }: ExportModalTabProps) => {
        const onClick = useCallback(() => onSelect(value), [value]);
        const testId = generateTestId(`export-modal-tab-${value}`);

        return visible ? (
            <VerticalNav.Item
                isDisabled={disabled}
                onClick={onClick}
                isActive={selected}
                icon={<TabIcon tab={value} />}
                {...testId}
            >
                <TabTitle tab={value} />
            </VerticalNav.Item>
        ) : null;
    },
);

function ExportModalSidebar({ setSelectedTab, selectedTab, visibleTabs, disabledTabs }: ExportModalSidebarProps) {
    const isVisible = (tab: TabVal) => isTabVisible(visibleTabs, tab);
    const testId = generateTestId('export-modal-sidebar');
    const { translate } = useTranslate();

    return (
        <VerticalNav {...testId}>
            <ExportModalTabWrapper>
                <VerticalNav.Title>{translate('design.header.export.button')}</VerticalNav.Title>
                <ExportModalTab
                    value={Tabs.Download}
                    selected={selectedTab === Tabs.Download}
                    onSelect={setSelectedTab}
                    disabled={disabledTabs[Tabs.Download]}
                    visible={isVisible(Tabs.Download)}
                />
                <MaybeTooltip
                    content={translate('modal.export.waiting-room.tooltip')}
                    hasTooltip={disabledTabs[Tabs.WaitingRoom]}
                >
                    <ExportModalTab
                        value={Tabs.WaitingRoom}
                        selected={selectedTab === Tabs.WaitingRoom}
                        onSelect={setSelectedTab}
                        disabled={disabledTabs[Tabs.WaitingRoom]}
                        visible={isVisible(Tabs.WaitingRoom)}
                    />
                </MaybeTooltip>
            </ExportModalTabWrapper>

            <ExportModalTabWrapper>
                <ExportModalTabLabel>{translate('modal.export.activity-log')}</ExportModalTabLabel>
                <ExportModalTab
                    value={Tabs.AllExports}
                    selected={selectedTab === Tabs.AllExports}
                    onSelect={setSelectedTab}
                    disabled={disabledTabs[Tabs.AllExports]}
                    visible={isVisible(Tabs.AllExports)}
                />
            </ExportModalTabWrapper>
        </VerticalNav>
    );
}

export default ExportModalSidebar;
