const filtersStorage = () => {
    const syncForUser = (userId: string) => {
        try {
            const storedUserId = sessionStorage.getItem('userId');

            if (!storedUserId) {
                sessionStorage.setItem('userId', userId);

                return;
            }

            if (storedUserId === userId) {
                return;
            }

            // remove all keys except "loginTracking"
            Object.keys(sessionStorage).forEach((key) => {
                if (key.startsWith('loginTracking')) {
                    return;
                }

                sessionStorage.removeItem(key);
            });
            sessionStorage.setItem('userId', userId);
        } catch (err) {
            console.error(err);
        }
    };

    const getFiltersFromStorage = (page) => {
        try {
            const stored = sessionStorage.getItem(page);
            if (!stored) {
                return undefined;
            }

            return JSON.parse(stored);
        } catch (err) {
            console.error(err);
            return undefined;
        }
    };

    const setFiltersToStorage = (page, value) => {
        try {
            sessionStorage.setItem(page, JSON.stringify(value));
        } catch (err) {
            console.error(err);
        }
    };

    const clearFiltersFromStorage = (page) => {
        try {
            sessionStorage.removeItem(page);
        } catch (err) {
            console.error(err);
        }
    };

    return {
        syncForUser,
        getFiltersFromStorage,
        setFiltersToStorage,
        clearFiltersFromStorage,
    };
};

export default filtersStorage;
