import { createSelector } from 'reselect';
import { type Asset, AssetStatus } from 'packages/types/assets';

// todo: memoize selector
export const assetsDataSelector = (state: any) => {
    return {
        fetchOptions: state.assets.fetchOptions,
        totalCount: state.assets.totalCount,
        loadingData: state.assets.loadingData,
        assets: state.assets.assets as Asset[],
    };
};

const ASSETS_PENDING_STATUSES = [
    AssetStatus.PROCESSING_IN_PROGRESS,
    AssetStatus.RETRY_PROCESSING,
    AssetStatus.PENDING_PROCESSING,
    AssetStatus.VALIDATION_IN_PROGRESS,
    AssetStatus.UPLOAD_IN_PROGRESS,
];

const getAssets = (state: any): Asset[] => state.assets.assets as Asset[];

export const getPendingAssets = createSelector(getAssets, (assets) =>
    assets.filter((asset) => ASSETS_PENDING_STATUSES.includes(asset.status)).map((asset) => asset.id),
);
