import type { ColorParams } from '@bynder-studio/misc';
import { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import { IAsset } from '../Assets/IAsset';
import { BaseProperty } from './BaseProperty';
import type { CreativeTypes } from '../../Enums/CreativeTypes';
import { type BasePropertyParams } from '../../types';

export type BackgroundColorParams = BasePropertyParams & {
    color: ColorParams;
    brandColors?: number[];
};

export const defaultBackgroundColorParams = {
    id: Date.now() - 999 ** 3,
    name: 'DEFAULT_BG_COLOR_NAME',
    locked: false,
    color: {
        type: 'SOLID',
        red: 255,
        green: 255,
        blue: 255,
        opacity: 1,
        brandColorId: null,
    } as ColorParams,
    brandColors: [],
};

export class BackgroundColor extends BaseProperty {
    color!: ColorParams;

    brandColors: number[] = [];

    constructor(params: BackgroundColorParams) {
        super();
        this.setProperties(params);
    }

    setAssetLoader(loader: IAssetsLoader): void {
        throw new Error('Method not implemented.');
    }

    constructAsset(frameRate: number): void {
        throw new Error('Method not implemented.');
    }

    isContainsAsset(arg0: IAsset): boolean {
        throw new Error('Method not implemented.');
    }

    getValidationRules(creativeType: CreativeTypes) {
        throw new Error('Method not implemented.');
    }

    update(params: Partial<BackgroundColorParams>): Set<ElementUpdateTypes> {
        return this.setProperties(params);
    }

    setProperties(params: Partial<BackgroundColorParams>): Set<ElementUpdateTypes> {
        const updateTypes: Set<ElementUpdateTypes> = super.setProperties(params);

        if (params.color !== undefined) {
            this.color = params.color;
        }

        if (params.brandColors !== undefined) {
            this.brandColors = params.brandColors;
        }

        return updateTypes;
    }

    getValuesByUpcomingUpdate(data: Partial<BackgroundColorParams>): Partial<BackgroundColorParams> {
        return super.getValuesByUpcomingUpdate(data);
    }

    cleanupEmittingValues(values: Record<string, any>) {
        delete values.brandColors;
        super.cleanupEmittingValues(values);
    }

    toObject(): BackgroundColorParams {
        return {
            ...super.toObject(),
            color: { ...this.color },
            brandColors: this.brandColors,
        };
    }
}
