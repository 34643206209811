import { token } from '@bynder/design-system';
import styled from 'styled-components';

export const InputContainer = styled.div<{ $hasAlphaChannel?: boolean }>`
    display: grid;
    grid-template-columns: ${(props) => (props.$hasAlphaChannel ? 'minmax(10px, 1fr) 80px' : 'minmax(0, 1fr)')};
    align-items: center;
    padding: 0 ${token.spacing5} ${token.spacing5};
    column-gap: ${token.spacing4};
`;
