import { Dimension } from './Dimension';
import { Position } from './Position';
import { Region } from './Region';

export class Box {
    position: Position;
    dimension: Dimension;
    rotation: number = 0;
    renderPosition: Position | null = null;

    constructor(position: Position, dimension: Dimension, rotation = 0, renderPosition = null) {
        this.position = position;
        this.dimension = dimension;
        this.rotation = rotation;
        this.renderPosition = renderPosition;
    }

    // rotatePoint(point: Position, centerPosition: Position, rotation: number) {
    //     const angleInRadians = (-rotation * Math.PI) / 180;
    //
    //     const newx =
    //         (point.getX() - centerPosition.getX()) * Math.cos(angleInRadians) -
    //         (point.getY() - centerPosition.getY()) * Math.sin(angleInRadians) +
    //         centerPosition.getX();
    //     const newy =
    //         (point.getX() - centerPosition.getX()) * Math.sin(angleInRadians) +
    //         (point.getY() - centerPosition.getY()) * Math.cos(angleInRadians) +
    //         centerPosition.getY();
    //
    //     return new Position(newx, newy);
    // }
    getRotatedPoint(point: Position, centerPosition?: Position, rotation?: number): Position {
        if (!centerPosition) {
            centerPosition = new Position(
                this.position.getX() + this.dimension.getWidth() / 2,
                this.position.getY() + this.dimension.getHeight() / 2,
            );
        }

        if (rotation === undefined) {
            rotation = this.rotation;
        }

        return point.rotatePoint(centerPosition, rotation);
    }

    isPointInBox(point: Position, centerPosition?: Position, rotation?: number): boolean {
        const rotatedPoint = this.getRotatedPoint(point, centerPosition, rotation);

        return (
            rotatedPoint.getX() >= this.position.getX() &&
            rotatedPoint.getX() <= this.position.getX() + this.dimension.getWidth() &&
            rotatedPoint.getY() >= this.position.getY() &&
            rotatedPoint.getY() <= this.position.getY() + this.dimension.getHeight()
        );
    }

    getCenterPosition(box: Box) {
        let b = box;

        if (!box) {
            b = this;
        }

        return new Position(
            b.position.getX() + b.dimension.getWidth() / 2,
            b.position.getY() + b.dimension.getHeight() / 2,
        );
    }

    toObject() {
        return {
            position: this.position?.toObject() ?? null,
            dimension: this.dimension?.toObject() ?? null,
            rotation: this.rotation,
            renderPosition: this.renderPosition,
        };
    }

    toRegion() {
        const x = this.position.getX();
        const y = this.position.getY();
        const width = this.dimension.getWidth();
        const height = this.dimension.getHeight();

        return new Region(x, y, width + x, height + y);
    }
}
