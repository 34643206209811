import { truthy } from '@bynder-studio/misc';
import { type IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import { ElementTypes } from '../../Enums/ElementTypes';
import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import {
    checkIsNeedUpdateElementsTreeByAccumulator,
    getElementsToSiblingsFromAccumulator,
} from '../../Helpers/accumulator';
import { DynamicEventEmitter } from '../../Helpers/DynamicEventEmitter';
import {
    compareByRenderOrder,
    compareByRenderOrderDesc,
    getAllElementsRecursively,
    getMaskElementRanges,
} from '../../Helpers/elementUtils';
import { GroupElement } from '../Elements/GroupElement';
import {
    applyCropToSiblings,
    calculateElementsRenderOrder,
    changeElementsPositionAndDimension,
    getNextElementRenderOrder,
} from '../Elements/Helpers/ElementHelpers';
import { createGroupElement } from '../Elements/Helpers/GroupElementHelpers';
import { TextElement } from '../Elements/TextElement';
import { BackgroundColor, BackgroundColorParams } from '../Properties/BackgroundColor';
import { Dimension, type DimensionObject } from '../Shared/Dimension';
import { EventAccumulator } from '../Shared/EventAccumulator';
import { type PositionObject } from '../Shared/Position';
import {
    type CreateElementOptions,
    type ElementUpdateOptions,
    Reason,
    type RemoveElementOptions,
    type UpdateElementOptions,
    type UpdateGlobalPropertyOptions,
    type VisualElement,
} from '../../types';
import { ElementUpdatedEventData, EventDataMap } from '../../event-types';
import { MaskModeTypes } from '../../Enums/MaskModeTypes';
import { Ranges } from '../../Helpers/Ranges';
import { TextStyles } from '../Shared/TextStyles';
import { isEmpty } from '../../Helpers/utils';
import { BaseProperty } from '../Properties/BaseProperty';
import { CompModel } from '../CompModels/CompModel';

export const defaultCreateElementOptions: CreateElementOptions = {
    reason: 'user',
};
export const defaultUpdateElementOptions: UpdateElementOptions = {
    walkParent: true,
    walkChildren: true,
    reason: 'user',
};
export const defaultRemoveElementOptions: RemoveElementOptions = {
    reason: 'user',
};
export const defaultUpdateGlobalPropertyOptions: UpdateGlobalPropertyOptions = {
    reason: 'user',
};

export abstract class BaseModel {
    public eventEmitter = new DynamicEventEmitter();

    protected assetLoader!: IAssetsLoader;

    protected elements!: VisualElement[];

    protected backgroundColor!: BackgroundColor;

    protected dimension!: Dimension;

    protected textStyles!: TextStyles;

    protected accumulator: EventAccumulator = new EventAccumulator(this);

    emit<K extends keyof EventDataMap>(eventName: K, eventData: EventDataMap[K]): void {
        if (!this.accumulator.getLevel()) {
            this.eventEmitter.emit(eventName, eventData);

            return;
        }

        // not sure
        if ('element' in eventData && eventData.element) {
            const element = eventData.element;
            eventData.element = element.getCopy();
            eventData.element.parent = element.parent;
        }

        this.accumulator.addEvent([eventName, eventData]);
    }

    getEventAccumulator(): EventAccumulator {
        return this.accumulator;
    }

    beginAccumulation(): void {
        this.accumulator.begin();
    }

    endAccumulation(reason: Reason = 'user'): void {
        const level = this.accumulator.getLevel();
        this.accumulator.setLevel(level - 1);

        if (level > 1) {
            return;
        }

        this.accumulator.setLevel(1);

        if (reason === 'user') {
            this.removeEmptyGroupElements(this.elements);

            if (checkIsNeedUpdateElementsTreeByAccumulator(this.accumulator.getEvents())) {
                this.recalculateRenderOrders(reason);
            }

            const excludesToSiblings = this.getAllElementsRecursively();
            const events = this.accumulator.mergeEvents();
            getElementsToSiblingsFromAccumulator(events).forEach((accumulatedElement) => {
                const element = this.getElementById(accumulatedElement.id);

                if (!element) {
                    return;
                }

                this.applyCropToSiblings(element, excludesToSiblings, reason);
                excludesToSiblings.splice(
                    excludesToSiblings.findIndex((el) => el.id === element.id),
                    1,
                );
            });
        }

        this.accumulator.end(reason);
    }

    getAssetLoader(): IAssetsLoader {
        return this.assetLoader;
    }

    setAssetLoader(assetLoader: IAssetsLoader) {
        this.assetLoader = assetLoader;

        return this;
    }

    setTextStyles(textStyles: TextStyles) {
        this.textStyles = textStyles;

        return this;
    }

    setup() {
        this.setupElements();

        return this;
    }

    protected setupElements(elements: VisualElement[] = this.getAllElementsRecursively()) {
        elements.forEach((el) => {
            if (el.setCanvasDimension) {
                el.setCanvasDimension(this.getDimensions());
            }

            el.setAssetLoader(this.assetLoader);

            if (el instanceof TextElement) {
                el.setTextStyles(this.textStyles);
                el.init();
            }
        });
    }

    getElements() {
        return this.elements;
    }

    setElements(elements: VisualElement[]) {
        this.elements = elements;

        return this;
    }

    getElementById(id: number | string): VisualElement | undefined {
        return this.getAllElementsRecursively().find((element) => element.id === id);
    }

    getNextElementSibling(id: number): VisualElement | null {
        const element = this.getElementById(id);

        if (!element) {
            return null;
        }

        const siblings = (element.parent?.children ?? this.elements).sort(compareByRenderOrder);

        const index = siblings.findIndex((el) => el.id === id);

        const nextSiblingIndex = siblings.slice(index + 1).findIndex((el) => el.renderOrder > element.renderOrder);

        if (nextSiblingIndex === -1) {
            return null;
        }

        return siblings[index + 1 + nextSiblingIndex] ?? null;
    }

    getPrevElementSibling(id: number): VisualElement | null {
        const element = this.getElementById(id);

        if (!element) {
            return null;
        }

        const siblings = (element.parent?.children ?? this.elements).sort(compareByRenderOrder);

        const index = siblings.findIndex((el) => el.id === id);

        const prevSiblingIndex = siblings.slice(0, index).findLastIndex((el) => el.renderOrder < element.renderOrder);

        if (prevSiblingIndex === -1) {
            return null;
        }

        return siblings[prevSiblingIndex] ?? null;
    }

    getVisibleElements(frameIndex = 0): VisualElement[] {
        return this.getElements().filter((el) => el.isVisible(frameIndex));
    }

    getAllElementsRecursively(): VisualElement[] {
        return getAllElementsRecursively<VisualElement>(this.elements.filter(truthy)).sort(compareByRenderOrderDesc);
    }

    getAllGlobalProperties(): BaseProperty[] {
        throw new Error('Method not implemented.');
    }

    setBackgroundColor(backgroundColor: BackgroundColor) {
        this.backgroundColor = backgroundColor;

        return this;
    }

    getBackgroundColor(): BackgroundColor {
        return this.backgroundColor;
    }

    setDimensions(dimension: Dimension) {
        this.dimension = dimension;

        return this;
    }

    getDimensions(): Dimension {
        return this.dimension;
    }

    updateGlobalProperty(
        elType: string,
        newValues: Partial<BackgroundColorParams>,
        options: UpdateGlobalPropertyOptions = {},
    ): void {
        switch (elType) {
            case ElementTypes.BACKGROUND_COLOR: {
                const property = this.getBackgroundColor();
                const oldValues = property.getValuesByUpcomingUpdate(newValues);
                property.update(newValues);

                property.cleanupEmittingValues(oldValues);
                property.cleanupEmittingValues(newValues);

                if (!isEmpty(oldValues) || !isEmpty(newValues)) {
                    this.emit('backgroundColorUpdated', {
                        property,
                        oldValues,
                        newValues,
                    });
                }

                break;
            }
        }
    }

    updateDimension(
        rawDimension: {
            width: number;
            height: number;
        },
        options: UpdateGlobalPropertyOptions = {},
    ): void {
        this.beginAccumulation();
        const { reason } = {
            ...defaultUpdateGlobalPropertyOptions,
            ...options,
        };
        const newDimension = new Dimension(rawDimension.width, rawDimension.height);

        const updateFn = ({
            element,
            dimension,
            position,
        }: {
            element: VisualElement;
            dimension: DimensionObject;
            position: PositionObject;
        }): void =>
            this.updateElement(
                element.id,
                {
                    dimension,
                    position,
                },
                {
                    walkChildren: false,
                    walkParent: false,
                    reason,
                },
            );

        if (reason === 'user') {
            changeElementsPositionAndDimension(this.getElements(), this.dimension, newDimension, updateFn);
        }

        const oldValues = this.dimension.toObject();
        this.dimension.setWidth(newDimension.getWidth());
        this.dimension.setHeight(newDimension.getHeight());
        this.emit('dimensionUpdated', {
            dimension: this.getDimensions(),
            oldValues,
            newValues: this.dimension.toObject(),
        });
        // todo: improve
        this.endAccumulation(reason);
    }

    // insert parsed elements
    addElements(
        elements: VisualElement[],
        startFrame?: number,
        options: CreateElementOptions = {},
        flattenElementsArray: VisualElement[][] | undefined = undefined,
    ): void {
        function sortElements(a: VisualElement, b: VisualElement) {
            const diff = a.renderOrder - b.renderOrder;

            if (diff === 0) {
                return (a.startFrame || 0) - (b.startFrame || 0);
            }

            return diff;
        }

        this.beginAccumulation();

        const sortedElements = [...elements].sort(sortElements);

        const sortedFlattenElementsArray = flattenElementsArray
            ? new Array(flattenElementsArray.length).fill(null).map((_, i) => {
                  const newIdx = elements.indexOf(sortedElements[i]);

                  return flattenElementsArray[newIdx];
              })
            : flattenElementsArray;

        const { reason } = {
            ...defaultCreateElementOptions,
            ...options,
        };
        let currentId = Date.now();
        const idMap = new Map<number, number>();
        const createdIdsSet = new Set<number>();

        sortedElements.forEach((element, elIndex) => {
            const originalElement = this.getElementById(element.id);

            const parent: GroupElement | null =
                originalElement?.parent instanceof GroupElement
                    ? (this.getElementById(originalElement.parent.id) as GroupElement | null)
                    : element.parent ?? null;

            // parent in new elements is always null,
            // but in case of duplication we need to link it back to original element parent
            element.parent = parent;
            const placeToPush = parent ? parent.children : this.elements;

            let newElRenderOrder = element.renderOrder;

            const flattenElements =
                (sortedFlattenElementsArray && sortedFlattenElementsArray[elIndex]) ||
                getAllElementsRecursively([element]);

            // we don't need to update renderOrder for undo/redo
            if (reason === 'user') {
                newElRenderOrder = originalElement
                    ? originalElement.renderOrder + 1
                    : getNextElementRenderOrder(placeToPush);

                // elements inside groups could have the same renderOrder
                // so we increment the counter conditionally
                let i = 0;

                flattenElements.sort(sortElements).forEach((el, idx, arr) => {
                    const nextEl = arr[idx + 1];

                    // do not increment renderOrder if the next element has the same renderOrder
                    const increment = el.renderOrder === nextEl?.renderOrder ? 0 : 1;

                    el.renderOrder = newElRenderOrder + i;

                    i += increment;
                });
            }

            placeToPush.push(element);

            if (reason === 'user') {
                // change element(s) id
                flattenElements.forEach((el) => {
                    const id = el.id;

                    if (options.keepId) {
                        currentId = id;
                    }

                    el.id = currentId;
                    idMap.set(id, currentId);
                    createdIdsSet.add(currentId);
                    currentId++;
                });
                // change mask elementId
                flattenElements.forEach((el) => {
                    if (!el.mask || !el.mask.elementId || !idMap.has(el.mask.elementId)) {
                        return;
                    }

                    el.mask.elementId = idMap.get(el.mask.elementId)!;
                });
            }

            this.emit('elementCreated', { element });
            // setup canvas dimension, asset loader/load assets
            this.setupElements(flattenElements);

            if (reason !== 'user') {
                return;
            }

            // update startFrame
            this.updateElement(element.id, {
                startFrame,
            });

            const offset = flattenElements.length;
            const isInsertedElement = (el: VisualElement) =>
                el.id === element.id || idMap.has(el.id) || createdIdsSet.has(el.id);

            this.getAllElementsRecursively()
                .filter((el) => el.renderOrder >= newElRenderOrder && !isInsertedElement(el))
                .forEach((el) => {
                    this.updateElement(el.id, {
                        renderOrder: el.renderOrder + offset,
                    });
                });
        });

        this.endAccumulation(reason);
    }

    getUpdateElementOptions(): ElementUpdateOptions {
        return {};
    }

    getAllMaskElementRanges(): Map<string, Ranges<MaskModeTypes>> {
        return getMaskElementRanges(this.elements);
    }

    updateElement(elId: number | string, rawElement: any, options: UpdateElementOptions = {}): void {
        this.beginAccumulation();
        const element = this.getElementById(elId);
        const { walkChildren, walkParent, reason } = {
            ...defaultUpdateElementOptions,
            ...options,
        };

        if (!element) {
            this.endAccumulation(reason);

            return;
        }

        const newValues = element.specificUpdateDataProcessing(rawElement, reason);
        const oldPosition = element.position;
        const oldParent = element.parent as GroupElement;
        const oldValues = element.getValuesByUpcomingUpdate(newValues);
        const updateTypes: Set<ElementUpdateTypes> = element.update(newValues, this.getUpdateElementOptions());

        if (updateTypes.has(ElementUpdateTypes.CHILDREN) && element instanceof GroupElement) {
            element.update(element.getDiffBasedOnChildren());
        }

        if ('parent' in newValues) {
            // detach
            if (oldParent) {
                oldParent.update({
                    children: oldParent.children.filter((el) => el.id !== element.id),
                });
                oldParent.update(oldParent.getDiffBasedOnChildren());
            } else {
                this.setElements(this.getElements().filter((el) => el.id !== element.id));
            }

            // attach
            if (element.parent) {
                element.parent.update({
                    children: [...element.parent.children, element],
                });
                element.parent.update(element.parent.getDiffBasedOnChildren());
            } else {
                this.setElements([...this.getElements(), element]);
            }
        }

        if (walkChildren && element instanceof GroupElement && element.children) {
            const diff: any = {};
            const diffKeys: string[] = [];

            if (!oldPosition.equals(element.position)) {
                diff.position = element.position.subtract(oldPosition);
                diffKeys.push('position');
            }

            if (diffKeys.length) {
                element.children.forEach((child) => {
                    const diffData = diffKeys.reduce<{ position?: PositionObject }>((acc, key) => {
                        if (key === 'position') {
                            acc.position = child.position.add(diff.position).toObject();
                        }

                        return acc;
                    }, {});
                    this.updateElement(child.id, diffData, {
                        walkParent: false,
                    });
                });
            }
        }

        element.cleanupEmittingValues(oldValues);
        element.cleanupEmittingValues(newValues);

        if (!isEmpty(oldValues) || !isEmpty(newValues)) {
            this.emit('elementUpdated', {
                element,
                updateTypes,
                oldValues,
                newValues,
            });
        }

        if (walkParent && element.parent) {
            const diff = element.parent.getDiffBasedOnChildren();

            if (Object.keys(diff).length) {
                this.updateElement(element.parent.id, diff, {
                    walkChildren: false,
                });
            }
        }

        this.endAccumulation(reason);
    }

    updateElements(rawElements: any[], options: UpdateElementOptions = {}): void {
        this.beginAccumulation();
        rawElements.forEach((rawElement) => this.updateElement(rawElement.id, rawElement, options));
        this.endAccumulation();
    }

    removeElements(elementIds: number[], options: RemoveElementOptions = {}): void {
        this.beginAccumulation();
        const { reason } = {
            ...defaultRemoveElementOptions,
            ...options,
        };
        elementIds.forEach((elementId: number) => {
            const element = this.getElementById(elementId);

            if (!element) {
                return;
            }

            this.removeElementFromItsPosition(element);
        });
        this.endAccumulation(reason);
    }

    wrapElementsIntoGroup(elementIds: number[]): GroupElement {
        this.beginAccumulation();
        const elements = elementIds.map((elementId) => this.getElementById(elementId)).filter(truthy);
        const children = elements
            .filter((element) => !element.parent || !elements.includes(element.parent))
            .sort(compareByRenderOrder);
        const groupNumber =
            this.getAllElementsRecursively().filter((element) => element instanceof GroupElement && element.children)
                .length + 1;
        const newGroupEl = createGroupElement(children, groupNumber);
        newGroupEl.setCanvasDimension(this.dimension);
        newGroupEl.setAssetLoader(this.assetLoader);
        this.emit('elementCreated', {
            element: newGroupEl,
        });

        const movedChildren = new Map<GroupElement, { oldChildren: VisualElement[]; newChildren: VisualElement[] }>();

        children.forEach((child) => {
            const oldParent = child.parent;

            if (oldParent) {
                if (!movedChildren.has(oldParent)) {
                    const oldChildren = [...(oldParent?.children ?? [])];

                    movedChildren.set(oldParent, {
                        oldChildren,
                        newChildren: oldChildren,
                    });
                }

                const oldParentUpdates = movedChildren.get(oldParent);
                oldParentUpdates.newChildren = oldParentUpdates.newChildren.filter((el) => el.id !== child.id);
            }

            this.updateElement(
                child.id,
                {
                    parent: newGroupEl,
                },
                {
                    walkChildren: false,
                },
            );
        });

        // add new group element
        if (!newGroupEl.parent) {
            this.elements.push(newGroupEl);
        } else {
            movedChildren.forEach((updates, oldParent) => {
                this.emit('elementUpdated', {
                    element: oldParent,
                    updateTypes: new Set([ElementUpdateTypes.CHILDREN]),
                    oldValues: {
                        children: updates.oldChildren,
                    },
                    newValues: {
                        children: updates.newChildren,
                    },
                });
            });

            const children = [...newGroupEl.parent.children, newGroupEl].sort(compareByRenderOrder);
            this.updateElement(
                newGroupEl.parent.id,
                {
                    children,
                },
                {
                    walkChildren: false,
                },
            );
        }

        this.endAccumulation();

        return newGroupEl;
    }

    unwrapGroupElement(groupId: number | string): void {
        this.beginAccumulation();
        const groupEl = this.getElementById(groupId);

        if (!(groupEl instanceof GroupElement)) {
            return;
        }

        // get children and parent to create new association
        const children = [...groupEl.children];
        const parent = groupEl.parent;
        const initialParentChildren = parent?.children;

        children.forEach((el) =>
            this.updateElement(
                el.id,
                {
                    parent,
                },
                {
                    walkChildren: false,
                },
            ),
        );

        if (parent) {
            this.emit('elementUpdated', {
                element: parent,
                updateTypes: new Set([ElementUpdateTypes.CHILDREN]),
                oldValues: {
                    children: initialParentChildren,
                },
                newValues: {
                    children: parent.children,
                },
            });
        }

        this.endAccumulation();
    }

    addExistingElementToGroup(elementId: number | string, groupId: number | string): GroupElement {
        this.beginAccumulation();
        const parent = this.getElementById(groupId);

        if (!(parent instanceof GroupElement)) {
            throw new Error('Cant find the group element by given group id!');
        }

        this.updateElement(
            elementId,
            {
                parent,
            },
            {
                walkChildren: false,
            },
        );
        this.endAccumulation();

        return parent;
    }

    unwrapElementFromGroup(elementId: number | string): VisualElement {
        this.beginAccumulation();
        let element = this.getElementById(elementId);

        if (!element) {
            this.endAccumulation();
            throw new Error('Cant find the element by given id!');
        }

        if (element.parent.children.length === 1) {
            const copy = element.getCopy();
            copy.parent = null;
            copy.id = Date.now();

            let parentToRemove = element.parent;

            while (parentToRemove.parent?.children.length === 1) {
                parentToRemove = parentToRemove.parent;
            }

            this.removeElements([parentToRemove.id]);
            this.addElements([copy], copy.startFrame, { reason: 'user', keepId: true }, [[copy]]);

            element = copy;
        } else {
            this.updateElement(
                element.id,
                {
                    parent: null,
                },
                {
                    walkChildren: false,
                },
            );
        }

        this.endAccumulation();

        return element;
    }

    onElementPartialUpdate(elementId: number | string, eventType: ElementUpdateTypes, callbackFn: any): () => void {
        const listener = (data: ElementUpdatedEventData) => {
            const { element, updateTypes } = data;

            if (element.id === elementId && updateTypes.has(eventType)) {
                callbackFn(data);
            }
        };

        this.eventEmitter.on('elementUpdated', listener);

        return () => this.eventEmitter.off('elementUpdated', listener);
    }

    onElementsPartialUpdate(eventType: ElementUpdateTypes, callbackFn: any): () => void {
        const listener = (data: ElementUpdatedEventData) => {
            const { updateTypes } = data;

            if (updateTypes.has(eventType)) {
                callbackFn(data);
            }
        };

        this.eventEmitter.on('elementUpdated', listener);

        return () => this.eventEmitter.off('elementUpdated', listener);
    }

    onElementUpdate(elementId: number | string, callbackFn: any): () => void {
        const listener = (data: ElementUpdatedEventData) => {
            const { element } = data;

            if (element.id === elementId) {
                callbackFn(data);
            }
        };

        this.eventEmitter.on('elementUpdated', listener);

        return () => this.eventEmitter.off('elementUpdated', listener);
    }

    protected removeElementFromItsPosition(element: VisualElement): void {
        if (element.parent) {
            const children = element.parent.children.filter((el) => el.id !== element.id);

            if (children.length) {
                this.updateElement(
                    element.parent.id,
                    {
                        children,
                    },
                    {
                        walkChildren: false,
                    },
                );
                this.emit('elementRemoved', {
                    element,
                });
            } else {
                this.removeElementFromItsPosition(element.parent);
            }
        } else {
            const newElements = this.getElements().filter((el) => el.id !== element.id);
            this.setElements(newElements);
            this.emit('elementRemoved', {
                element,
            });
        }
    }

    protected removeEmptyGroupElements(elements: VisualElement[]): void {
        elements.forEach((element) => {
            if (!(element instanceof GroupElement)) {
                return;
            }

            this.removeEmptyGroupElements(element.children);

            if (!element.children.length) {
                this.removeElementFromItsPosition(element);
            }
        });
    }

    recalculateRenderOrders(reason: Reason = 'user'): void {
        if (reason !== 'user') {
            return;
        }

        const listOfElementWithNewRenderOrder: {
            element: VisualElement;
            renderOrder: number;
        }[] = [];
        calculateElementsRenderOrder(this.elements, listOfElementWithNewRenderOrder, 1);
        listOfElementWithNewRenderOrder.forEach(({ element, renderOrder }) => {
            this.updateElement(
                element.id,
                {
                    renderOrder,
                },
                {
                    walkParent: false,
                    walkChildren: false,
                },
            );
        });
    }

    protected applyCropToSiblings(element: VisualElement, elements: VisualElement[], reason: Reason = 'user'): void {
        if (reason !== 'user') {
            return;
        }

        type Params = {
            duration: number;
            startFrame?: number;
        };

        const updateFn = (element: VisualElement, params: Params): void => {
            this.updateElement(element.id, params);
        };

        const overlappingElementIds = applyCropToSiblings(elements, element, updateFn);
        this.removeElements(overlappingElementIds);
    }

    getCompModel(frameIndex = 0): CompModel {
        throw new Error('Method not implemented.');
    }

    createElement(type: ElementTypes, rawParams: any, options: CreateElementOptions): VisualElement {
        throw new Error('Method not implemented.');
    }

    getCopy(): BaseModel {
        throw new Error('Method not implemented.');
    }

    toObject(): Record<string, any> {
        return {
            elements: this.elements?.map((el) => el.toObject()) ?? [],
            dimension: this.dimension?.toObject() ?? null,
        };
    }

    protected getElementsCopy() {
        return this.elements.map((el) => el.getCopy());
    }
}
