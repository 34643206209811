import { useEffect, useMemo, useRef } from 'react';

export function useThrottledCallback<C extends (...args: any[]) => void>(
    callback: C,
    delay = 10,
): C & { cancel: () => void } {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const throttled = useMemo(() => {
        let timeoutId: ReturnType<typeof setTimeout> | null = null;
        let lastArgs: Parameters<C>;

        const throttledFunc = (...args: Parameters<C>) => {
            lastArgs = args;
            if (timeoutId === null) {
                callbackRef.current(...args);
                timeoutId = setTimeout(() => {
                    timeoutId = null;
                    // if new arguments were set, call the callback with them
                    if (lastArgs !== args) {
                        callbackRef.current(...lastArgs);
                    }
                }, delay);
            }
        };

        throttledFunc.cancel = () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
                timeoutId = null;
            }
        };

        return throttledFunc;
    }, [delay]) as C & { cancel: () => void };

    // Automatically cancel pending callbacks on unmount
    useEffect(() => {
        return () => {
            throttled.cancel();
        };
    }, [throttled]);

    return throttled;
}
