import styled, { css } from 'styled-components';
import { Input, token, ColorPicker, Form } from '@bynder/design-system';

export const PickerContainer = styled.div<{ disableAlpha?: boolean }>`
    position: relative;

    ${(props) =>
        props.disableAlpha &&
        css`
            & > div > div:nth-child(2) > div:nth-child(1) {
                width: 180px;
                margin: 0 0 0 auto;
            }
        `}

    button {
        position: absolute;
        top: 142px;
        left: 16px;
        background-color: ${token.white};
        z-index: 2;

        ${(props) =>
            props.disableAlpha &&
            css`
                top: 133px;
            `}
    }
`;

export const UppercaseInput = styled(Input)`
    input {
        text-transform: uppercase;
    }
`;

export const StyledColorPicker = styled(ColorPicker)`
    & > div {
        width: 270px;
    }
    & > div > div:nth-child(2) {
        grid-template-columns: minmax(10px, 1fr) 85px;
    }
`;

export const StyledRow = styled(Form.Row)`
    grid-template-columns: 160px repeat(auto-fill, 75px);
`;
