import { useEffect } from 'react';
import { isInteractiveElement } from '@bynder-studio/misc';
import { ImageManipulationRenderer, PlaybackManipulationRenderer } from '@bynder-studio/render-web';
import { isCtrlKey } from '~/helpers/hotKeys';
import useArrangeActions from './useArrangeActions';

type Props = {
    manipulationRenderer: PlaybackManipulationRenderer | ImageManipulationRenderer;
};

export const useArrangeShortcuts = ({ manipulationRenderer }: Props) => {
    const { swapUpDown, moveUpDown, handleBringToFront, handleSendToBack } = useArrangeActions({
        manipulationRenderer,
    });

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent): void => {
            if (isInteractiveElement(e)) {
                return;
            }

            const isImageDesign = manipulationRenderer instanceof ImageManipulationRenderer;

            if (isCtrlKey(e)) {
                switch (e.code) {
                    case 'BracketLeft': {
                        e.preventDefault();
                        isImageDesign ? swapUpDown('up') : moveUpDown('up');
                        break;
                    }

                    case 'BracketRight': {
                        e.preventDefault();
                        isImageDesign ? swapUpDown('down') : moveUpDown('down');
                        break;
                    }

                    default:
                        break;
                }
            }

            if (!isCtrlKey(e)) {
                switch (e.code) {
                    case 'BracketLeft': {
                        e.preventDefault();
                        handleBringToFront();
                        break;
                    }

                    case 'BracketRight': {
                        e.preventDefault();
                        handleSendToBack();
                        break;
                    }

                    default:
                        break;
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [manipulationRenderer]);
};
