import styled from 'styled-components';
import { token, Inspector, DescriptionList } from '@bynder/design-system';
import PagePreviewsBase from 'packages/pages/design/components/PagePreview/PagePreviewsBase';
import ConfiguratorTimeline from 'packages/pages/design/timeline';

export const CanvasContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    // this makes the sive of the container independent of the size of the canvas
    & > canvas {
        position: absolute;
        box-shadow: 0 0 0 1px ${token.gray100};
    }
`;

export const Sidebar = styled(Inspector)`
    grid-area: sidebar;
    border-top-right-radius: ${token.radiusLarge};
    border-bottom-right-radius: ${token.radiusLarge};
    padding-top: ${token.spacing4};
`;

export const SidebarFooter = styled(Inspector.Footer)`
    border-bottom-right-radius: ${token.radiusLarge};
`;

export const Grid = styled.div`
    // the get gets its height from the parent
    // position absolute is needed to make the parent size decoupled
    position: absolute;
    width: 100%;
    display: grid;
    grid-template:
        'header header sidebar' 72px
        'previews canvas sidebar' 1fr
        'timeline timeline sidebar' auto
        / auto 1fr 360px;
    gap: ${token.spacing6};
`;

export const StyledPagePreviews = styled(PagePreviewsBase)`
    grid-area: previews;
`;

export const StyledHeader = styled.div`
    grid-area: header;

    & > div {
        padding-right: 0;
    }
`;

export const StyledConfiguratorTimeline = styled(ConfiguratorTimeline)`
    grid-area: timeline;
    border-bottom-left-radius: ${token.radiusLarge};
    overflow: hidden;

    // styles from scss has more specificity than styled-components
    max-width: 100% !important;
    min-width: 400px !important;
    height: 152px !important;

    & .timeline--header-container {
        box-shadow: none;
    }
`;

export const CanvasContainerWrapper = styled.div`
    grid-area: canvas;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    display: flex;
    justify-self: center;
    align-self: stretch;
    justify-content: center;
    align-items: center;
`;

export const StyledDescription = styled(DescriptionList.Description)`
    white-space: pre-line;
`;
