import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ColorParams } from '@bynder-studio/render-core';
import useTextSelection from 'packages/hooks/useTextSelection';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import { getExistingBrandColor } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor/utils';
import { rgbToHex } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import { BrandColor } from 'packages/store/brandColors/types';
import { BrandColorOnElement } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor';
import { extractUnique } from 'packages/pages/editor/RightSideMenu/Shots/Text/Fonts/utils';
import { collectColorsFromElement } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/utils';
import { brandColor2ColorParams, color2ColorParams, colorToCSS, rgbaToObject } from '~/common/editor/editorHelper';

const useFontColorManipulator = ({ creativeModel, selectedElement }) => {
    const id = selectedElement.id;
    const { color: colors, getRawStyles } = useTextSelection();
    const { palettes: rawPalettes } = useSelector(colorPalettes);
    const elementColors = collectColorsFromElement(selectedElement, rawPalettes);

    const palettes = useMemo(() => {
        return rawPalettes
            .map((palette) => {
                const filteredColors = palette.brandColors.filter((c) => ['SOLID']?.includes(c.type));
                return {
                    ...palette,
                    brandColors: filteredColors,
                };
            })
            .filter((palette) => !!palette.brandColors.length);
    }, [rawPalettes]);

    const updateText = useCallback(
        (property, newFontColor) => {
            const param = {
                updateTextSettingForSelection: {
                    settings: {
                        color: newFontColor,
                    },
                },
            };

            creativeModel.updateElement(id, param);
        },
        [creativeModel, id],
    );

    // TODO: Review the logc about updating text by updateTextSettingsByEachRun,
    // seems to be useless after gradient changes
    const setColor = (newValue: ColorParams) => {
        if (colors.length > 1) {
            const styles = getRawStyles().color.map(() => ({
                color: {
                    ...newValue,
                    brandColorId: null,
                },
            }));
            const param = { updateTextSettingsByEachRun: { styles } };
            creativeModel.updateElement(id, param);
        } else {
            updateText('color', { ...newValue, brandColorId: null });
        }
    };

    const onChange = useCallback(
        (val) => {
            updateText('color', { ...color2ColorParams(val), brandColorId: null });
        },
        [updateText],
    );

    const [brandColors, customColors]: [BrandColorOnElement[] | [], ColorParams[] | []] = useMemo(() => {
        const brandColorObj = {};
        const customColorObj = {};

        (colors as ColorParams[]).forEach((c) => {
            const brandColor = !c.brandColorId ? null : getExistingBrandColor(palettes, c.brandColorId);

            if (brandColor && c.brandColorId) {
                brandColorObj[c.brandColorId] = brandColor;
            } else {
                const hexCode = rgbToHex(colorToCSS(c));
                customColorObj[hexCode] = c;
            }
        });

        return [Object.values(brandColorObj), Object.values(customColorObj)];
    }, [colors, palettes]);

    const setBrandColor = (color: BrandColor) => {
        updateText('color', { brandColorId: id, ...brandColor2ColorParams(color) });
    };

    const onColorDetach = () => {
        if (colors.length === 1) {
            updateText('color', { ...brandColor2ColorParams(brandColors[0]), brandColorId: null });
        } else {
            const styles = (getRawStyles().color as ColorParams[]).map((c) => ({
                color: { ...rgbaToObject(colorToCSS(c)), brandColorId: null },
            }));

            const param = { updateTextSettingsByEachRun: { styles } };
            creativeModel.updateElement(id, param);
        }
    };

    const onAppliedClick = (color: BrandColor | ColorParams) =>
        'hexCode' in color ? setBrandColor(color as BrandColor) : setColor(color);

    const isMixed = useMemo(
        () =>
            extractUnique(
                colors.map((c) => {
                    const { opacity, brandColorId, ...others } = c;

                    return others;
                }),
            ).length > 1,
        [colors],
    );

    const isAlphaMixed = useMemo(() => [...new Set(colors.map((c) => c.opacity))].length > 1, [colors]);

    return {
        hasBrandColors: !!brandColors.length,
        isMixed,
        isAlphaMixed,
        palettes,
        brandColors,
        customColors,
        onChange,
        onAppliedClick,
        setBrandColor,
        onColorDetach,
        colors: colors as ColorParams[],
        elementColors,
    };
};

export default useFontColorManipulator;
