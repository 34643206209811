import { test } from 'rambda';
import { elementSortFn } from './helpers/elementtree';
import type { ColorParams } from '@bynder-studio/render-core';
import type { ColorStop } from '@bynder-studio/misc';
import type { Color } from 'packages/ds/ColorPicker/types';
import type { BrandColor, GradientBrandColor } from 'packages/store/brandColors/types';
import { rgbToHex } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';

export function rgbaToObject(rgba: string) {
    const parts = rgba.substring(rgba.indexOf('(') + 1, rgba.indexOf(')')).split(',');

    return {
        type: 'SOLID' as const,
        red: Number(parts[0]),
        green: Number(parts[1]),
        blue: Number(parts[2]),
        opacity: Number(parts[3]),
    };
}

export function hexToRgb(hex: string, a: string | number) {
    let newHex = hex.replace('#', '').replace(/_/g, '');

    if (newHex.length === 3) {
        newHex = `${newHex[0].repeat(2) + newHex[1].repeat(2) + newHex[2].repeat(2)}`;
    }

    if (newHex.length < 3) {
        newHex = 'FFFFFF';
    }

    const r = parseInt(newHex.substring(0, 2), 16) || 0;
    const g = parseInt(newHex.substring(2, 4), 16) || 0;
    const b = parseInt(newHex.substring(4, 6), 16) || 0;

    return `rgba(${r},${g},${b},${a})`;
}

// TODO: It's appeared this fn is used not only for brandColors which is against of the fn name
export function brandColor2ColorParams<U extends ColorParams>(
    brandColor: BrandColor,
): U & { brandColorId: number | null } {
    if (brandColor.type === 'SOLID') {
        const rgba = hexToRgb(brandColor.hexCode, brandColor.alpha);
        const colorObj = rgbaToObject(rgba);

        return {
            ...colorObj,
            brandColorId: brandColor.id || null,
        };
    }

    const { id, displayOrder, name, ...rest } = brandColor as GradientBrandColor;

    return {
        ...rest,
        paletteName: undefined,
        brandColorId: brandColor.id || null,
    };
}

export function colorParams2Color(color: ColorParams): Color {
    if (color.type === 'SOLID') {
        const hex = rgbToHex(colorToCSS(color));
        const opacity = Math.floor(color.opacity * 100);

        return {
            color: hex,
            opacity,
        };
    }

    return {
        type: color.type === 'LINEAR_GRADIENT' ? 'linear' : 'radial',
        angle: color.type === 'LINEAR_GRADIENT' ? color.angle : undefined,
        stops: color.stops.map((stop) => ({
            color: {
                color: rgbToHex(colorToCSS(stop)),
                opacity: stop.opacity * 100,
            },
            position: stop.offset * 100,
        })),
    };
}

export function color2ColorParams(color: Color): ColorParams {
    if (typeof color === 'string') {
        return { ...rgbaToObject(hexToRgb(color, 1)), brandColorId: null };
    }

    if ('opacity' in color) {
        return { ...rgbaToObject(hexToRgb(color.color, color.opacity / 100)), brandColorId: null };
    }

    const stops: ColorStop[] = color.stops.map((stop) => {
        const { hex, opacity } =
            typeof stop.color === 'string'
                ? {
                      hex: stop.color,
                      opacity: 1,
                  }
                : {
                      hex: stop.color.color,
                      opacity: stop.color.opacity / 100,
                  };

        const { red, green, blue } = rgbaToObject(hexToRgb(hex, opacity));

        return {
            offset: stop.position / 100,
            opacity,
            red,
            green,
            blue,
        };
    });

    if (color.type === 'linear') {
        return {
            type: 'LINEAR_GRADIENT',
            angle: color.angle || 0,
            stops,
            brandColorId: null,
        };
    }

    return {
        type: 'RADIAL_GRADIENT',
        angle: 0,
        stops,
        brandColorId: null,
    };
}

export const colorToCSS = (
    colorObj: ColorParams | ColorStop | string = {
        type: 'SOLID',
        red: 0,
        green: 0,
        blue: 0,
        opacity: 1,
        brandColorId: null,
    },
) => {
    if (typeof colorObj !== 'object') {
        return colorObj;
    }

    const solidColor = !('type' in colorObj) || colorObj.type === 'SOLID' ? colorObj : colorObj.stops[0];

    const [r, g, b, a] = [solidColor.red, solidColor.green, solidColor.blue, solidColor.opacity];

    return `rgba(${r},${g},${b},${a})`;
};

export const shadowObjToString = (value) => {
    return {
        color: colorToCSS(value.color),
        blur: value.blurRadius,
        offsetX: Math.round(value.offset * Math.sin((value.angle * Math.PI) / 180)),
        offsetY: -Math.round(value.offset * Math.cos((value.angle * Math.PI) / 180)),
    };
};

export function prepareDataForSave(dataToParse) {
    const elements = Object.values(dataToParse).map((el) => {
        const element = copy(el);
        if (element.properties.value) {
            delete element.properties.value.thumbnail;
        }
        return { id: element.id, type: element.type.toUpperCase(), properties: element.properties };
    });

    return { elements };
}

export const copy = (e) => {
    return JSON.parse(JSON.stringify(e));
};

export function isImportedAnimation(type) {
    return ['CORNER_PIN', 'DATA_IMPORT'].includes(type);
}

export const propertiesTypesMap = new Map([
    ['SELECT', 'SELECTBOX'],
    ['DATA_IMPORT_LIST', 'DATA_IMPORT'],
    ['CORNER_PIN_LIST', 'CORNER_PIN_LIST'],
    ['NUMERIC', 'NUMBER'],
    ['DURATION', 'FRAMEPICKER'],
    ['ANIMATION_TIMING', 'SELECTBOX'],
    ['TEXT_BREAKUP', 'TEXT_BREAKUP'],
]);

export const propertiesUnitMap = new Map([
    ['distance', '%'],
    ['opacity', '%'],
    ['scale', '%'],
    ['blur', '%'],
]);

export const ANIMATIONS_NAME = 'ANIMATIONS_NAME';
export const IMG_VIDEO_VALUE = 'value';
export const PREVIEW_CANVAS_ID = 'PREVIEW_CANVAS_ID';

export function sortElementsKeys(elements) {
    return Object.keys(elements).sort(elementSortFn((id) => elements[id], false));
}

export const getRandomString = (length) => {
    let ret = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        ret += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return ret;
};

export const flattenNestedTags = (html) => {
    const isOpening = test(/<(b|i)>/);
    const isClosing = test(/<\/(b|i)>/);
    const isBr = test(/<\/?br>/);
    const tagRegExp = /<\/?\w+>/g;
    const closeTag = (t) => t.replace(/</, '</');

    const tags = [];

    let match = null;
    while ((match = tagRegExp.exec(html)) !== null) {
        tags.push({
            name: match[0],
            index: match.index,
            length: match[0].length,
        });
    }

    const tagStack = [];
    const unmatchedTags = tags
        .map((tag) => {
            if (isBr(tag.name) && tagStack.length) {
                return [[...tagStack.map((t) => ({ ...t }))], tag];
            }

            if (isOpening(tag.name)) {
                tagStack.push(tag);
            } else if (isClosing(tag.name)) {
                tagStack.pop(tag);
            }
        })
        .filter(Boolean);

    let ret = html;
    const replacementData = unmatchedTags.map((unmatchedTag) => {
        const [openTags, tag] = unmatchedTag;
        const closing = openTags
            .map((t) => closeTag(t.name))
            .reverse()
            .join('');
        const opening = openTags.map((t) => t.name).join('');

        const placeholder = getRandomString(tag.length);
        ret = ret.slice(0, tag.index) + placeholder + ret.slice(tag.index + tag.length);
        return [placeholder, closing + tag.name + opening];
    });

    replacementData.forEach((replacement) => {
        const [placeholder, replacementTag] = replacement;
        ret = ret.replace(placeholder, replacementTag);
    });

    return ret;
};

export function parseHTML(html) {
    if (!html) return '';
    if (!html.includes('</div>')) return html;

    const cleanedHTML = html
        .replace(/<br>(?=<\/.+>)/g, '')
        .split('</div>')
        .filter(Boolean)
        .map((v) => v.replace('<div>', ''))
        .map((v) => (v.replace(/<[ib]>/gi, '') === '<br>' ? '' : v))
        .join('<br>');

    return flattenNestedTags(cleanedHTML);
}

export const stylesMap = new Map([
    ['REGUALR', { bold: false, italic: false }],
    ['BOLD', { bold: true, italic: false }],
    ['ITALIC', { bold: false, italic: true }],
    ['BOLD_ITALIC', { bold: true, italic: true }],
]);

export function escapeHtml(text) {
    return text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
}

export function escapeHtmlPartly(text) {
    return text
        .replace(/&(?!nbsp;)/g, '&amp;')
        .replace(/<([^>]*)(>?)/g, (m, g1, g2) => {
            if (!(['b', 'i', 'br', '/b', '/i'].includes(g1) && g2)) {
                return m.replace('<', '&lt;').replace('>', '&gt');
            } else {
                return m;
            }
        })
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
        .replace(/\n/g, '<br>');
}

export function unescapeHtml(html) {
    return parseHTML(html)
        .replace(/<br>/g, '\n')
        .replace(/<[^>]+>/g, '')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&nbsp;/g, ' ')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
        .replace(/&amp;/g, '&');
}

export function unescapeHtmlPartly(html) {
    return parseHTML(html)
        .replace(/<br>/g, '\n')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&nbsp;/g, ' ')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
        .replace(/&amp;/g, '&');
}

export function getTextStyles(value, families, fontId) {
    const textValue = unescapeHtmlPartly(value);
    const rows = textValue.split('\n');
    const fontStyles = getFontStylesForElement(families, fontId);
    const styles = {};
    if (fontStyles) {
        rows.forEach((row, i) => {
            styles[i] = getCanvasStyles(row, fontStyles);
        });
    }
    return styles;
}

export function getFontStylesForElement(families, fontId) {
    if (!fontId) return {};
    const family = getFamilyByFontId(families, fontId);
    if (!family) return;
    return {
        BOLD: getStyleId(family, 'BOLD'),
        ITALIC: getStyleId(family, 'ITALIC'),
        BOLD_ITALIC: getStyleId(family, 'BOLD_ITALIC'),
        REGULAR: getStyleId(family, 'REGULAR'),
    };
}

export function getCanvasStyles(row, fontStyles) {
    const chars = row.split('');
    let currentStyle = { bold: 0, italic: 0 };
    let currentinx = 0;
    const style = {};
    chars.forEach((char, i) => {
        if (char === '<') {
            const next = chars[i + 1];
            if (next === 'b') {
                currentStyle.bold = currentStyle.bold + 1;
            } else if (next === 'i') {
                currentStyle.italic = currentStyle.italic + 1;
            } else if (next === '/') {
                const next = chars[i + 2];
                if (next === 'b') {
                    currentStyle.bold = currentStyle.bold - 1;
                } else if (next === 'i') {
                    currentStyle.italic = currentStyle.italic - 1;
                }
            }
        } else if (char !== '>' && chars[i - 1] !== '<' && chars[i - 2] !== '<') {
            const fontId = getStyle(currentStyle, fontStyles);
            if (fontId) style[currentinx] = { fontFamily: fontId };
            currentinx++;
        }
    });
    return style;
}

function getStyle(style, fontStyles) {
    if (style.italic > 0 && style.bold > 0) return fontStyles['BOLD_ITALIC'];
    if (style.bold > 0) return fontStyles['BOLD'];
    if (style.italic > 0) return fontStyles['ITALIC'];
    return null;
}

function getFamilyByFontId(families, fontId) {
    return families.find((family) => family.fonts.find((font) => font.id === fontId));
}

function getStyleId(family, style) {
    const font =
        family.fonts.find((font) => font.style === style) || family.fonts.find((font) => font.style === 'REGULAR');
    return font && font.id.toString();
}

export const frameIndexToTime = (frameIndex, frameRate) => {
    //TODO: frame accurate seeking is not well supported
    //https://bugs.chromium.org/p/chromium/issues/detail?id=66631
    //seek to just before next frame (seek-to-closest-yet-not-past-keyframe)
    return frameIndex / frameRate + (1 / frameRate - 0.001);
};

export const millisecondToFrames = (milliseconds, frameRate) => Math.floor((frameRate * milliseconds) / 1000);

export const framesToMilliseconds = (frames, frameRate) => (frames * 1000) / frameRate;

// NOTE: Use special fn for transforming frames to milliseconds
// ONLY (!) for getting right frame in ms for playback.
export const framesToMillisecondsForSeek = (frames, frameRate) => frameIndexToTime(frames, frameRate) * 1000;

export const isTypeBackgroundOrPosterTime = (e) =>
    ['POSTER_FRAME', 'BACKGROUND_COLOR', 'GLOBAL_AUDIO'].includes(e.type);

export const rangesOverlaping = (a, b) => {
    if (a.from > b.to || a.to <= b.from) return false;
    return true;
};

export default {
    colorToCSS,
    sortElementsKeys,
};
