import { TemplateElement } from '@bynder-studio/render-core';
import { ColorParams, UnifiedCommonСolor, ExtendedColorParams } from '@bynder-studio/misc';
import { toRGBA } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextStyles/utils';
import {
    hexToRgb,
    isGradientColor,
    rgbToHex,
} from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import { rgbaToObject, colorToCSS, brandColor2ColorParams } from '~/common/editor/editorHelper';
import { BrandColor } from 'packages/store/brandColors/types';

export const getUnifiedColor = (
    color: ColorParams,
    byId: { [brandColorId: number]: BrandColor },
): UnifiedCommonСolor => {
    const extraParams = isGradientColor(color) ? brandColor2ColorParams(color) : {};

    if (color?.brandColorId && color.brandColorId in byId) {
        const { hexCode, id, name, alpha: opacity } = byId[color.brandColorId];

        return { hexCode, ...extraParams, id, name, opacity };
    }

    const hexCode = rgbToHex(colorToCSS(color)).replace('#', '');

    return {
        hexCode,
        opacity: color.opacity,
        ...extraParams,
        id: undefined,
        name: undefined,
    };
};

const retrieveBrandColor = (
    styles: Set<string | null>,
    colors: ExtendedColorParams[],
    byId: { [brandColorId: number]: BrandColor },
    brandColorId?: number,
) => {
    const brandColor = brandColorId && byId[brandColorId];

    if (!brandColor) {
        return;
    }

    if (isGradientColor(brandColor)) {
        const convertedColor = brandColor2ColorParams(brandColor);
        const { name, id } = brandColor;

        const color: ExtendedColorParams = {
            brandColorId: id,
            ...convertedColor,
            name,
        };

        const key = convertedColor.stops.reduce((acc, curr) => acc + toRGBA(curr), color.brandColorId);

        if (!styles.has(key)) {
            styles.add(key);
            colors.push(color);
        }
    } else {
        const color: ExtendedColorParams = {
            brandColorId: brandColor.id,
            ...rgbaToObject(hexToRgb(brandColor.hexCode, brandColor.alpha)),
            name: brandColor.name,
        };
        const key = toRGBA(color) + color.brandColorId;

        if (!styles.has(key)) {
            styles.add(key);
            colors.push(color);
        }
    }
};

export const collectConfigureColors = (
    templateElement: TemplateElement,
    brandColorsPropName: string,
    colorPropName: string,
    byId: { [brandColorId: number]: BrandColor },
): ExtendedColorParams[] => {
    const styles = new Set<string | null>();
    const colors: ExtendedColorParams[] = [];

    (templateElement.properties[brandColorsPropName] || []).forEach((brandColorId: number) => {
        retrieveBrandColor(styles, colors, byId, brandColorId);
    });

    // Retrieving default persisten color from template
    const color = colorPropName.split('.').reduce((accum, current) => accum[current], templateElement.properties);

    if (!color.brandColorId) {
        colors.push({ ...color, name: rgbToHex(colorToCSS(color)) });
    } else {
        retrieveBrandColor(styles, colors, byId, color.brandColorId);
    }

    return colors;
};
