import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, List, ModalBase, Thumbnail, Divider } from '@bynder/design-system';
import {
    BackgroundColorParams,
    ContentPropertiesManager,
    ContentProperty,
    ShapeElement,
    TextElement,
} from '@bynder-studio/render-core';
import { useTranslate } from '@bynder/localization';
import { useDispatch, useSelector } from 'react-redux';
import { hexToRgb } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import { markAsUnsaved } from 'packages/store/creativeEditor/creativeEditor.actions';
import generateTestId from '~/helpers/testIdHelpers';
import useEditor from '~/hooks/useEditor';
import { collectBrandColorIds, collectCustomColors } from './utils';
import modalContainer from 'packages/common/modalContainer';
import { ColorThumbnail } from 'packages/pages/brandColors/components/ColorThumbnail';
import { brandColor2ColorParams, colorParams2Color } from '~/common/editor/editorHelper';

export type ElementStylesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    element: TextElement | ShapeElement | BackgroundColorParams;
    propertyName: string;
    brandColorProperty: string;
    title: string;
    globalPropertyName?: string;
};

const pass = () => {};

const parsePropertyName = (element: TextElement | ShapeElement | BackgroundColorParams, propertyName: string) =>
    propertyName.split('.').reduce((accum, current) => accum[current], element);

export const ColorStylesModal: FC<ElementStylesModalProps> = ({
    isOpen,
    onClose,
    element,
    propertyName,
    brandColorProperty,
    title,
    globalPropertyName = '',
}) => {
    const dispatch = useDispatch();
    const { creativeModel } = useEditor();
    const { translate } = useTranslate();
    const { palettes } = useSelector(colorPalettes);
    const [isSaveAllowed, setIsSaveAllowed] = useState(false);
    const [selectedBrandColors, setSelectedBrandColors] = useState<number[]>([]);
    const appliedColors = useMemo(() => collectBrandColorIds(parsePropertyName(element, propertyName)), [element]);
    const customColors = useMemo(() => collectCustomColors(parsePropertyName(element, propertyName)), [element]);

    const toggleBrandColor = useCallback<(brandColorId: number) => void>((brandColorId) => {
        setIsSaveAllowed(true);
        setSelectedBrandColors((prev) => {
            if (prev.includes(brandColorId)) {
                return prev.filter((id) => id !== brandColorId);
            }
            return [...prev, brandColorId];
        });
    }, []);

    const toggleSelectAllBrandColors = useCallback<(paletteId: number) => void>(
        (paletteId) => {
            setIsSaveAllowed(true);
            const palette = palettes.find((p) => p.id === paletteId);
            if (!palette) return;

            const brandColorIdSet = new Set(palette.brandColors.map((color) => color.id));

            setSelectedBrandColors((prev) => {
                const isAllPaletteColorsSelected = palette.brandColors.every((color) => prev.includes(color.id));
                if (isAllPaletteColorsSelected) {
                    return prev.filter((id) => appliedColors.has(id) || !brandColorIdSet.has(id));
                }
                return [...new Set([...prev, ...palette.brandColors.map((color) => color.id)])];
            });
        },
        [palettes],
    );

    const handleSave = useCallback(() => {
        const uniqueBrandColorsIds = [...new Set(selectedBrandColors)];

        if (globalPropertyName) {
            creativeModel.updateGlobalProperty(globalPropertyName, {
                ...element,
                [brandColorProperty]: uniqueBrandColorsIds,
            });
        } else {
            const { contentPropertyId } = element;

            if (contentPropertyId) {
                const contentPropertyManager: ContentPropertiesManager = creativeModel.getContentPropertiesManager();
                const contentProperty = contentPropertyManager.getContentProperty(contentPropertyId);

                if (contentProperty) {
                    contentPropertyManager.updateContentProperty({
                        ...contentProperty,
                        properties: {
                            ...contentProperty?.properties,
                            [brandColorProperty]: uniqueBrandColorsIds,
                        },
                    } as Partial<ContentProperty>);
                }
            } else {
                creativeModel.updateElement(element.id, { [brandColorProperty]: uniqueBrandColorsIds });
            }
        }

        dispatch(markAsUnsaved());
        onClose();
    }, [element, creativeModel, selectedBrandColors, brandColorProperty, globalPropertyName]);

    useEffect(() => {
        setSelectedBrandColors([
            ...collectBrandColorIds(parsePropertyName(element, propertyName)),
            ...element[brandColorProperty],
        ]);
    }, [isOpen]);

    return (
        <ModalBase container={modalContainer} isOpen={isOpen} onClose={onClose}>
            <ModalBase.Header title={title} />
            <ModalBase.Content>
                <List>
                    {!!customColors.length && (
                        <List.Item
                            checkedVariant="checkbox"
                            onClick={pass}
                            subtext={translate('editor.sidebar.shots.configure.modal.selected-custom-colors', {
                                selected: customColors.length,
                                total: customColors.length,
                            })}
                            isChecked
                            isDisabled
                        >
                            {translate('editor.sidebar.shots.configure.modal.all-custom-colors')}
                        </List.Item>
                    )}
                    {[...customColors].map((color) => (
                        <List.Item
                            key={color.hexCode}
                            checkedVariant="checkbox"
                            onClick={pass}
                            thumbnail={
                                <Thumbnail
                                    shape="circle"
                                    backgroundColor={hexToRgb(color.hexCode, color.alpha)}
                                    variant="content"
                                    size="s"
                                />
                            }
                            isChecked
                            isDisabled
                        >
                            {color.hexCode.toLocaleUpperCase()}
                        </List.Item>
                    ))}
                    {customColors.length > 0 && <Divider />}
                    {palettes
                        .filter((palettes) => !!palettes.brandColors.length)
                        .map((palette) => (
                            <Fragment key={palette.id}>
                                <List.Item
                                    checkedVariant="checkbox"
                                    isChecked={palette.brandColors.every((color) =>
                                        selectedBrandColors.includes(color.id),
                                    )}
                                    onClick={() => toggleSelectAllBrandColors(palette.id)}
                                    subtext={translate('editor.sidebar.shots.configure.modal.selected-colors', {
                                        selected: palette.brandColors.filter((color) =>
                                            selectedBrandColors.includes(color.id),
                                        ).length,
                                        total: palette.brandColors.length,
                                    })}
                                >
                                    {palette.name}
                                </List.Item>
                                {palette.brandColors.map((color) => (
                                    <List.Item
                                        key={color.id}
                                        checkedVariant="checkbox"
                                        isChecked={selectedBrandColors.includes(color.id)}
                                        isDisabled={appliedColors.has(color.id)}
                                        onClick={() => toggleBrandColor(color.id)}
                                        thumbnail={
                                            <ColorThumbnail
                                                color={colorParams2Color(brandColor2ColorParams(color))}
                                                shape="circle"
                                                size="s"
                                            />
                                        }
                                    >
                                        {color.name}
                                    </List.Item>
                                ))}
                                {palette.brandColors.length > 0 && <Divider />}
                            </Fragment>
                        ))}
                </List>
            </ModalBase.Content>
            <ModalBase.Footer
                actionPrimary={
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        isDisabled={!isSaveAllowed}
                        {...generateTestId('shots_text_element_styles_modal_save')}
                    >
                        {translate('editor.sidebar.shots.text.textStyle.modal.elementStyles.save')}
                    </Button>
                }
                actionSecondary={
                    <Button
                        variant="secondary"
                        onClick={onClose}
                        {...generateTestId('shots_text_element_styles_modal_cancel')}
                    >
                        {translate('editor.sidebar.shots.text.textStyle.modal.elementStyles.cancel')}
                    </Button>
                }
            />
        </ModalBase>
    );
};
