import { ColorStop, Gradient, SolidColor } from '@bynder-studio/misc';

export type BrandColorPalette = {
    id: number;
    name: string;
    brandColors: BrandColor[];
};

export type ColorPalettesState = {
    palettes: BrandColorPalette[];
    palette: null | BrandColorPalette;
    isFetching: boolean;
    totalItems: number;
    isPaletteFetching: boolean;
    editInProgress: boolean;
    deleteInProgress: boolean;
    createInProgress: boolean;
    palettesErrors?: any;
    paletteErrors?: any;
};

type BaseBrandColor = {
    id: number;
    name: string;
    hexCode: string;
    displayOrder: number;
    paletteName: string;
};

export type SolidBrandColor = BaseBrandColor & {
    type: SolidColor['type'];
    hexCode: string;
    alpha: number;
};

export type GradientBrandColor = BaseBrandColor & {
    type: Gradient['type'];
    angle: 90;
    stops: ColorStop[];
};

export type BrandColor = SolidBrandColor | GradientBrandColor; // will add gradient types later

export type CreateBrandColorData =
    | Pick<SolidBrandColor, 'name' | 'hexCode' | 'alpha' | 'type'>
    | Pick<GradientBrandColor, 'name' | 'stops' | 'type'>;

export type BrandColorsState = {
    colors: BrandColor[];
    color: BrandColor | null;
    isFetching: boolean;
    totalItems: number;
    editingInProgress: boolean;
    deleteInProgress: boolean;
    creatingInProgress: boolean;
    colorsErrors?: any;
    colorErrors?: any;
};

export enum actionTypes {
    // palette level
    GET_COLOR_PALETTES_REQUEST = 'GET_COLOR_PALETTES_REQUEST',
    GET_COLOR_PALETTES_SUCCESS = 'GET_COLOR_PALETTES_SUCCESS',
    GET_COLOR_PALETTES_FAILURE = 'GET_COLOR_PALETTES_FAILURE',
    GET_COLOR_PALETTE_REQUEST = 'GET_COLOR_PALETTE_REQUEST',
    GET_COLOR_PALETTE_SUCCESS = 'GET_COLOR_PALETTE_SUCCESS',
    GET_COLOR_PALETTE_FAILURE = 'GET_COLOR_PALETTE_FAILURE',
    ADD_COLOR_PALETTE_REQUEST = 'ADD_COLOR_PALETTE_REQUEST',
    ADD_COLOR_PALETTE_SUCCESS = 'ADD_COLOR_PALETTE_SUCCESS',
    ADD_COLOR_PALETTE_FAILURE = 'ADD_COLOR_PALETTE_FAILURE',
    EDIT_COLOR_PALETTE_REQUEST = 'EDIT_COLOR_PALETTE_REQUEST',
    EDIT_COLOR_PALETTE_SUCCESS = 'EDIT_COLOR_PALETTE_SUCCESS',
    EDIT_COLOR_PALETTE_FAILURE = 'EDIT_COLOR_PALETTE_FAILURE',
    DELETE_COLOR_PALETTE_REQUEST = 'DELETE_COLOR_PALETTE_REQUEST',
    DELETE_COLOR_PALETTE_SUCCESS = 'DELETE_COLOR_PALETTE_SUCCESS',
    DELETE_COLOR_PALETTE_FAILURE = 'DELETE_COLOR_PALETTE_FAILURE',

    // color level
    GET_BRAND_COLORS_REQUEST = 'GET_BRAND_COLORS_REQUEST',
    GET_BRAND_COLORS_SUCCESS = 'GET_BRAND_COLORS_SUCCESS',
    GET_BRAND_COLORS_FAILURE = 'GET_BRAND_COLORS_FAILURE',
    ADD_BRAND_COLOR_REQUEST = 'ADD_BRAND_COLOR_REQUEST',
    ADD_BRAND_COLOR_SUCCESS = 'ADD_BRAND_COLOR_SUCCESS',
    ADD_BRAND_COLOR_FAILURE = 'ADD_BRAND_COLOR_FAILURE',
    EDIT_BRAND_COLOR_REQUEST = 'EDIT_BRAND_COLOR_REQUEST',
    EDIT_BRAND_COLOR_SUCCESS = 'EDIT_BRAND_COLOR_SUCCESS',
    EDIT_BRAND_COLOR_FAILURE = 'EDIT_BRAND_COLOR_FAILURE',
    DELETE_BRAND_COLOR_REQUEST = 'DELETE_BRAND_COLOR_REQUEST',
    DELETE_BRAND_COLOR_SUCCESS = 'DELETE_BRAND_COLOR_SUCCESS',
    DELETE_BRAND_COLOR_FAILURE = 'DELETE_BRAND_COLOR_FAILURE',
    UPDATE_ORDER_BRAND_COLOR_REQUEST = 'UPDATE_ORDER_BRAND_COLOR_REQUEST',
    UPDATE_ORDER_BRAND_COLOR_SUCCESS = 'UPDATE_ORDER_BRAND_COLOR_SUCCESS',
    UPDATE_ORDER_BRAND_COLOR_FAILURE = 'UPDATE_ORDER_BRAND_COLOR_FAILURE',
}
