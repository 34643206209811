import React, { useMemo, useState } from 'react';
import { ExportProvider } from '../variation-export/ExportContext';
import Modal from './Modal';
import AdvancedExportModal from '../variation-export/Modal';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import features from '~/configs/features';

const QuickExportModal = ({ isOpen, onClose }) => {
    const isSimplifiedExportEnabled = useMemo(
        () => AuthorizationHelper.isFeatureAvailable(features.SIMPLIFIED_EXPORT),
        [],
    );

    const [isAdvancedSelection, setIsAdvancedSelection] = useState(false);
    const handleClose = () => {
        onClose();
        setIsAdvancedSelection(false);
    };

    const handleCloseAdvanced = () => {
        setIsAdvancedSelection(false);
    };

    const handleContinue = () => {
        setIsAdvancedSelection(false);
    };

    const switchToAdvancedSelection = () => {
        setIsAdvancedSelection(true);
    };

    return (
        <ExportProvider>
            {isSimplifiedExportEnabled ? (
                isAdvancedSelection ? (
                    <AdvancedExportModal
                        isOpen={isOpen}
                        onClose={handleCloseAdvanced}
                        onContinue={handleContinue}
                        isQuickIntegrationMode
                    />
                ) : (
                    <Modal
                        isOpen={isOpen}
                        onClose={handleClose}
                        onSwitchToAdvancedSelection={switchToAdvancedSelection}
                    />
                )
            ) : (
                <AdvancedExportModal isOpen={isOpen} onClose={onClose} />
            )}
        </ExportProvider>
    );
};

export default QuickExportModal;
