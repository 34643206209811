import React from 'react';
import { Table, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { Creative } from 'packages/store/creatives/types';
import useAccessRights from 'packages/hooks/useAccessRights';
import features from '~/configs/features';
import { Box } from './TitleCell.styled';
import { contentIconMap, getCreativeSharingIcon } from '../../card/utils';

const PROCESSED_STATUSES = ['PROCESSED', 'NOT_FOUND', 'FAILED'];

const DesignTitleCell = ({ design, isProjectDetails }: { design: Creative; isProjectDetails: boolean }) => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const isMultiVariationEnabled = isPlatformAllowed([features.MULTI_VARIATION]);

    const isProcessingDone = PROCESSED_STATUSES.includes(design.thumbnailStatus);

    const imageUrl = () => {
        if (design.thumbnailImage) {
            return design.thumbnailImage;
        }

        return '';
    };

    const getSubText = () => {
        if (isMultiVariationEnabled) {
            return translate('pages.designs.grid.numberOfVariations', { count: design.videosCount });
        }

        return '';
    };

    return (
        <Table.Cell
            thumbnail={
                !isProcessingDone ? (
                    <Thumbnail variant="clean" icon={contentIconMap[design.creativeType]}>
                        <Thumbnail.Overlay isLoading />
                    </Thumbnail>
                ) : (
                    <>
                        {imageUrl() ? (
                            <Thumbnail variant="content" imageUrl={imageUrl()} />
                        ) : (
                            <Thumbnail variant="clean" icon={contentIconMap[design.creativeType]} />
                        )}
                    </>
                )
            }
            subtext={getSubText()}
        >
            <Box>
                <div>{design.name}</div>
                <div>{getCreativeSharingIcon(design, isProjectDetails)}</div>
            </Box>
        </Table.Cell>
    );
};

export default DesignTitleCell;
