import React, { useCallback, useEffect } from 'react';
import { Button, Dropdown } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconAspectRatioOutline, IconResolutions } from '@bynder/icons';
import useExport from '../hooks/useExport';
import { VariationTransformedSetItemType } from '../types';
import useVariations from 'packages/pages/design/hooks/useVariations';

const useSizeSelectList = () => {
    const { pages, pageIds: ids, setPageIds: setIds } = useExport();

    useEffect(() => {
        setIds(pages.map((page) => page.id));
    }, [pages]);

    const select = useCallback((id) => {
        setIds((ids) => {
            if (ids.includes(id)) {
                return ids.filter((id2) => id2 !== id);
            }

            return [...ids, id];
        });
    }, []);

    const toggleSelectAll = useCallback(() => {
        setIds((ids) => {
            if (ids.length === pages.length) {
                return [];
            }

            return pages.map((page) => page.id);
        });
    }, [pages]);

    const isAllSelected = pages.length === ids.length;

    return [pages, ids, select, isAllSelected, toggleSelectAll];
};

export const Pages = () => {
    const [pages, selected, select, isAllSelected, toggleSelectAll] = useSizeSelectList();
    const { selectedIds, variationSets, setSelectedIds, setSelectedCount } = useExport();
    const { variations } = useVariations();
    const { translate } = useTranslate();

    const pagesSet = variations.reduce((acc, variation: VariationTransformedSetItemType) => {
        Object.entries(variation.sizes).forEach(([key, size]) => {
            if (!acc[key]) {
                acc[key] = [size.variationId];
            } else {
                acc[key].push(size.variationId);
            }
        });

        return acc;
    }, {});

    if (pages.length === 1) {
        return null;
    }

    useEffect(() => {
        if (!selectedIds) {
            return;
        }

        const selection = selected.map((id) => pagesSet[id]).flat();

        const filteredIds = Object.entries(selectedIds).reduce((acc, [key, value]) => {
            if (selection.includes(key)) {
                acc[key] = value;
            }
            return acc;
        }, {});

        setSelectedIds(filteredIds);
        setSelectedCount(Object.keys(filteredIds).length);
    }, [selected]);

    return (
        <Dropdown
            trigger={({ isOpen, ...triggerProps }) => (
                <Button variant="clean" isPressed={isOpen} rightIcon={<Dropdown.Arrow />} {...triggerProps}>
                    {translate('modal.export.any-page')}
                </Button>
            )}
        >
            <Dropdown.Item
                checkedVariant="checkbox"
                isChecked={isAllSelected}
                onClick={toggleSelectAll}
                icon={<IconResolutions size="22" />}
            >
                {translate('modal.export.all-pages')}
            </Dropdown.Item>
            <Dropdown.Divider />
            {pages.map((page) => (
                <Dropdown.Item
                    key={page.id}
                    icon={<IconAspectRatioOutline />}
                    checkedVariant="checkbox"
                    isChecked={selected.includes(page.id)}
                    onClick={() => select(page.id)}
                >
                    {page.name}
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
};
