import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Box, Divider, Form, token } from '@bynder/design-system';
import { Color, ColorStop, Gradient, SolidColor } from '../../types';
import { Slider } from './Slider';
import { GradientProvider, useGradient } from './GradientContext';
import { GradientOptions } from './GradientOptions';
import { Saturation } from '../SolidColorPicker/Saturation';
import { HueAndOpacity } from '../SolidColorPicker/HueAndOpacity';
import { ChangeColorType } from '../ChangeColorType';
import { hslColorToSolidColor, solidColorToHslColor } from '../../utils/hsl';
import type { HslaColor, HslColor } from 'react-colorful';

type Props = {
    value: Gradient;
    setValue: (gradient: Color) => void;
    isColorTypeChangeAllowed?: boolean;
};

export function GradientPicker(props: Props) {
    return (
        <GradientProvider value={props.value} onChange={props.setValue}>
            <StopSaturation />
            {props.isColorTypeChangeAllowed ? <ChangeColorType value={props.value} onChange={props.setValue} /> : <></>}
            <Container>
                <Form.Row>
                    <Slider />
                </Form.Row>
                <GradientOptions />
            </Container>
            <Divider />
            <StopColorPicker />
        </GradientProvider>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${token.spacing3};
    padding: 0 ${token.spacing5} ${token.spacing5};
`;

function StopSaturation() {
    const { currentColorValue, dispatch } = useGradient();

    function onChange(newColor: HslColor | HslaColor) {
        dispatch({
            type: 'update-selected-stop',
            payload: { color: hslColorToSolidColor(newColor), lastUpdateSource: 'saturation' },
        });
    }

    return <Saturation value={currentColorValue} onChange={onChange} />;
}

function StopColorPicker() {
    const { currentColorValue, dispatch } = useGradient();

    function onChange(newColor: HslColor | HslaColor) {
        dispatch({
            type: 'update-selected-stop',
            payload: { color: hslColorToSolidColor(newColor), latestHue: newColor.h, lastUpdateSource: 'hue' },
        });
    }

    return (
        <Box paddingTop="4">
            <HueAndOpacity value={currentColorValue} onChange={onChange} />
        </Box>
    );
}
