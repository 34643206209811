import React from 'react';
import { Box, Divider, Tabs } from '@bynder/design-system';
import { IconLinearGradient, IconRadialGradient, IconSolid } from '@bynder/icons';
import { Color, Gradient, LinearGradient, RadialGradient } from '../types';

interface Props {
    value: Color;
    onChange: (newValue: Color) => void;
}

const linearGradient: LinearGradient = {
    type: 'linear',
    angle: 0,
    stops: [
        {
            color: {
                color: '#eeeeee',
                opacity: 100,
            },
            position: 0,
        },
        {
            color: {
                color: '#cccccc',
                opacity: 100,
            },
            position: 100,
        },
    ],
};

const radialGradient: RadialGradient = {
    type: 'radial',
    stops: [
        {
            color: {
                color: '#eeeeee',
                opacity: 100,
            },
            position: 0,
        },
        {
            color: {
                color: '#cccccc',
                opacity: 100,
            },
            position: 100,
        },
    ],
};

const defaultColors = {
    solid: '#000000',
    linear: linearGradient,
    radial: radialGradient,
};

type ColorType = keyof typeof defaultColors;

export function ChangeColorType(props: Props) {
    const type = getColorType(props.value);

    function handleTypeChange(newType: ColorType) {
        if (newType === type) {
            return;
        }

        let color = defaultColors[newType];

        if (typeof color !== 'string') {
            color = structuredClone(color);
        }

        if (isGradient(props.value) && isGradient(color)) {
            color.stops = props.value.stops;
        } else if (isGradient(props.value) && !isGradient(color)) {
            color = props.value.stops[0].color as string;
        } else if (!isGradient(props.value) && isGradient(color)) {
            color.stops[0].color =
                typeof props.value === 'string' ? { color: props.value, opacity: 100 } : props.value.color;
        }

        props.onChange(color);
    }

    return (
        <Box paddingBottom="4">
            <Box paddingInline="4" paddingBottom="4">
                <Tabs>
                    <Tabs.Tab
                        isActive={type === 'solid'}
                        onClick={(e) => {
                            e.preventDefault();
                            handleTypeChange('solid');
                        }}
                        label="Solid"
                        isLabelHidden
                        icon={<IconSolid />}
                    />
                    <Tabs.Tab
                        isActive={type === 'linear'}
                        onClick={(e) => {
                            e.preventDefault();
                            handleTypeChange('linear');
                        }}
                        label="Linear"
                        isLabelHidden
                        icon={<IconLinearGradient />}
                    />
                    {/* <Tabs.Tab
                        isActive={type === 'radial'}
                        onClick={(e) => {
                            e.preventDefault();
                            handleTypeChange('radial');
                        }}
                        label="Radial"
                        isLabelHidden
                        icon={<IconRadialGradient />}
                    /> */}
                </Tabs>
            </Box>
            <Divider />
        </Box>
    );
}

function isGradient(color: Color): color is Gradient {
    return typeof color === 'object' && 'stops' in color;
}

function getColorType(color: Color): ColorType {
    if (typeof color === 'string') {
        return 'solid';
    }

    if ('color' in color) {
        return 'solid';
    }

    if (color.type === 'linear') {
        return 'linear';
    }

    if (color.type === 'radial') {
        return 'radial';
    }

    throw new Error('Invalid color shape');
}
