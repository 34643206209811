import React, { memo, ReactNode } from 'react';
import { GroupElement, ImageElement, TextElement, ShapeElement } from '@bynder-studio/render-web';
import {
    IconBuildShape,
    IconFolder,
    IconFolderMask,
    IconImage,
    IconImageMask,
    IconMask,
    IconMaskAlt3,
    IconTextField,
    IconTextMask,
} from '@bynder/icons';
import { Thumbnail } from '@bynder/design-system';
import useEditor from '~/hooks/useEditor';
import { isUsedAsMask } from '~/common/editor/helpers/elementtree';

type Props = {
    element: TextElement | ImageElement | GroupElement | ShapeElement;
    src?: string;
};

const GenerateIcon = memo(({ element: layerElement, src }: Props) => {
    let icon: ReactNode = null;

    const { creativeModel } = useEditor();
    const isElementMasked = layerElement?.mask !== null;
    const isMask = isUsedAsMask(creativeModel, layerElement);

    if (isMask) {
        icon = <IconMask />;
    } else {
        if (layerElement instanceof TextElement) {
            icon = isElementMasked ? <IconTextMask /> : <IconTextField />;
        }

        if (layerElement instanceof ImageElement) {
            if (isElementMasked) {
                icon = <IconImageMask />;
            } else if (src) {
                icon = <Thumbnail imageUrl={src} variant="content" size="xs" />;
            } else {
                icon = <IconImage />;
            }
        }

        if (layerElement instanceof GroupElement) {
            icon = isElementMasked ? <IconFolderMask /> : <IconFolder />;
        }

        if (layerElement instanceof ShapeElement) {
            icon = isElementMasked ? <IconMaskAlt3 /> : <IconBuildShape />;
        }
    }

    return icon;
});

export default GenerateIcon;
