import styled from 'styled-components';
import { Button, Badge, Dropdown, Flex, InteractiveIcon, token, Tooltip, textStyles, Box } from '@bynder/design-system';

export const VariationDropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

export const ButtonWithIndicator = styled(Button)`
    position: relative;
`;

export const WarningIconIndicator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
`;

export const VariationDropdownItemInner = styled(Dropdown.Item)`
    > span {
        overflow: visible;
    }
`;

export const VariationDropdownItem = styled(Flex)`
    padding: 8px 0;
    & > [class^='Container'] {
        width: 100%;
        & > [class^='ItemWrapper'] {
            width: 100%;
        }
    }
    .variation--dropdown-item-name {
        display: flex;
        flex-direction: column;

        width: calc(252px - 54px);
        padding: 0 16px 0 0;
        margin-left: ${token.spacing4};

        overflow: hidden;

        font-size: 16px;
        line-height: 20px;
        color: #334e5c;
        span:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & > :last-child {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #667a85;
        }
    }
    &:hover {
        cursor: pointer;
        background-color: #f7f8f9;
    }
`;

export const VariationSearchInput = styled.div`
    display: flex;
    width: 100%;
    background-color: #fff;
    margin-block-end: 8px;
    padding: 0 8px 0 16px;
    input,
    [class^='InputWrapper'] {
        border: none;
        padding-inline-start: 0 !important;
        &:hover,
        &:focus {
            outline: 0;
            background-color: transparent;
            border: none;
        }
    }
    .add--variation {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 40px !important;
        flex-shrink: 0;
        border-left: 1px solid rgba(0, 34, 51, 0.05) !important;
        svg {
            width: 34px;
            height: 34px;
        }
    }
`;

const VariationDropdownBtn = styled(Button)`
    max-width: 100%;
    overflow: hidden;
    background-color: transparent !important;
    color: #334e5c !important;
    .selected--variation_name {
        display: block;
        max-width: 100%;

        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        overflow: hidden;
        font-size: 18px;
        line-height: 24px;
    }

    &:hover,
    &:active,
    &[aria-expanded='true'] {
        background-color: #f7f8f9 !important;
    }
`;

export const PreviewSearchIcon = styled(InteractiveIcon)`
    svg {
        width: 24px;
        height: 24px;
    }
`;

export const PreviewVariationsDropdownBtn = styled(VariationDropdownBtn)`
    position: relative;

    display: block !important;
    height: auto !important;
    padding: 5px 35px 5px 16px !important;

    line-height: normal !important;

    svg {
        position: absolute;
        top: 50%;
        right: 5px;
        fill: rgba(0, 34, 51, 0.4);

        transform: translateY(-50%);
    }
`;

const longNameBlockStyles = `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const PreviewDetailsBox = styled(Box)`
    flex: 1;
    overflow: hidden;
    margin-left: ${token.spacing4};
    margin-right: ${token.spacing4};
`;

export const PreviewDetails = styled(Flex)`
    height: auto;
    align-items: center;
`;

export const PreviewTitle = styled.div`
    ${textStyles.uiTextL};
    ${longNameBlockStyles};

    flex-grow: 0;
    flex-shrink: 1;

    margin-right: ${token.spacing3};
`;

export const PreviewBadge = styled.div`
    margin-right: ${token.spacing3};
    text-wrap: nowrap;
`;

export const PreviewDropdown = styled(Dropdown)`
    ${longNameBlockStyles};

    flex-grow: 0;
    flex-shrink: 1;
    min-width: 150px;

    margin-left: ${token.spacing2};
`;

export const PreviewBtnsContainer = styled(Flex)`
    width: auto;
    margin-left: auto;
    padding-top: 3px; // align with the title
    justify-content: flex-end;
    gap: ${token.spacing5};
`;

export const PreviewHeaderWrapper = styled.div`
    display: flex;

    padding: 21px ${token.spacing6};
    width: calc(100vw - 360px);
    max-width: calc(100vw - 360px);
    min-width: 864px;
`;

export const GoBackButton = styled(Button)`
    align-self: center;
`;

export const StyledBadge = styled(Badge)`
    pointer-events: all;
    cursor: pointer;
    line-height: 1.5;
`;

export const StyledTooltip = styled(Tooltip)`
    padding: 0;
    border-radius: 5px;
    min-width: 360px;
`;

export const CommentContainer = styled(Flex)`
    padding: ${token.spacing3} ${token.spacing5} ${token.spacing3} ${token.spacing5};
    margin: ${token.spacing2} 0;
`;

export const ActionTime = styled.span`
    ${textStyles.uiTextS};
    color: ${token.gray600};
`;

export const CommentText = styled.p`
    ${textStyles.paragraphM};
    padding: ${token.spacing2} 0;
    margin: 0;
`;
