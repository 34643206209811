import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import { calculateBoxCrop } from '../../Helpers/calculateBoxCrop';
import type { IAsset } from '../Assets/IAsset';
import { VideoAsset } from '../Assets/VideoAsset';
import { VideoCompElement } from '../CompModels/Elements/VideoCompElement';
import { Box } from '../Shared/Box';
import { ContentTransform } from '../Shared/ContentTransform';
import { Dimension } from '../Shared/Dimension';
import { BaseVisualElement } from './BaseVisualElement';
import { TimelineBehavior } from '../../Enums/TimelineBehavior';
import { VirtualData } from '../Shared/VirtualData';
import { type CreativeTypes } from '../../Enums/CreativeTypes';
import { PreviewTypes } from '../../Enums/PreviewTypes';
import { type ElementUpdateOptions, type VideoElementParams } from '../../types';

export class VideoElement extends BaseVisualElement {
    src: string | null | undefined = null;

    srcId: string | null | undefined = null;

    srcType: string | null | undefined = null;

    fileName: string | null = null;

    offsetTime = 0;

    useAudio = false;

    gain = 0;

    fadeIn = 0;

    fadeOut = 0;

    isAlpha = false;

    useDynamicLength = false;

    timelineBehavior: TimelineBehavior = TimelineBehavior.AUTO;

    contentTransform!: ContentTransform;

    naturalDimension!: Dimension;

    cropData: any;

    virtualData: VirtualData = { bynderCollectionId: null, allowPersonalUpload: true };

    constructor(params: Partial<VideoElementParams>) {
        super();
        this.setProperties(params);
    }

    setProperties(params: Partial<VideoElementParams>): Set<ElementUpdateTypes> {
        const updateTypes: Set<ElementUpdateTypes> = super.setProperties(params);

        if (params.src !== undefined) {
            this.src = params.src;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.srcId !== undefined) {
            this.srcId = params.srcId;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.srcType !== undefined) {
            this.srcType = params.srcType;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.fileName !== undefined) {
            this.fileName = params.fileName;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.offsetTime !== undefined) {
            this.offsetTime = params.offsetTime;
            updateTypes.add(ElementUpdateTypes.SOURCE); // related to source
        }

        if (params.isAlpha !== undefined) {
            this.isAlpha = params.isAlpha;
            updateTypes.add(ElementUpdateTypes.SOURCE); // related to source
        }

        if (params.useAudio !== undefined) {
            this.useAudio = params.useAudio;
            updateTypes.add(ElementUpdateTypes.SOURCE); // related to source
        }

        if (params.gain !== undefined) {
            this.gain = params.gain;
            updateTypes.add(ElementUpdateTypes.AUDIO_GAIN);
        }

        if (params.fadeIn !== undefined) {
            this.fadeIn = params.fadeIn;
            updateTypes.add(ElementUpdateTypes.AUDIO_FADE_IN);
        }

        if (params.fadeOut !== undefined) {
            this.fadeOut = params.fadeOut;
            updateTypes.add(ElementUpdateTypes.AUDIO_FADE_OUT);
        }

        if (params.useDynamicLength !== undefined && this.useDynamicLength !== !!(params.useDynamicLength as any)) {
            this.useDynamicLength = !!(params.useDynamicLength as any);
            updateTypes.add(ElementUpdateTypes.DYNAMIC_LENGTH);
        }

        if (
            params.timelineBehavior !== undefined &&
            this.timelineBehavior !== params.timelineBehavior &&
            Object.values(TimelineBehavior).includes(params.timelineBehavior)
        ) {
            this.timelineBehavior = params.timelineBehavior || TimelineBehavior.AUTO;
            updateTypes.add(ElementUpdateTypes.TIMELINE_BEHAVIOR);
        }

        if (params.contentTransform !== undefined && params.contentTransform !== null) {
            this.contentTransform = new ContentTransform(params.contentTransform);
            updateTypes.add(ElementUpdateTypes.CONTENT_TRANSFORM);
        }

        if (params.naturalDimension !== undefined && params.naturalDimension !== null) {
            this.naturalDimension = new Dimension(params.naturalDimension.width, params.naturalDimension.height);
            updateTypes.add(ElementUpdateTypes.NATURAL_DIMENSION);
        }

        if (this.naturalDimension && this.dimension) {
            // Calculate content position relative to box
            this.cropData = calculateBoxCrop(this.contentTransform, this.dimension, this.naturalDimension);
        }

        if (params.virtualData !== undefined) {
            this.virtualData = params.virtualData;
        }

        return updateTypes;
    }

    update(params: Partial<VideoElementParams>, options?: ElementUpdateOptions): Set<ElementUpdateTypes> {
        const { frameRate = 25 } = options || {};
        const oldSrcId = this.srcId;
        const oldOffsetTime = this.offsetTime;
        const oldUseAudio = this.useAudio;
        const oldDuration = this.duration;
        const oldStartFrame = this.startFrame;
        const updateTypes: Set<ElementUpdateTypes> = this.setProperties(params);

        if (
            this.srcId !== oldSrcId ||
            this.offsetTime !== oldOffsetTime ||
            this.useAudio !== oldUseAudio ||
            this.duration !== oldDuration ||
            this.startFrame !== oldStartFrame
        ) {
            this.constructAsset(frameRate);
        }

        return updateTypes;
    }

    getCompElement(frameIndex: number) {
        if (!this.position || !this.cropData) {
            return null;
        }

        const compEl = new VideoCompElement();
        compEl.originalElement = this;
        compEl.id = this.id;
        compEl.assetId = this.generateAssetId();
        compEl.hidden = this.hidden;
        compEl.renderOrder = this.renderOrder;
        compEl.opacity = this.opacity;
        compEl.rotation = this.rotation;
        compEl.scale = this.scale;
        compEl.dropShadow = this.dropShadow;
        compEl.mask = this.mask;
        compEl.blendMode = this.blendMode;
        compEl.offsetTime = this.offsetTime;
        compEl.offsetTimeFrame = this.offsetTime + (frameIndex - this.startFrame) / 25;
        compEl.useAudio = this.useAudio;
        compEl.isAlpha = this.isAlpha;
        compEl.isAssetLoading = this.isAssetLoading;
        compEl.boundingBox = new Box(this.position.getCopy(), this.dimension.getCopy());
        compEl.contentBox = new Box(this.cropData.position, this.cropData.dimension);
        // take the cropped position and dimension
        compEl.cropPositionPct = this.cropData.cropPositionPct;
        compEl.cropDimensionPct = this.cropData.cropDimensionPct;
        compEl.frameIndex = frameIndex;

        return this.applyAnimations(frameIndex, compEl);
    }

    constructAsset(frameRate = 25): void {
        if (!this.src) {
            return;
        }

        const asset = new VideoAsset({
            id: this.generateAssetId(),
            src: this.src,
            isAlpha: this.isAlpha,
            useAudio: this.useAudio,
            startFrame: this.startFrame,
            offsetTime: this.offsetTime,
            duration: this.duration,
            frameRate,
            naturalDimension: this.naturalDimension.getCopy(),
        });
        this.isAssetLoading = true;
        this.assetLoader!.setAsset(asset);
        this.assetLoader!.loadVideo(this.assetLoader!.getAsset(asset.id) as VideoAsset)
            .catch((err) => {
                console.warn('Video asset loading error. Url:', this.src, err);
            })
            .finally(() => {
                this.isAssetLoading = false;
            });
    }

    isContainsAsset(asset: IAsset): boolean {
        return asset instanceof VideoAsset && asset.id === this.generateAssetId() && asset.src === this.src;
    }

    generateAssetId(): string {
        return `${this.srcId}-${this.startFrame}-${this.offsetTime}-${this.duration}`;
    }

    getValidationRules(creativeType: CreativeTypes, previewType: PreviewTypes) {
        const rules = super.getValidationRules(creativeType, previewType);

        if (previewType === PreviewTypes.CONTENT) {
            return rules;
        }

        return {
            ...rules,
            src: {
                REQUIRED: true,
            },
        };
    }

    getGainValue(): number {
        return Math.pow(10, this.gain / 20);
    }

    getFadeScale(time: number, playbackDuration: number, frameRate: number): number {
        const duration = (this.duration - Math.max(0, this.startFrame + this.duration - playbackDuration)) / frameRate;
        const startAt = this.startFrame / frameRate;

        if (time < startAt || time >= startAt + duration) {
            return 0;
        }

        const fadeInScale = time >= startAt + this.fadeIn ? 1 : (time - startAt) / this.fadeIn;
        const fadeOutScale = time <= startAt + duration - this.fadeOut ? 1 : (startAt + duration - time) / this.fadeOut;

        return fadeInScale * fadeOutScale;
    }

    toObject(): VideoElementParams {
        const baseObject = super.toObject();

        return {
            ...baseObject,
            src: this.src,
            srcId: this.srcId,
            srcType: this.srcType,
            fileName: this.fileName,
            offsetTime: this.offsetTime,
            useAudio: this.useAudio,
            gain: this.gain,
            fadeIn: this.fadeIn,
            fadeOut: this.fadeOut,
            isAlpha: this.isAlpha,
            useDynamicLength: this.useDynamicLength,
            timelineBehavior: this.timelineBehavior,
            contentTransform: this.contentTransform?.toObject() ?? null,
            naturalDimension: this.naturalDimension?.toObject() ?? null,
            virtualData: this.virtualData,
        };
    }
}
