import { RefObject } from 'react';

export function fromPxToPercents(wrapperRef: RefObject<HTMLDivElement>, px): number {
    const width = wrapperRef?.current?.getBoundingClientRect()?.width;

    if (!width) {
        return 0;
    }

    return (100 / width) * px;
}

export function fromPercentsToPx(wrapperRef: RefObject<HTMLDivElement>, percents): number {
    const width = wrapperRef?.current?.getBoundingClientRect()?.width;

    if (!width) {
        return 0;
    }

    return (width / 100) * percents;
}

const placeholderWidth = 10; // px

function getShotIndexAndPrevPositionByPosX(posX: number, shotsRef: any): [number, number] {
    const elements = shotsRef.current;
    let start = placeholderWidth;
    let i = 0;

    for (; i < elements.length; ++i) {
        const { clientWidth } = elements[i];

        if (posX <= start + clientWidth) {
            break;
        }

        start += clientWidth;
    }

    return [i, start];
}

const getShotIndexAndPrevDurationByFrame = (frame: number, shots: any): [number, number] => {
    let start = 0;
    let i = 0;

    for (; i < shots.length; ++i) {
        const { duration } = shots[i];

        if (frame >= start && frame < start + duration) {
            break;
        }

        start += duration;
    }

    return [i, start];
};

export const getFrameIndexByPosX = (posX: number, shots: any, shotsRef: any): number => {
    const [index, prevPosition] = getShotIndexAndPrevPositionByPosX(posX, shotsRef);
    const start = shots.slice(0, index).reduce((acc, shot) => acc + shot.duration, 0);

    if (!shotsRef.current[index]) {
        return -1;
    }

    const width = shotsRef.current[index].clientWidth;
    const pos = Math.max(0.001, posX - prevPosition);
    const shot = shots[index];

    return start + Math.ceil((shot.duration * pos) / width) - 1;
};

export const getPositionByFrame = (frame: number, shots: any, shotsRef: any): number => {
    const [index, prevDuration] = getShotIndexAndPrevDurationByFrame(frame, shots);
    const start = shotsRef.current.slice(0, index).reduce((acc, el) => acc + el.clientWidth, 0);
    const width = shotsRef.current[index].clientWidth;
    const duration = Math.max(0, frame - prevDuration);
    const shot = shots[index];

    return start + Math.ceil((width * duration) / Math.max(1, shot.duration - 1));
};
