import React, { Fragment, useCallback, useMemo, useState } from 'react';
import type { ColorParams } from '@bynder-studio/render-core';
import { equals } from 'rambda';
import { Divider, Dropdown } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconPlaylistAdd } from '@bynder/icons';
import { BrandColor, BrandColorPalette } from 'packages/store/brandColors/types';
// eslint-disable-next-line import/no-cycle
import { BrandColorOnElement } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor';
import debounce from '~/helpers/debounce';
import generateTestId from '~/helpers/testIdHelpers';
import { ColorItem } from './ColorItem';

type ColorSearchProps = {
    palettes: BrandColorPalette[];
    selectedBrandColors: BrandColor[] | [];
    selectedCustomColors?: ColorParams[] | [];
    setBrandColor: (color: BrandColor) => void;
    testId: string;
    elementColors?: ColorParams[] | [];
    onAppliedClick?: (color: BrandColorOnElement | ColorParams) => void;
    openElementStylesModal?: () => void;
    configureColorsButton?: React.ReactElement | null;
};

const MIN_CHARACTERS_SEARCH = 1;

const ColorSearch = ({
    palettes,
    selectedBrandColors,
    selectedCustomColors = [],
    setBrandColor,
    elementColors = [],
    onAppliedClick = () => {},
    testId,
    openElementStylesModal,
    configureColorsButton = null,
}: ColorSearchProps) => {
    const { translate } = useTranslate();

    const [searchInputValue, setSearchInputValue] = useState('');
    const [delayedSearchValue, setDelayedSearchValue] = useState('');

    const idToBrandColor: { [brandColorId: number]: BrandColor } = useMemo(() => {
        const byId: { [brandColorId: number]: BrandColor } = {};
        palettes.forEach((palette) => {
            palette.brandColors.forEach((color) => {
                byId[color.id] = color;
            });
        });

        return byId;
    }, [palettes]);

    const onSearchWithDelay = debounce((keyword: string) => {
        if (!keyword.length || keyword.length <= MIN_CHARACTERS_SEARCH) {
            setDelayedSearchValue('');
        } else {
            setDelayedSearchValue(keyword);
        }
    }, 1000);

    const filterPalettes = useMemo(
        () =>
            delayedSearchValue.length > MIN_CHARACTERS_SEARCH
                ? palettes.reduce((acc, palette) => {
                      if (!palette.brandColors.length) {
                          return acc;
                      }

                      if (palette.name.toLowerCase().includes(delayedSearchValue.toLowerCase())) {
                          acc.push(palette);

                          return acc;
                      }

                      const paletteFilteredBrandColors = palette.brandColors.filter((color) =>
                          color.name.toLowerCase().includes(delayedSearchValue.toLowerCase()),
                      );

                      if (paletteFilteredBrandColors.length) {
                          acc.push({ ...palette, brandColors: paletteFilteredBrandColors });

                          return acc;
                      }

                      return acc;
                  }, [] as BrandColorPalette[])
                : palettes,
        [delayedSearchValue],
    );

    const isChecked = useCallback<(item: BrandColor | BrandColorOnElement | ColorParams) => boolean>(
        (item) => {
            const id = (item as BrandColor).id || (item as ColorParams).brandColorId;

            if (id) {
                return selectedBrandColors.some((color) => color.id === id);
            }

            return selectedCustomColors.some((color) => equals(color, item));
        },
        [selectedBrandColors, selectedCustomColors],
    );

    const testIdProp = useMemo(() => generateTestId(`${testId}_choose_from_palette_search`), [testId]);

    return (
        <>
            <Dropdown.SearchInput
                value={searchInputValue}
                onChange={(value) => {
                    setSearchInputValue(value);
                    onSearchWithDelay(value);
                }}
                placeholder={translate('editor.sidebar.shots.color.search')}
                aria-label={translate('editor.sidebar.shots.color.search.label')}
                {...testIdProp}
            />
            {!!elementColors.length && searchInputValue === '' && (
                <>
                    <Dropdown.SectionTitle>
                        {translate('editor.sidebar.shots.color.search.element.styles')}
                    </Dropdown.SectionTitle>
                    {elementColors.map((color, i) => (
                        <ColorItem
                            key={`${color.id}-${i}`}
                            item={idToBrandColor[color.brandColorId] || color}
                            onClick={onAppliedClick}
                            selected={isChecked(color)}
                        />
                    ))}
                    {openElementStylesModal && (
                        <Dropdown.Item icon={<IconPlaylistAdd />} onClick={openElementStylesModal}>
                            {translate('editor.sidebar.shots.text.textStyle.configure-element-styles')}
                        </Dropdown.Item>
                    )}
                </>
            )}
            {searchInputValue === '' && configureColorsButton}
            {searchInputValue === '' && (configureColorsButton || !!elementColors.length) && <Divider />}
            {filterPalettes.map((palette, index) => (
                <Fragment key={index}>
                    <Dropdown.SectionTitle>{palette.name}</Dropdown.SectionTitle>
                    {palette.brandColors.map((color, i) => (
                        <ColorItem
                            key={`${color.id}-${i}-${index}`}
                            item={color}
                            onClick={setBrandColor}
                            selected={isChecked(color)}
                        />
                    ))}
                    {index < filterPalettes.length - 1 && <Dropdown.Divider />}
                </Fragment>
            ))}
        </>
    );
};

export default ColorSearch;
