import React from 'react';
import { Translate } from '@bynder/localization';
import { Table } from '@bynder/design-system';
import roles from '~/configs/roles';
import useAccessRights from 'packages/hooks/useAccessRights';
import OwnerNameCell from 'packages/pages/components/list/cell/OwnerNameCell';
import DateCreatedCell from 'packages/pages/components/list/cell/DateCell';
import DurationCell from 'packages/pages/components/list/cell/DurationCell';
import TemplateTitleCell from 'packages/pages/components/list/cell/TemplateTitleCell';
import {
    HiddenActions,
    MenuActions as TemplateMenuActions,
} from 'packages/pages/components/templates/TemplateContextMenu/types';
import TemplateContextMenu from 'packages/pages/components/templates/TemplateContextMenu';
import { OrderFilterType, SortingFilterType } from 'packages/pages/components/filters/components/Ordering/types';
import { Template } from 'packages/store/templates/types';
import { PreviewActionButton } from 'packages/pages/components/templates/TemplateCard/PreviewActionButton';
import { ListItemProps } from './types';

export const headers = [
    { id: 'name', title: <Translate id="listview.template.column.name" /> },
    { id: 'date_created', title: <Translate id="listview.template.column.date_created" /> },
    { id: 'date_updated', title: <Translate id="listview.template.column.last_modified" /> },
    { id: 'ownership', title: <Translate id="listview.template.column.ownership" />, notSortable: true },
    { id: 'duration', title: <Translate id="listview.template.column.duration" /> },
    { id: 'menu', title: '', notSortable: true },
];
export const headersWithSelection = [{ id: 'checkboxes', title: '', notSortable: true }, ...headers];
export const columnWidths = ['40%', '20%', '20%', '20%', 'auto'];
export const columnWidthsWithSelection = ['auto', '40%', '20%', '20%', '20%', 'auto'];

const ListViewItem = ({
    template,
    sort,
    menuActions,
    includeSharedIndicator,
    handleClick,
    hiddenMenuActions,
    onContextMenuTriggerClick,
    onSelect,
    isSelected,
}: ListItemProps) => {
    const { isAccountRoleAssigned } = useAccessRights();
    const [sortBy, sortingOrder] = sort;

    const hasTemplateThumbnail = !!template.thumbnailImage;

    return (
        <Table.Row
            onClick={() => handleClick(template)}
            isSelected={isSelected ? isSelected(template) : undefined}
            onSelect={onSelect ? () => onSelect(template) : undefined}
        >
            <TemplateTitleCell template={template} isCategoryDetails includeSharedIndicator={includeSharedIndicator} />
            {sortBy !== 'date_updated' ? (
                <DateCreatedCell date={template.created} />
            ) : (
                <DateCreatedCell date={template.updated} />
            )}
            <OwnerNameCell item={template} />
            <DurationCell creativeTypeEntity={template} />
            <Table.Cell textFlow="no-wrap">
                <Table.StopPropagation>
                    {isAccountRoleAssigned(roles.video_brand_studio.video.manage) ? (
                        <PreviewActionButton
                            template={template}
                            onClick={menuActions.showPreview}
                            isDisabled={!hasTemplateThumbnail}
                        />
                    ) : (
                        <TemplateContextMenu
                            template={template}
                            menuActions={menuActions}
                            isPreviewDisabled={!hasTemplateThumbnail}
                            hiddenActions={hiddenMenuActions}
                            onContextMenuTriggerClick={onContextMenuTriggerClick}
                        />
                    )}
                </Table.StopPropagation>
            </Table.Cell>
        </Table.Row>
    );
};

export const renderItems = ({
    templates,
    sort,
    menuActions,
    includeSharedIndicator,
    handleClick,
    hiddenMenuActions,
    onContextMenuTriggerClick,
    onSelect,
    isSelected,
}: {
    templates: Template[];
    sort: [SortingFilterType, OrderFilterType];
    menuActions: TemplateMenuActions;
    includeSharedIndicator: boolean;
    handleClick: (template: Template) => void;
    hiddenMenuActions?: HiddenActions;
    onContextMenuTriggerClick?: () => void;
    onSelect?: (template: Template) => void;
    isSelected?: (template: Template) => boolean;
}) =>
    templates.map((template) => (
        <ListViewItem
            key={template.id}
            template={template}
            sort={sort}
            menuActions={menuActions}
            includeSharedIndicator={includeSharedIndicator}
            handleClick={handleClick}
            hiddenMenuActions={hiddenMenuActions}
            onContextMenuTriggerClick={onContextMenuTriggerClick}
            onSelect={onSelect}
            isSelected={isSelected}
        />
    ));
