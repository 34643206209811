import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import useEditor from 'packages/web/src/hooks/useEditor';
import { framesToTime } from '~/helpers/helpers';
import { computeOverflow } from '../helper';
import {
    TimelineTimestamp,
    TimelineTimestampMark,
    TimelineTimestamps,
    TimelineTimestampSpace,
} from './Timestamps.styled';

const minSlotWidth = 80;

const useSpacing = (containerRef: RefObject<HTMLElement>) => {
    const lastStampCountRef = useRef<number>();
    const [stampCount, setStampCount] = useState(4);

    const checkWidth = useCallback(() => {
        if (!lastStampCountRef.current || !containerRef.current) {
            return;
        }

        const boundingBox = containerRef.current.getBoundingClientRect();
        const stamps = (boundingBox.width / minSlotWidth) << 0;

        const stampsToIncrease = stampCount * 2 + 1;
        const stampsToDecrease = (stampCount - 1) / 2;

        if (stamps >= stampsToIncrease) {
            if (lastStampCountRef.current !== stampsToIncrease) {
                setStampCount(stampsToIncrease);
                lastStampCountRef.current = stampsToIncrease;
            }
        } else if (stamps < lastStampCountRef.current) {
            if (stampCount !== stampsToDecrease) {
                setStampCount(stampsToDecrease);
                lastStampCountRef.current = stampsToDecrease;
            }
        }
    }, [stampCount]);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        checkWidth();
        const mutationObserver = new MutationObserver(() => {
            checkWidth();
        });

        mutationObserver.observe(containerRef.current, {
            attributes: true,
            attributeFilter: ['style'],
        });

        return () => mutationObserver.disconnect();
    }, [stampCount]);

    return stampCount;
};

const useTimestamps = (
    duration: number,
    stampCount: number,
    elementContainerRef: RefObject<HTMLElement>,
): [string[], number] => {
    const [overflowPercentage, setOverflowPercentage] = useState(0);

    useEffect(() => {
        const [, overflowRaw] = computeOverflow(elementContainerRef);
        setOverflowPercentage(overflowRaw);
    });

    const blockDuration = duration / (stampCount + 1);
    const realDuration = blockDuration + blockDuration * (overflowPercentage / 100);
    const timeStampsDuration = realDuration * (stampCount + 1);
    const timestamps: string[] = Array(stampCount)
        .fill(null)
        .map((_, idx) => {
            const startFrame = Math.round((idx + 0.5) * realDuration + 0.5 * realDuration);
            return framesToTime(startFrame);
        });

    return [timestamps, timeStampsDuration];
};

export const Timestamps = ({
    duration,
    timestampsContainerRef,
    elementContainerRef,
}: {
    duration: number;
    timestampsContainerRef: RefObject<HTMLDivElement>;
    elementContainerRef: RefObject<HTMLElement>;
}) => {
    const stampCount = useSpacing(timestampsContainerRef);
    const [timestamps, timeStampsDuration] = useTimestamps(duration, stampCount, elementContainerRef);
    const { manipulationRenderer } = useEditor();

    const handleClick = useCallback(
        (e) => {
            if (!timestampsContainerRef.current) {
                return;
            }

            const mouseX = e.clientX;
            const { x: timeStampX, width: timeStampWidth } = timestampsContainerRef.current.getBoundingClientRect();
            const coeficent = (mouseX - timeStampX) / timeStampWidth;
            const frame = Math.floor(Number(timeStampsDuration) * coeficent);

            if (manipulationRenderer) {
                manipulationRenderer.setCurrentFrame(frame);
            }
        },
        [timestampsContainerRef, timeStampsDuration, manipulationRenderer],
    );

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        if (timeStampsDuration < manipulationRenderer.getCurrentFrame()) {
            manipulationRenderer.setCurrentFrame(manipulationRenderer.getPlaybackDuration().getDuration());
        }
    }, [timeStampsDuration]);

    return (
        <TimelineTimestamps onMouseDown={handleClick} ref={timestampsContainerRef}>
            <TimelineTimestampSpace />
            {timestamps.map((time, i) => (
                <TimelineTimestamp key={i}>
                    <span>{time}</span>
                    <TimelineTimestampMark />
                </TimelineTimestamp>
            ))}
            <TimelineTimestampSpace />
        </TimelineTimestamps>
    );
};
