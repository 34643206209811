import React from 'react';
import { Thumbnail } from '@bynder/design-system';
import { Color } from 'packages/ds/ColorPicker/types';
import { hexToRgb } from '~/common/editor/editorHelper';
import { isGradientColor } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import { GradientThumbnail } from 'packages/ds/GradientThumbnail/GradientThumbnail';

type Props = {
    color: Color;
    shape: 'square' | 'circle';
    size?: 'xs' | 's';
};

export function ColorThumbnail({ color, shape, size }: Props) {
    if (isGradientColor(color)) {
        return <GradientThumbnail {...color} size={size} shape={shape} />;
    }

    const bg = typeof color === 'string' ? hexToRgb(color, 1) : hexToRgb(color.color, color.opacity);

    return <Thumbnail variant="content" backgroundColor={bg} size={size} shape={shape} />;
}
