import React from 'react';
import { Color, Gradient } from '../types';
import { GradientPicker } from './GradientPicker/GradientPicker';
import { SolidColorPicker } from './SolidColorPicker/SolidPicker';

interface Props {
    value: Color;
    onChange: (newValue: Color) => void;
    isColorTypeChangeAllowed?: boolean;
}

export function InternalColorPicker(props: Props) {
    if (isGradient(props.value)) {
        return (
            <GradientPicker
                value={props.value}
                setValue={props.onChange}
                isColorTypeChangeAllowed={props.isColorTypeChangeAllowed}
            />
        );
    }

    return (
        <SolidColorPicker
            value={props.value}
            onChange={props.onChange}
            isColorTypeChangeAllowed={props.isColorTypeChangeAllowed}
        />
    );
}

function isGradient(color: Color): color is Gradient {
    return typeof color === 'object' && 'type' in color && ['linear', 'radial'].includes(color.type);
}
