import React from 'react';
import { getCreativeSharingIcon } from 'packages/pages/components/card/utils';
import { IdentityRole } from 'packages/store/sharing/types';
import TemplateCardBase, { type TemplateCardBaseProps } from './TemplateCardBase';
import { HiddenActions, MenuActions } from '../TemplateContextMenu/types';
import useAccessRights from 'packages/hooks/useAccessRights';
import roles from '~/configs/roles';
import TemplateContextMenu from '../TemplateContextMenu';
import { PreviewActionButton } from './PreviewActionButton';

export type TemplateCardProps = Omit<
    TemplateCardBaseProps,
    'testId' | 'selectButtonAriaLabel' | 'contextAction' | 'headerIcon'
> & {
    menuActions: MenuActions;
    hiddenMenuActions?: HiddenActions;
    includeSharedIndicator?: boolean;
    onContextMenuTriggerClick?: () => void;
};

export const TemplateCard = ({
    template,
    menuActions,
    hiddenMenuActions = [],
    isSelected,
    onSelect,
    onClick,
    includeSharedIndicator,
    onContextMenuTriggerClick,
}: TemplateCardProps) => {
    const { isAccountRoleAssigned } = useAccessRights();
    const hasTemplateThumbnail = !!template.thumbnailImage;
    const headerIcon = includeSharedIndicator && getCreativeSharingIcon(template, true, IdentityRole.CAN_USE);

    return (
        <TemplateCardBase
            template={template}
            isSelected={isSelected}
            onSelect={onSelect}
            onClick={onClick}
            selectButtonAriaLabel={`Select template ${template.name}`}
            testId="template_card"
            contextAction={
                isAccountRoleAssigned(roles.video_brand_studio.video.manage) ? (
                    <PreviewActionButton
                        template={template}
                        onClick={menuActions.showPreview}
                        isDisabled={!hasTemplateThumbnail}
                    />
                ) : (
                    <TemplateContextMenu
                        template={template}
                        menuActions={menuActions}
                        isPreviewDisabled={!hasTemplateThumbnail}
                        hiddenActions={hiddenMenuActions}
                        onContextMenuTriggerClick={onContextMenuTriggerClick}
                        isCardAction={true}
                    />
                )
            }
            headerIcon={headerIcon}
        />
    );
};
