import { useSelector } from 'react-redux';
import { getUser } from 'packages/store/user/user.selectors';
import { EntityGrantedPermissions, IdentityType } from 'packages/store/sharing/types';
import AuthenticationHelper from '~/helpers/AuthenticationHelper';
import roles from '~/configs/roles';
import {
    ActionStatus,
    DesignAction,
    ProjectAction,
    Entity,
    IdentityRole,
    TemplateAction,
    EntityLevelActions,
    CategoryAction,
} from './types';

const entityPermissions = {
    [Entity.PROJECT]: {
        [roles.video_brand_studio.handler.admin]: {
            [IdentityRole.OWNER]: {
                [ProjectAction.LIST]: ActionStatus.ALLOWED,
                [ProjectAction.VIEW]: ActionStatus.ALLOWED,
                [ProjectAction.RENAME]: ActionStatus.ALLOWED,
                [ProjectAction.DELETE]: ActionStatus.ALLOWED,
                [ProjectAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [ProjectAction.LIST]: ActionStatus.ALLOWED,
                [ProjectAction.VIEW]: ActionStatus.ALLOWED,
                [ProjectAction.RENAME]: ActionStatus.ALLOWED,
                [ProjectAction.DELETE]: ActionStatus.DISABLED,
                [ProjectAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [ProjectAction.LIST]: ActionStatus.ALLOWED,
                [ProjectAction.VIEW]: ActionStatus.ALLOWED,
                [ProjectAction.RENAME]: ActionStatus.DISABLED,
                [ProjectAction.DELETE]: ActionStatus.DISABLED,
                [ProjectAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
            },
        },
        [roles.video_brand_studio.template.manage]: {
            [IdentityRole.OWNER]: {
                [ProjectAction.LIST]: ActionStatus.ALLOWED,
                [ProjectAction.VIEW]: ActionStatus.ALLOWED,
                [ProjectAction.RENAME]: ActionStatus.ALLOWED,
                [ProjectAction.DELETE]: ActionStatus.ALLOWED,
                [ProjectAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [ProjectAction.LIST]: ActionStatus.ALLOWED,
                [ProjectAction.VIEW]: ActionStatus.ALLOWED,
                [ProjectAction.RENAME]: ActionStatus.ALLOWED,
                [ProjectAction.DELETE]: ActionStatus.DISABLED,
                [ProjectAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [ProjectAction.LIST]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.VIEW]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
            },
        },
        [roles.video_brand_studio.video.manage]: {
            [IdentityRole.OWNER]: {
                [ProjectAction.LIST]: ActionStatus.ALLOWED,
                [ProjectAction.VIEW]: ActionStatus.ALLOWED,
                [ProjectAction.RENAME]: ActionStatus.ALLOWED,
                [ProjectAction.DELETE]: ActionStatus.ALLOWED,
                [ProjectAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [ProjectAction.LIST]: ActionStatus.ALLOWED,
                [ProjectAction.VIEW]: ActionStatus.ALLOWED,
                [ProjectAction.RENAME]: ActionStatus.ALLOWED,
                [ProjectAction.DELETE]: ActionStatus.DISABLED,
                [ProjectAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [ProjectAction.UPDATE_PERMISSIONS]: ActionStatus.DISABLED,
                [ProjectAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [ProjectAction.LIST]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.VIEW]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [ProjectAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
            },
        },
    },
    [Entity.DESIGN]: {
        [roles.video_brand_studio.handler.admin]: {
            [IdentityRole.OWNER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.ALLOWED,
                [DesignAction.MOVE]: ActionStatus.ALLOWED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.ALLOWED,
                [DesignAction.DELETE]: ActionStatus.ALLOWED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.OWNER_REVIEWER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.ALLOWED,
                [DesignAction.MOVE]: ActionStatus.ALLOWED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.ALLOWED,
                [DesignAction.DELETE]: ActionStatus.ALLOWED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.ALLOWED,
                [DesignAction.MOVE]: ActionStatus.DISABLED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.ALLOWED,
                [DesignAction.DELETE]: ActionStatus.DISABLED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR_REVIEWER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.ALLOWED,
                [DesignAction.MOVE]: ActionStatus.DISABLED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.ALLOWED,
                [DesignAction.DELETE]: ActionStatus.DISABLED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.REVIEWER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.ALLOWED,
                [DesignAction.MOVE]: ActionStatus.DISABLED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.ALLOWED,
                [DesignAction.DELETE]: ActionStatus.DISABLED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.ALLOWED,
                [DesignAction.MOVE]: ActionStatus.DISABLED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.ALLOWED,
                [DesignAction.DELETE]: ActionStatus.DISABLED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
        },
        [roles.video_brand_studio.template.manage]: {
            [IdentityRole.OWNER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.ALLOWED,
                [DesignAction.MOVE]: ActionStatus.ALLOWED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.ALLOWED,
                [DesignAction.DELETE]: ActionStatus.ALLOWED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.OWNER_REVIEWER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.ALLOWED,
                [DesignAction.MOVE]: ActionStatus.ALLOWED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.ALLOWED,
                [DesignAction.DELETE]: ActionStatus.ALLOWED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.ALLOWED,
                [DesignAction.MOVE]: ActionStatus.DISABLED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.ALLOWED,
                [DesignAction.DELETE]: ActionStatus.DISABLED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR_REVIEWER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.ALLOWED,
                [DesignAction.MOVE]: ActionStatus.DISABLED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.ALLOWED,
                [DesignAction.DELETE]: ActionStatus.DISABLED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.REVIEWER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [DesignAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MOVE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DUPLICATE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.NOT_ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [DesignAction.LIST]: ActionStatus.NOT_ALLOWED,
                [DesignAction.VIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [DesignAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MOVE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DUPLICATE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.NOT_ALLOWED,
            },
        },
        [roles.video_brand_studio.video.manage]: {
            [IdentityRole.OWNER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MOVE]: ActionStatus.ALLOWED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DELETE]: ActionStatus.ALLOWED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.OWNER_REVIEWER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MOVE]: ActionStatus.ALLOWED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DELETE]: ActionStatus.ALLOWED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MOVE]: ActionStatus.DISABLED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DELETE]: ActionStatus.DISABLED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.DISABLED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR_REVIEWER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.ALLOWED,
                [DesignAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MOVE]: ActionStatus.DISABLED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DELETE]: ActionStatus.DISABLED,
                [DesignAction.DUPLICATE]: ActionStatus.ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.DISABLED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.REVIEWER]: {
                [DesignAction.LIST]: ActionStatus.ALLOWED,
                [DesignAction.VIEW]: ActionStatus.ALLOWED,
                [DesignAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [DesignAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MOVE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DUPLICATE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.DISABLED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.NOT_ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [DesignAction.LIST]: ActionStatus.NOT_ALLOWED,
                [DesignAction.VIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [DesignAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MOVE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.MANAGE_SIZES]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.DUPLICATE]: ActionStatus.NOT_ALLOWED,
                [DesignAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [DesignAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [DesignAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [DesignAction.ADD_REVIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.CANCEL_REVIEW]: ActionStatus.NOT_ALLOWED,
                [DesignAction.REQUEST_REVIEW]: ActionStatus.NOT_ALLOWED,
            },
        },
    },
    [Entity.TEMPLATE]: {
        [roles.video_brand_studio.handler.admin]: {
            [IdentityRole.OWNER]: {
                [TemplateAction.LIST]: ActionStatus.ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.ALLOWED,
                [TemplateAction.EDIT]: ActionStatus.ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.ALLOWED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.ALLOWED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CAN_USE]: {
                [TemplateAction.LIST]: ActionStatus.ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.DISABLED,
                [TemplateAction.EDIT]: ActionStatus.ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.DISABLED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.DISABLED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [TemplateAction.LIST]: ActionStatus.ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.DISABLED,
                [TemplateAction.EDIT]: ActionStatus.ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.DISABLED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.DISABLED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [TemplateAction.LIST]: ActionStatus.ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.DISABLED,
                [TemplateAction.DELETE]: ActionStatus.DISABLED,
                [TemplateAction.EDIT]: ActionStatus.DISABLED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.DISABLED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.DISABLED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.NOT_ALLOWED,
            },
        },
        [roles.video_brand_studio.template.manage]: {
            [IdentityRole.OWNER]: {
                [TemplateAction.LIST]: ActionStatus.ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.ALLOWED,
                [TemplateAction.EDIT]: ActionStatus.ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.ALLOWED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.ALLOWED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CAN_USE]: {
                [TemplateAction.LIST]: ActionStatus.ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.DISABLED,
                [TemplateAction.EDIT]: ActionStatus.ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.DISABLED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.DISABLED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [TemplateAction.LIST]: ActionStatus.ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.DISABLED,
                [TemplateAction.EDIT]: ActionStatus.ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.DISABLED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.DISABLED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [TemplateAction.LIST]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.NOT_ALLOWED,
            },
        },
        [roles.video_brand_studio.video.manage]: {
            [IdentityRole.OWNER]: {
                [TemplateAction.LIST]: ActionStatus.ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CAN_USE]: {
                [TemplateAction.LIST]: ActionStatus.ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [TemplateAction.LIST]: ActionStatus.ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [TemplateAction.LIST]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.CREATE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.EDIT]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.MOVE_IN_MODAL]: ActionStatus.NOT_ALLOWED,
                [TemplateAction.REQUEST_REVIEW]: ActionStatus.NOT_ALLOWED,
            },
        },
    },
    [Entity.CATEGORY]: {
        [roles.video_brand_studio.handler.admin]: {
            [IdentityRole.OWNER]: {
                [CategoryAction.LIST]: ActionStatus.ALLOWED,
                [CategoryAction.VIEW]: ActionStatus.ALLOWED,
                [CategoryAction.RENAME]: ActionStatus.ALLOWED,
                [CategoryAction.DELETE]: ActionStatus.ALLOWED,
                [CategoryAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [CategoryAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [CategoryAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [CategoryAction.LIST]: ActionStatus.ALLOWED,
                [CategoryAction.VIEW]: ActionStatus.ALLOWED,
                [CategoryAction.RENAME]: ActionStatus.ALLOWED,
                [CategoryAction.DELETE]: ActionStatus.DISABLED,
                [CategoryAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [CategoryAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [CategoryAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [CategoryAction.LIST]: ActionStatus.ALLOWED,
                [CategoryAction.VIEW]: ActionStatus.ALLOWED,
                [CategoryAction.RENAME]: ActionStatus.DISABLED,
                [CategoryAction.DELETE]: ActionStatus.DISABLED,
                [CategoryAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [CategoryAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [CategoryAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
            },
        },
        [roles.video_brand_studio.template.manage]: {
            [IdentityRole.OWNER]: {
                [CategoryAction.LIST]: ActionStatus.ALLOWED,
                [CategoryAction.VIEW]: ActionStatus.ALLOWED,
                [CategoryAction.RENAME]: ActionStatus.ALLOWED,
                [CategoryAction.DELETE]: ActionStatus.ALLOWED,
                [CategoryAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [CategoryAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [CategoryAction.TRANSFER_OWNERSHIP]: ActionStatus.ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [CategoryAction.LIST]: ActionStatus.ALLOWED,
                [CategoryAction.VIEW]: ActionStatus.ALLOWED,
                [CategoryAction.RENAME]: ActionStatus.ALLOWED,
                [CategoryAction.DELETE]: ActionStatus.DISABLED,
                [CategoryAction.VIEW_PERMISSIONS]: ActionStatus.ALLOWED,
                [CategoryAction.UPDATE_PERMISSIONS]: ActionStatus.ALLOWED,
                [CategoryAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [CategoryAction.LIST]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.VIEW]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
            },
        },
        [roles.video_brand_studio.video.manage]: {
            [IdentityRole.OWNER]: {
                [CategoryAction.LIST]: ActionStatus.ALLOWED,
                [CategoryAction.VIEW]: ActionStatus.ALLOWED,
                [CategoryAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
            },
            [IdentityRole.CONTRIBUTOR]: {
                [CategoryAction.LIST]: ActionStatus.ALLOWED,
                [CategoryAction.VIEW]: ActionStatus.ALLOWED,
                [CategoryAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
            },
            [IdentityRole.NO_ROLE]: {
                [CategoryAction.LIST]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.VIEW]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.RENAME]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.DELETE]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.VIEW_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.UPDATE_PERMISSIONS]: ActionStatus.NOT_ALLOWED,
                [CategoryAction.TRANSFER_OWNERSHIP]: ActionStatus.NOT_ALLOWED,
            },
        },
    },
};

// eslint-disable-next-line import/no-unused-modules
export const useEntityPermissions = (getAccountAssignedRole, isPlatformAllowedSelectorFn) => {
    const { groups, user } = useSelector(getUser);
    const profiles = AuthenticationHelper.getProfiles();

    const getIdentityRoleGroup = (permissions: EntityGrantedPermissions[]): IdentityRole => {
        const groupPermission = permissions.find(
            (permission) =>
                permission?.securityIdentity?.groupId && groups.includes(permission.securityIdentity.groupId),
        );

        if (!groupPermission) {
            return IdentityRole.NO_ROLE;
        }

        const getPermissionRole = (role: IdentityRole) => {
            if (
                groupPermission.grantedPermissions.some((entry) => entry.permission === role && entry.granted) ||
                (groupPermission.projectGrantedPermissions &&
                    groupPermission.projectGrantedPermissions.some(
                        (entry) => entry.permission === role && entry.granted,
                    ))
            ) {
                return role;
            }

            return null;
        };

        const contributorRole = getPermissionRole(IdentityRole.CONTRIBUTOR);
        const reviewerRole = getPermissionRole(IdentityRole.REVIEWER);
        const canUseRole = getPermissionRole(IdentityRole.CAN_USE);

        if (reviewerRole && !contributorRole) {
            return IdentityRole.REVIEWER;
        }

        if (contributorRole && !reviewerRole) {
            return IdentityRole.CONTRIBUTOR;
        }

        if (contributorRole && reviewerRole) {
            return IdentityRole.CONTRIBUTOR_REVIEWER;
        }

        if (canUseRole) {
            return IdentityRole.CAN_USE;
        }

        return IdentityRole.NO_ROLE;
    };

    const getIdentityRoleProfile = (permissions: EntityGrantedPermissions[]): IdentityRole => {
        const profilePermission = permissions.find((permission) =>
            profiles.includes(permission.securityIdentity.profileId),
        );

        if (!profilePermission) {
            return IdentityRole.NO_ROLE;
        }

        const getPermissionRole = (role: IdentityRole) => {
            if (
                profilePermission.grantedPermissions.some((entry) => entry.permission === role && entry.granted) ||
                (profilePermission.projectGrantedPermissions &&
                    profilePermission.projectGrantedPermissions.some(
                        (entry) => entry.permission === role && entry.granted,
                    ))
            ) {
                return role;
            }

            return null;
        };

        const contributorRole = getPermissionRole(IdentityRole.CONTRIBUTOR);
        const reviewerRole = getPermissionRole(IdentityRole.REVIEWER);
        const canUseRole = getPermissionRole(IdentityRole.CAN_USE);

        if (reviewerRole && !contributorRole) {
            return IdentityRole.REVIEWER;
        }

        if (contributorRole && !reviewerRole) {
            return IdentityRole.CONTRIBUTOR;
        }

        if (contributorRole && reviewerRole) {
            return IdentityRole.CONTRIBUTOR_REVIEWER;
        }

        if (canUseRole) {
            return IdentityRole.CAN_USE;
        }

        return IdentityRole.NO_ROLE;
    };

    const getIdentityRoleAllUsers = (permissions: EntityGrantedPermissions[]): IdentityRole => {
        const allUsersPermission = permissions.find(
            (permission) => permission.securityIdentity.securityIdentityType === IdentityType.ALL_USERS,
        );

        if (!allUsersPermission) {
            return IdentityRole.NO_ROLE;
        }

        const getPermissionRole = (role: IdentityRole) => {
            if (
                allUsersPermission.grantedPermissions.some((entry) => entry.permission === role && entry.granted) ||
                (allUsersPermission.projectGrantedPermissions &&
                    allUsersPermission.projectGrantedPermissions.some(
                        (entry) => entry.permission === role && entry.granted,
                    ))
            ) {
                return role;
            }

            return null;
        };

        const contributorRole = getPermissionRole(IdentityRole.CONTRIBUTOR);
        const canUseRole = getPermissionRole(IdentityRole.CAN_USE);

        if (contributorRole) {
            return IdentityRole.CONTRIBUTOR;
        }

        if (canUseRole) {
            return IdentityRole.CAN_USE;
        }

        return IdentityRole.NO_ROLE;
    };

    const getIdentityRoleProject = (permissions: EntityGrantedPermissions[]): IdentityRole => {
        const projectPermission = permissions.find(
            (permission) => permission.securityIdentity.identityId === user.identityId,
        );

        if (!projectPermission) {
            return IdentityRole.NO_ROLE;
        }

        if (
            projectPermission.projectGrantedPermissions &&
            projectPermission.projectGrantedPermissions.some(
                (entry) =>
                    (entry.permission === IdentityRole.OWNER || entry.permission === IdentityRole.CONTRIBUTOR) &&
                    entry.granted,
            )
        ) {
            return IdentityRole.CONTRIBUTOR;
        }

        return IdentityRole.NO_ROLE;
    };

    const getIdentityRoleEntity = (permissions: EntityGrantedPermissions[]): IdentityRole => {
        const userPermission = permissions.find(
            (permission) => permission.securityIdentity.identityId === user.identityId,
        );

        if (!userPermission) {
            return IdentityRole.NO_ROLE;
        }

        const getPermissionRole = (role: IdentityRole) => {
            if (userPermission.grantedPermissions.some((entry) => entry.permission === role && entry.granted)) {
                return role;
            }

            return null;
        };

        const ownerRole = getPermissionRole(IdentityRole.OWNER);
        const contributorRole = getPermissionRole(IdentityRole.CONTRIBUTOR);
        const reviewerRole = getPermissionRole(IdentityRole.REVIEWER);
        const canUseRole = getPermissionRole(IdentityRole.CAN_USE);

        if (reviewerRole) {
            if (ownerRole) {
                return IdentityRole.OWNER_REVIEWER;
            }

            if (contributorRole) {
                return IdentityRole.CONTRIBUTOR_REVIEWER;
            }

            if (!contributorRole) {
                return IdentityRole.REVIEWER;
            }
        } else {
            if (ownerRole) {
                return IdentityRole.OWNER;
            }

            if (contributorRole) {
                return IdentityRole.CONTRIBUTOR;
            }
        }

        if (canUseRole) {
            return IdentityRole.CAN_USE;
        }

        return IdentityRole.NO_ROLE;
    };

    const getIdentityRole = (permissions: EntityGrantedPermissions[]): IdentityRole => {
        if (!permissions) {
            return IdentityRole.NO_ROLE;
        }

        const identityRoleEntity = getIdentityRoleEntity(permissions);
        const identityRoleGroup = getIdentityRoleGroup(permissions);
        const identityRoleProfile = getIdentityRoleProfile(permissions);

        const isAnyIdentityRoleReviewer = [identityRoleEntity, identityRoleGroup, identityRoleProfile].some(
            (role) => role === IdentityRole.REVIEWER || role === IdentityRole.CONTRIBUTOR_REVIEWER,
        );

        const getIdentityRoleForReviewer = (identityRole: IdentityRole): IdentityRole => {
            if (identityRole === IdentityRole.OWNER) {
                return IdentityRole.OWNER_REVIEWER;
            }

            if (identityRole === IdentityRole.CONTRIBUTOR) {
                return IdentityRole.CONTRIBUTOR_REVIEWER;
            }

            return identityRole;
        };

        if (identityRoleEntity !== IdentityRole.NO_ROLE) {
            if (isAnyIdentityRoleReviewer) {
                return getIdentityRoleForReviewer(identityRoleEntity);
            }

            return identityRoleEntity;
        }

        if (identityRoleGroup !== IdentityRole.NO_ROLE) {
            if (isAnyIdentityRoleReviewer) {
                return getIdentityRoleForReviewer(identityRoleGroup);
            }

            return identityRoleGroup;
        }

        if (identityRoleProfile !== IdentityRole.NO_ROLE) {
            if (isAnyIdentityRoleReviewer) {
                return getIdentityRoleForReviewer(identityRoleProfile);
            }

            return identityRoleProfile;
        }

        const identityRoleProject = getIdentityRoleProject(permissions);

        if (identityRoleProject !== IdentityRole.NO_ROLE) {
            return identityRoleProject;
        }

        const identityRoleAllUsers = getIdentityRoleAllUsers(permissions);

        if (identityRoleAllUsers !== IdentityRole.NO_ROLE) {
            return identityRoleAllUsers;
        }

        return IdentityRole.NO_ROLE;
    };

    const isEntityActionAllowed = (
        action: EntityLevelActions,
        entity: Entity,
        permissions?: EntityGrantedPermissions[],
    ): ActionStatus => {
        const accountRole = getAccountAssignedRole();

        if (!accountRole) {
            return ActionStatus.NOT_ALLOWED;
        }

        if (!permissions) {
            return ActionStatus.NOT_ALLOWED;
        }

        const identityRole = getIdentityRole(permissions);

        if (!identityRole) {
            return ActionStatus.NOT_ALLOWED;
        }

        const actions = entityPermissions[entity][accountRole][identityRole];

        if (!actions) {
            return ActionStatus.NOT_ALLOWED;
        }

        if (!Object.prototype.hasOwnProperty.call(actions, action)) {
            return ActionStatus.NOT_ALLOWED;
        }

        return actions[action];
    };

    return {
        isEntityActionAllowed,
        getIdentityRole,
        getIdentityRoleAllUsers,
    };
};

export default useEntityPermissions;
