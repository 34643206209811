// todo: resolve cyclic dependencies
// import { DynamicEventEmitter, TextElement } from '@bynder-studio/render-core';
import { BoundedText } from '../Core/BoundedText';
import { LayoutRuns, RunProps, ShapedTextPosition, TextProps } from '../Core/StructuredText';
import { Cursor } from './Cursor';
import { MouseTracker } from './MouseTracker';
import { isCtrlKey, isOptionAltKey, isShiftKey } from '../Helpers/keys';
import { isAccented, isSelectionInclude, orderSelectPositions } from '../Helpers/utils';
import { DeactivateCb, OnChange, TextEditorData } from './types';
import { fromRunsToRichText, getRunFromElement, getTextFromClipboardItem } from '../Helpers/textUtils';
import { equals, isRtlGlyph, splitTextByWords } from '../Core/utils';
import { CursorOrientation, LayoutElement } from '../Core/Types';
import { type FontFamily } from '../Core/FontsFamilies';

const GENERATOR_NAME = 'bynder studio';

// eslint-disable-next-line no-control-regex
const CONTROL_CHARS_REGEX = /[\b\f\n\r\t\x00-\x1F\x7F\u0000-\u001F\u007F]/;
const INFORMATION_SEPARATOR_THREE = '\u001d';
const DELETE = '\x7F';
const ARROW_TOP = '\x1E';
const ARROW_BOTTOM = '\x1F';
const ARROW_LEFT = '\x1C';
const MULTI_CLICK_DELAY = 500;

// TextEditor provides UI editing capabilities for BoundedText
export class TextEditor {
    private canvas: HTMLCanvasElement = null;

    private containerDocument: Document = null;

    private scale = 1;

    private dpr = 1;

    private emitter: any = null;

    private fontFamilies: FontFamily[] = [];

    private readonly context!: CanvasRenderingContext2D;

    private cursor: Cursor | null = null;

    private mouseTracker: MouseTracker;

    /** @warning do not use outside "requestToChangeTextData"!! */
    onChange: OnChange | null = null;

    deactivateCb: DeactivateCb | null = null;

    private element!: any;

    private bText!: BoundedText;

    private offsetX!: number;

    private offsetY!: number;

    // Editor state
    private cursorEnabled = false;

    private cursorPosition!: ShapedTextPosition; // character index of cursor

    private selectStartPosition: ShapedTextPosition | null = null;

    private selectEndPosition: ShapedTextPosition | null = null;

    private inputRef: HTMLInputElement | null = null;

    private isKeyPressed = false;

    private isComposing = false;

    private latestMovingAction: {
        key: 'ArrowLeft' | 'ArrowRight' | 'ArrowUp' | 'ArrowDown' | 'None';
        isPositionChanged: boolean;
    } = {
        key: 'None',
        isPositionChanged: false,
    };

    private isDeadKeyPressed = false;

    private deleteAfterComposing = false;

    private repeatedKeyPressEvent = false;

    public isExternalInputFocused = false;

    private lastClickTime = 0;

    private clickCount = 0;

    constructor({ canvas, scale, dpr, containerDocument, emitter, fontFamilies, deactivate }) {
        this.canvas = canvas;
        this.containerDocument = containerDocument;
        this.scale = scale;
        this.dpr = dpr;
        this.emitter = emitter;
        this.fontFamilies = fontFamilies;
        this.deactivateCb = deactivate;
        this.context = canvas.getContext('2d');
        this.mouseTracker = new MouseTracker();
    }

    private get text() {
        const shapedText = this.bText.getShapedText();

        return shapedText.getText();
    }

    private get glyphs() {
        const shapedText = this.bText.getShapedText();

        return shapedText.getGlyphs();
    }

    private getCurrentGlyph() {
        return this.glyphs[this.cursorPosition.glyphIdx];
    }

    private getPrevGlyph() {
        const textIdx = this.getTextIdx(this.cursorPosition) - 1;

        return this.glyphs.find((g) => g.data.textIdxs.includes(textIdx));
    }

    private getNextRightHit(offset: number, n = 1) {
        return Math.min(offset + n, this.text.length);
    }

    private getNextLeftHit(offset: number, n = 1) {
        return Math.max(offset - n, 0);
    }

    private getNextWordRightHit(offset: number) {
        const words = splitTextByWords(this.text);
        const word = words.find((w) => w.end > offset);

        if (!word) {
            return this.text.length;
        }

        return word.end;
    }

    private getNextWordLeftHit(offset: number) {
        const words = splitTextByWords(this.text);
        const word = words.reverse().find((w) => w.start < offset);

        if (!word) {
            return 0;
        }

        return word.start;
    }

    private getNextWordHits(offset: number) {
        const words = splitTextByWords(this.text, true);
        const word = words.reverse().find((w) => w.end >= offset && w.start <= offset);

        if (!word) {
            return { start: 0, end: 0 };
        }

        return { ...word };
    }

    private getTextByLine(lineIndex: number) {
        const shapedText = this.bText.getShapedText();
        const text = this.text;

        const line = shapedText.getShapedLines()[lineIndex];
        const lastGlyph = line.glyphs[line.glyphs.length - 1];

        if (!lastGlyph) {
            return {
                text: '\n',
                start: text.length,
            };
        }

        const textIdxs = line.glyphs.flatMap((g) => g.data.textIdxs);
        const start = textIdxs.reduce((acc, idx) => Math.min(acc, idx));
        const end = textIdxs.reduce((acc, idx) => Math.max(acc, idx)) + 1;

        return {
            text: text.slice(start, end),
            start,
        };
    }

    private getLineRightHit(lineIndex: number) {
        const line = this.getTextByLine(lineIndex);

        return line.start + Math.max(0, line.text.length - (line.text.endsWith('\n') ? 1 : 0));
    }

    private getLineLeftHit(lineIndex: number) {
        const line = this.getTextByLine(lineIndex);

        return line.start;
    }

    private getParagraphRightHit(startLineIndex: number) {
        const shapedText = this.bText.getShapedText();
        const totalLines = shapedText.getShapedLines().length;
        let currentLineIndex = startLineIndex;

        let currentLine = this.getTextByLine(currentLineIndex);

        while (currentLineIndex < totalLines - 1) {
            if (currentLine.text.endsWith('\n')) {
                break;
            }

            currentLineIndex++;
            currentLine = this.getTextByLine(currentLineIndex); // Fetch the next line
        }

        const endOffset = currentLine.text.endsWith('\n') ? 1 : 0;

        return currentLine.start + Math.max(0, currentLine.text.length - endOffset);
    }

    private getParagraphLeftHit(startLineIndex: number) {
        let currentLineIndex = startLineIndex;

        while (currentLineIndex > 0) {
            const prevLine = this.getTextByLine(currentLineIndex - 1);

            if (prevLine.text.endsWith('\n')) {
                break;
            }

            currentLineIndex--;
        }

        const currentLine = this.getTextByLine(currentLineIndex);

        return currentLine.start;
    }

    private getTextIdx(position: ShapedTextPosition) {
        return this.bText.getShapedText().getTextIdx(position);
    }

    private textIdxToPosition(textIdx: number): ShapedTextPosition {
        return this.bText.getShapedText().textIdxToPosition(textIdx);
    }

    async cut() {
        if (!this.bText || !this.hasSelection()) {
            return;
        }

        await this.copy();
        this.removeText(true);
    }

    async paste() {
        if (!this.bText) {
            return;
        }

        await this.insertTextFromClipboard();
    }

    async copy() {
        if (!this.bText) {
            return;
        }

        await this.copyTextToClipboard();
    }

    private getScale(): number {
        return this.scale;
    }

    private getDpr(): number {
        return this.dpr;
    }

    // Updating of most important params for right rendering
    // This method is cached in manipulationLevel and called only if any of these params was changed.
    updateDynamicData = (offsetX: number, offsetY: number, scale: number) => {
        if (this.offsetX !== offsetX || this.offsetY !== offsetY || this.scale !== scale) {
            this.offsetX = offsetX;
            this.offsetY = offsetY;
            this.scale = scale;

            this.syncTextDataWithElement(true);
        }

        // TODO: I guess this is not good to make it every iteration.
        // Add here some caching
        this.syncTextDataWithElement();
    };

    private activatingCursorAndSelection(e: MouseEvent) {
        if (!this.bText) {
            return;
        }

        let [x, y] = this.calculateMousePosition(e);
        x = x - this.offsetX;
        y = y - this.offsetY;

        const [startPos, endPos] = this.bText.getPositionsFromArea(x, y, x, y, false);

        if (startPos === null || endPos === null) {
            this.selectAll();
        } else {
            this.setCursor(startPos);
        }
    }

    onTextEditorUpdate = (textEditorData: TextEditorData) => {
        if (textEditorData === null) {
            this.deactivateCb();

            return;
        }

        const { cursorPosition, selection } = textEditorData;
        this.setCursor(cursorPosition);

        if (selection.start && selection.end) {
            this.setSelection(selection.start, selection.end);
        }
    };

    // Activate text editor and set onChange cb
    activate(emitter: any, onChange: OnChange, e: MouseEvent) {
        this.onChange = onChange;
        this.activatingCursorAndSelection(e);
        this.requestToChangeTextData();
    }

    setBText(element: any) {
        this.element = element;
        this.bText = element.boundedText;

        this.boundedTextUpdatedEventHandler();
        this.bindEvents();
    }

    // /** Should be used only inside TextManipulation special fn, not directly */
    public deactivate() {
        if (!this.bText) {
            return;
        }

        this.unbindEvents();
        this.onChange(this.element.id, this.bText.getShapedText().getTextProps(), null, false);
    }

    draw() {
        if (!this.bText) {
            return;
        }

        if (this.hasSelection()) {
            const [start, end] = orderSelectPositions(this.selectStartPosition, this.selectEndPosition);

            this.bText.getSelectionRanges(start, end).forEach((item) => {
                this.drawSelectionHighlight(this.context, item.x, item.y, item.width, item.height);
            });
        }

        if (!this.isExternalInputFocused && this.cursor && this.cursorEnabled) {
            this.calculateCursorPosition().forEach(([x, y, h], index, arr) => {
                this.cursor.updatePosition(x, y, h, false);
                this.updateCursorColor();
                this.cursor.draw(this.getScale(), this.getDpr(), 1 - index / arr.length, this.isKeyPressed);
            });
        }
    }

    drawSelectionHighlight(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number) {
        const scale = this.getScale() * this.dpr;

        ctx.save();
        // reset the transform to make sure the x and y offset are from the absolute left top of the canvas
        ctx.resetTransform();
        ctx.fillStyle = '#00AAFF4D'; // highlight color

        ctx.fillRect(
            (x + this.offsetX) * scale,
            (y + this.offsetY) * scale,
            width * scale, // for empty lines show a minimum selection line
            height * scale,
        );

        ctx.restore();
    }

    initCursor() {
        // initialise cursor and set it to the end of the text
        if (!this.cursor) {
            this.cursor = new Cursor(this.context);
        }

        this.setCursor(this.textIdxToPosition(this.text.length));
    }

    clearCursor() {
        this.cursorPosition = null;
        this.cursor = null;
        this.cursorEnabled = false;
    }

    private updateInputPosition(x: number, y: number, h: number) {
        if (!this.inputRef) {
            return;
        }

        const scale = this.getScale();
        const { left, top }: DOMRect = this.canvas.getBoundingClientRect();

        this.inputRef.style.height = scale * h + 'px';
        this.inputRef.style.left = scale * x + left + 'px';
        this.inputRef.style.top = scale * y + top + 'px';
        this.inputRef.focus();
    }

    renderContextMenu = (contextMenuEl) => {
        const [x, y] = this.getContextMenuPosition();

        contextMenuEl.style.left = `${x}px`; // 5px is a margin from letter
        contextMenuEl.style.top = `${y}px`;

        this.containerDocument.body.appendChild(contextMenuEl);
    };

    getContextMenuPosition() {
        const MARGIN_FROM_CURSOR = 5;
        const scale = this.getScale();
        const [x, y] = this.bText.getGlyphAreaByPosition(this.cursorPosition || this.selectEndPosition);
        const { left, top }: DOMRect = this.canvas.getBoundingClientRect();

        return [
            scale * (x + this.offsetX) + left + MARGIN_FROM_CURSOR,
            scale * (y + this.offsetY) + top + MARGIN_FROM_CURSOR,
        ];
    }

    private updateCursorColor() {
        const textIdxs = this.bText.getShapedText().getTextIdxsFromSelection(this.cursorPosition, this.cursorPosition);
        const { color } = this.bText.getShapedText().getStylesForSelection(...textIdxs);
        this.cursor.setColor(color[0]);
    }

    private calculateCursorPosition(): [number, number, number][] {
        const currentGlyph = this.getCurrentGlyph();
        const prevGlyph = this.getPrevGlyph();
        const currentGlyphSubTextIdx = this.cursorPosition.subTextIdx;
        const cursorPos: [number, number, number][] = [];

        if (
            currentGlyph &&
            prevGlyph &&
            !prevGlyph.isNewLine() &&
            !currentGlyph.isNewLine() &&
            isRtlGlyph(currentGlyph) &&
            !isRtlGlyph(prevGlyph)
        ) {
            const prevGlyphSubTextIdx = (currentGlyphSubTextIdx || prevGlyph.data.textIdxs.length) - 1;
            const [x, y, w, h] = this.bText.getGlyphArea(prevGlyph, prevGlyphSubTextIdx);
            // prettier-ignore
            cursorPos.push([
                x + this.offsetX + w,
                y + this.offsetY,
                h,
            ]);
        }

        const [x, y, w, h] = this.bText.getGlyphAreaByPosition(this.cursorPosition);
        // prettier-ignore
        cursorPos.push([
            x + this.offsetX + (this.cursorPosition.cursorOrientation === CursorOrientation.Right ? w : 0),
            y + this.offsetY,
            h,
        ]);

        return cursorPos;
    }

    private updateCursorData(pos, saveSelection: boolean, holdCursor: boolean) {
        if (this.cursor) {
            this.cursorPosition = pos;
            const [x, y, h] = this.calculateCursorPosition()[0];
            this.cursor.updatePosition(x, y, h, holdCursor);

            if (!saveSelection) {
                this.clearSelection();
                this.updateCursorColor();
                this.cursorEnabled = true;
            }

            this.updateInputPosition(x, y, h);
        }
    }

    private updateSelectionData(pos1: ShapedTextPosition, pos2: ShapedTextPosition) {
        // NOTE: We don't need to reorder select and start positions here!!!
        // We are doing this only in redraw function.
        [this.selectStartPosition, this.selectEndPosition] = [pos1, pos2];

        if (this.hasSelection()) {
            this.cursorEnabled = false;
        }
    }

    /**
     * Switch to cursor mode
     * @param pos
     * @param saveSelection - for not clearing selection
     * @param holdCursor - for not reseting cursor blinking timer
     */
    private setCursor(pos: ShapedTextPosition, saveSelection = false, holdCursor = true) {
        this.updateCursorData(pos, saveSelection, holdCursor);
        this.requestToChangeTextData();
    }

    private syncTextDataWithElement(forceSync = false) {
        if (!this.bText) {
            return;
        }

        const { cursorPosition, selection } = this.element.textEditorData || {};

        if (!cursorPosition) {
            this.deactivateCb();

            return;
        }

        if (forceSync || !equals(this.cursorPosition, cursorPosition)) {
            this.updateCursorData(cursorPosition, !!selection.start, !selection.start && !forceSync);
        }

        const currentSelection = { start: this.selectStartPosition, end: this.selectEndPosition };

        if (forceSync || !equals(currentSelection, selection)) {
            this.updateSelectionData(selection.start, selection.end);
        }
    }

    private moveCursor(dX: number, dY: number, saveSelection = false, holdCursor = true) {
        if (this.cursorPosition && (dX || dY)) {
            if (dX && !dY) {
                this.setCursor(
                    this.textIdxToPosition(Math.min(this.getTextIdx(this.cursorPosition) + dX, this.text.length)),
                    saveSelection,
                    holdCursor,
                );

                return;
            }

            this.setCursor(
                this.bText.getShapedTextPositionDelta(this.cursorPosition, dX, dY),
                saveSelection,
                holdCursor,
            );
        }
    }

    /**
     * Switch to selection mode and set selection
     * @param pos1
     * @param pos2
     */
    private setSelection(pos1: ShapedTextPosition, pos2: ShapedTextPosition) {
        this.updateSelectionData(pos1, pos2);
        this.requestToChangeTextData();
    }

    private clearSelection() {
        this.selectStartPosition = null;
        this.selectEndPosition = null;
    }

    private hasSelection() {
        if (!this.selectStartPosition || !this.selectEndPosition) {
            return false;
        }

        const start = this.selectStartPosition;
        const end = this.selectEndPosition;

        return start.textIdx !== end.textIdx;
    }

    private getSelectionIndexes(): { startIdx: number; endIdx: number } {
        if (this.hasSelection()) {
            const startIdx = this.getTextIdx(this.selectStartPosition);
            const endIdx = this.getTextIdx(this.selectEndPosition);

            return {
                startIdx: Math.min(startIdx, endIdx),
                endIdx: Math.max(startIdx, endIdx),
            };
        }

        const textIdx = this.getTextIdx(this.cursorPosition);

        return { startIdx: textIdx, endIdx: textIdx };
    }

    private selectAll = () => {
        this.setSelection(this.textIdxToPosition(0), this.textIdxToPosition(this.text.length));
    };

    private removeText(backspace = false) {
        const shapedText = this.bText.getShapedText();
        const getStartEnd = () => {
            if (this.cursorEnabled) {
                const textIdx = this.getTextIdx(this.cursorPosition);

                const startIdx = backspace ? this.getNextLeftHit(textIdx) : textIdx;
                const endIdx = backspace ? textIdx : this.getNextRightHit(textIdx);

                return { startIdx, endIdx };
            }

            return this.getSelectionIndexes();
        };

        const { startIdx, endIdx } = getStartEnd();

        const newTextData = shapedText.updateTextForSelection(startIdx, endIdx, '');
        this.requestToChangeTextData(newTextData);
        this.setCursor(this.textIdxToPosition(startIdx));
    }

    private insertText(text: string, updateLayout = false) {
        const shapedText = this.bText.getShapedText();
        const { startIdx, endIdx } = this.getSelectionIndexes();

        const newTextData = shapedText.updateTextForSelection(startIdx, endIdx, text, updateLayout);
        this.requestToChangeTextData(newTextData);
        this.setCursor(this.textIdxToPosition(startIdx + text.length));
    }

    private async copyTextToClipboard() {
        if (!this.hasSelection()) {
            return;
        }

        const [selStart, selEnd] = orderSelectPositions(this.selectStartPosition, this.selectEndPosition);
        const text = this.bText.getTextForSelection(selStart, selEnd);

        if (!text) {
            return;
        }

        const { startIdx, endIdx } = this.getSelectionIndexes();
        const shapedText = this.bText.getShapedText();
        const [updatedRuns, updatedLayoutRuns] = shapedText.getUpdatedRunsForConverting(startIdx, endIdx);
        const richText = fromRunsToRichText(updatedLayoutRuns, updatedRuns, text);
        const generatorTag = `<meta name="generator" content="${GENERATOR_NAME}">`;
        const data = [
            new ClipboardItem({
                'text/html': new Blob([generatorTag, richText], {
                    type: 'text/html',
                }),
                'text/plain': new Blob([text], { type: 'text/plain' }),
            }),
        ];

        await navigator.clipboard.write(data).then(
            () => {},
            (error) => {
                console.log(error);
            },
        );
    }

    private async insertPlainText(data: ClipboardItem) {
        const text = await getTextFromClipboardItem(data, 'plain');

        if (text) {
            this.insertText(text, true);
        }
    }

    private async insertTextFromClipboard(isShiftPressed = false) {
        await navigator.clipboard.read().then(async (clips) => {
            for (const item of clips) {
                if (isShiftPressed) {
                    await this.insertPlainText(item);
                    continue;
                }

                const text = await getTextFromClipboardItem(item, 'html');

                if (!text) {
                    await this.insertPlainText(item);
                    continue;
                }

                try {
                    const document = new DOMParser().parseFromString(text, 'text/html');
                    const generator = document.querySelector('meta[name=generator]');

                    if (generator?.getAttribute('content') !== GENERATOR_NAME) {
                        await this.insertPlainText(item);
                        continue;
                    }

                    if (this.hasSelection()) {
                        this.removeText();
                    }

                    const paragraphs = Array.from(document.querySelectorAll('p'));

                    if (!paragraphs.length) {
                        console.error('No layoutRuns in clipboard, fallback to pasting plaintext');

                        await this.insertPlainText(item);
                        continue;
                    }

                    const layoutRunsToAdd = paragraphs.reduce((acc, p) => {
                        const containedSpans = Array.from(p.querySelectorAll('span'));

                        const length = containedSpans.reduce(
                            (contentLength, span) => contentLength + span.textContent.length,
                            0,
                        );

                        acc.push({
                            type: LayoutElement.PARAGRAPH,
                            length,
                        });

                        return acc;
                    }, [] as LayoutRuns);

                    const shapedText = this.bText.getShapedText();
                    const [startIdx] = shapedText.getTextIdxsFromSelection(this.cursorPosition, this.cursorPosition);

                    const existingStyles = shapedText.getStylesForSelection(startIdx, startIdx, true);
                    const fonts = this.fontFamilies.flatMap((family) => family.fonts);

                    const spans = Array.from(document.body.querySelectorAll('span'));
                    const { runs, value } = spans.reduce(
                        (acc, element) => {
                            const run = getRunFromElement(element, fonts, existingStyles);
                            acc.value += element.textContent;
                            acc.runs.push(run);

                            return acc;
                        },
                        { runs: [] as RunProps[], value: '' },
                    );

                    this.requestToChangeTextData(
                        shapedText.updateRunsWithRuns({
                            runsToAdd: runs,
                            layoutRunsToAdd,
                            textIdxStart: startIdx,
                            text: value,
                        }),
                    );
                    this.moveCursor(value.length, 0);
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }

    private createHandlerInput() {
        if (this.inputRef === null) {
            this.inputRef = document.createElement('input');
            this.inputRef.id = 'text-editor-input__hidden';
            this.inputRef.autocomplete = 'off';
            this.inputRef.style.position = 'fixed';
            this.inputRef.style.opacity = '0';
            this.inputRef.style.zIndex = '-1';

            this.inputRef.addEventListener('compositionstart', () => {
                this.isComposing = true;
            });

            this.inputRef.addEventListener('compositionend', () => {
                this.isComposing = false;

                if (!this.deleteAfterComposing) {
                    this.setCursor(this.selectStartPosition);
                }

                this.deleteAfterComposing = false;
            });

            this.inputRef.addEventListener('keydown', this.handleInputKeyDown);
            this.inputRef.addEventListener('input', this.handleInputChange);
            this.inputRef.addEventListener('keyup', this.handleInputKeyUp);

            this.containerDocument.body.appendChild(this.inputRef);
            this.inputRef.focus();
        }
    }

    private removeHandlerInput() {
        this.inputRef?.remove();
        this.inputRef = null;
    }

    bindEvents() {
        this.createHandlerInput();

        this.emitter.on('textEditorUpdate', this.onTextEditorUpdate);
        this.canvas.addEventListener('mousedown', this.handleMouseDown);
        this.canvas.addEventListener('mousemove', this.handleMouseMove);
        this.containerDocument.addEventListener('mousedown', this.handleContainerMouseDown);
        this.containerDocument.addEventListener('mouseup', this.handleContainerMouseUp);
        this.containerDocument.addEventListener('keyup', this.handleContainerKeyUp);
    }

    unbindEvents() {
        this.removeHandlerInput();

        this.emitter.off('textEditorUpdate', this.onTextEditorUpdate);
        this.canvas.removeEventListener('mousedown', this.handleMouseDown);
        this.canvas.removeEventListener('mousemove', this.handleMouseMove);
        this.containerDocument.removeEventListener('mousedown', this.handleContainerMouseDown);
        this.containerDocument.removeEventListener('mouseup', this.handleContainerMouseUp);
        this.containerDocument.removeEventListener('keyup', this.handleContainerKeyUp);
    }

    private boundedTextUpdatedEventHandler() {
        // initialise cursor after bounding box has been updated
        if (this.cursor === null) {
            this.initCursor();
        }
    }

    private onDoubleClick = () => {
        const [startPos] = this.bText.getPositionsFromArea(...this.mouseTracker.getArea());

        if (!startPos) {
            return;
        }

        const currentTextIndex = this.getTextIdx(startPos);
        const { start, end } = this.getNextWordHits(currentTextIndex);
        const startWordPos = this.textIdxToPosition(start);
        const endWordPos = this.textIdxToPosition(end);
        this.setSelection(startWordPos, endWordPos);
    };

    private onTripleClick = () => {
        const [startPos] = this.bText.getPositionsFromArea(...this.mouseTracker.getArea());

        if (!startPos) {
            return;
        }

        const lineStart = this.textIdxToPosition(this.getParagraphRightHit(startPos.lineIdx));
        const lineEnd = this.textIdxToPosition(this.getParagraphLeftHit(startPos.lineIdx));
        this.setSelection(lineStart, lineEnd);
    };

    private onQuadrupleClick = () => {
        const [startPos] = this.bText.getPositionsFromArea(...this.mouseTracker.getArea());

        if (!startPos) {
            return;
        }

        this.selectAll();
    };

    // TODO: Refactor requestToChangeTextData usage!
    private requestToChangeTextData = (formattedText: TextProps = null) => {
        if (!this.onChange) {
            return;
        }

        const currentFText = this.bText.getShapedText().getTextProps();
        const properFormattedText = formattedText || currentFText;
        const selection = { start: this.selectStartPosition, end: this.selectEndPosition };
        const cursorPosition = this.cursorPosition;
        const textEditorData =
            !cursorPosition && !selection.start && !selection.end ? null : { cursorPosition, selection };

        if (!equals(properFormattedText, currentFText) || !equals(textEditorData, this.element.textEditorData)) {
            this.onChange(this.element.id, properFormattedText, textEditorData, true);
        }
    };

    private handleArrowDownAfterSelection = (
        key: 'ArrowLeft' | 'ArrowRight' | 'ArrowUp' | 'ArrowDown',
    ): ShapedTextPosition => {
        let newPosition = this.cursorPosition;
        const [startSelect, endSelect] = orderSelectPositions(this.selectStartPosition, this.selectEndPosition);

        if (key === 'ArrowLeft') {
            newPosition = this.textIdxToPosition(Math.min(this.getTextIdx(startSelect), this.getTextIdx(endSelect)));
        } else if (key === 'ArrowRight') {
            newPosition = this.textIdxToPosition(Math.max(this.getTextIdx(startSelect), this.getTextIdx(endSelect)));
        } else if (key === 'ArrowDown') {
            newPosition = this.bText.getShapedTextPositionDelta(endSelect, 0, 1);
        } else if (key === 'ArrowUp') {
            newPosition = this.bText.getShapedTextPositionDelta(startSelect, 0, -1);
        }

        return newPosition;
    };

    private onArrowKeyDown = (
        key: 'ArrowLeft' | 'ArrowRight' | 'ArrowUp' | 'ArrowDown',
        isShiftPressed: boolean,
        isAltPressed: boolean,
        isCmdPressed: boolean,
    ) => {
        let arrowKey = key;
        let finalPosition: ShapedTextPosition;
        const { ltr, rtl } = this.element?.detectedTextDirections || { ltr: 0, rtl: 0 };
        const isFullArabic = ltr === 0 && rtl > 0;

        if (isFullArabic) {
            if (key === 'ArrowLeft') {
                arrowKey = 'ArrowRight';
            } else if (key === 'ArrowRight') {
                arrowKey = 'ArrowLeft';
            }
        }

        if (isCmdPressed) {
            switch (arrowKey) {
                case 'ArrowRight':
                    finalPosition = this.textIdxToPosition(this.getLineRightHit(this.cursorPosition.lineIdx));

                    if (
                        (this.glyphs[finalPosition.glyphIdx] && isRtlGlyph(this.glyphs[finalPosition.glyphIdx])) ||
                        (this.glyphs[Math.max(0, finalPosition.glyphIdx - 1)] &&
                            isRtlGlyph(this.glyphs[Math.max(0, finalPosition.glyphIdx - 1)]))
                    ) {
                        finalPosition = this.textIdxToPosition(Math.max(0, finalPosition.textIdx - 1));
                        finalPosition.cursorOrientation = CursorOrientation.Left;
                    }

                    break;
                case 'ArrowLeft':
                    finalPosition = this.textIdxToPosition(this.getLineLeftHit(this.cursorPosition.lineIdx));
                    break;
                case 'ArrowUp':
                    finalPosition = this.textIdxToPosition(0);
                    break;
                case 'ArrowDown':
                    finalPosition = this.textIdxToPosition(this.text.length);
                    break;
            }
        } else if (isAltPressed) {
            switch (arrowKey) {
                case 'ArrowRight':
                    finalPosition = this.textIdxToPosition(
                        this.getNextWordRightHit(this.getTextIdx(this.cursorPosition)),
                    );
                    break;
                case 'ArrowLeft':
                    finalPosition = this.textIdxToPosition(
                        this.getNextWordLeftHit(this.getTextIdx(this.cursorPosition)),
                    );
                    break;
                case 'ArrowUp':
                    finalPosition = this.textIdxToPosition(0);
                    break;
                case 'ArrowDown':
                    finalPosition = this.textIdxToPosition(this.text.length);
                    break;
            }
        } else {
            switch (arrowKey) {
                case 'ArrowRight':
                    finalPosition = this.textIdxToPosition(this.getNextRightHit(this.getTextIdx(this.cursorPosition)));
                    break;
                case 'ArrowLeft':
                    finalPosition = this.textIdxToPosition(this.getNextLeftHit(this.getTextIdx(this.cursorPosition)));
                    break;
                case 'ArrowUp':
                    finalPosition = this.bText.getShapedTextPositionDelta(this.cursorPosition, 0, -1);
                    break;
                case 'ArrowDown':
                    finalPosition = this.bText.getShapedTextPositionDelta(this.cursorPosition, 0, 1);
                    break;
            }
        }

        if (isShiftPressed) {
            const startPos = this.selectStartPosition || this.cursorPosition;
            this.setSelection(
                this.textIdxToPosition(this.getTextIdx(startPos)),
                this.textIdxToPosition(this.getTextIdx(finalPosition)),
            );
        } else if (this.hasSelection()) {
            finalPosition = this.handleArrowDownAfterSelection(arrowKey);
        }

        this.latestMovingAction.isPositionChanged = !equals(finalPosition, this.cursorPosition);

        this.setCursor(finalPosition, this.hasSelection() ? isShiftPressed : false);
    };

    private handleCmdShortcuts = async (key: string, isShiftPressed: boolean) => {
        if (key === 'x') {
            await this.cut();
        } else if (key === 'a') {
            this.selectAll();
        } else if (key === 'c') {
            await this.copyTextToClipboard();
        } else if (key === 'v') {
            await this.insertTextFromClipboard(isShiftPressed);
        }
    };

    private handleMacCtrlShortcuts = (key: string) => {
        if (key === 'a') {
            this.setCursor(this.textIdxToPosition(0));
        } else if (key === 'e') {
            this.setCursor(this.textIdxToPosition(this.text.length));
        }
    };

    private handleInputKeyUp = () => {
        this.isKeyPressed = false;
    };

    private handleInputChange = (event: InputEvent) => {
        if (!event.data) {
            return;
        }

        if (this.isDeadKeyPressed) {
            this.repeatedKeyPressEvent = false;
        }

        if (this.repeatedKeyPressEvent) {
            this.repeatedKeyPressEvent = false;

            if (isAccented(event.data)) {
                let dx = event.data.length;

                switch (this.latestMovingAction.key) {
                    case 'ArrowLeft':
                        this.setSelection(
                            this.cursorPosition,
                            this.bText.getShapedTextPositionDelta(this.cursorPosition, dx, 0),
                        );
                        break;
                    case 'ArrowRight': {
                        if (!this.latestMovingAction.isPositionChanged) {
                            dx--;
                        }

                        this.setSelection(
                            this.bText.getShapedTextPositionDelta(this.cursorPosition, -dx, 0),
                            this.bText.getShapedTextPositionDelta(this.cursorPosition, -dx - 1, 0),
                        );
                        break;
                    }
                    default:
                        this.setSelection(
                            this.cursorPosition,
                            this.textIdxToPosition(this.getNextLeftHit(this.getTextIdx(this.cursorPosition), dx)),
                        );
                }
            }
        }

        if (!CONTROL_CHARS_REGEX.test(event.data)) {
            this.insertText(event.data);
        } else {
            switch (event.data) {
                case INFORMATION_SEPARATOR_THREE:
                    // only text length is important here, so we can insert any char
                    this.insertText(' ');
                    break;
                case '\b':
                    this.removeText();
                    break;
                case DELETE:
                    this.removeText(false);
                    break;
                case ARROW_TOP:
                    this.moveCursor(0, -1);
                    break;
                case ARROW_BOTTOM:
                    this.moveCursor(0, 1);
                    break;
                case ARROW_LEFT:
                    this.moveCursor(-1, 0);
                    break;
            }
        }

        if (event.isComposing) {
            this.setSelection(
                this.cursorPosition,
                this.textIdxToPosition(this.getNextLeftHit(this.getTextIdx(this.cursorPosition))),
            );
        }
    };

    private handleInputKeyDown = async (e: KeyboardEvent) => {
        this.latestMovingAction = { key: 'None', isPositionChanged: false };

        if (this.isComposing) {
            if (e.key === 'Delete') {
                this.deleteAfterComposing = true;
            }

            return;
        }

        if (e.key === 'Escape') {
            if (this.isDeadKeyPressed) {
                // stop selecting accented letter instead of deactivating the text editor
                this.isDeadKeyPressed = false;

                return;
            }

            this.deactivateCb();

            return;
        }

        this.isKeyPressed = true;

        const isShiftPressed = isShiftKey(e);
        const isAltPressed = isOptionAltKey(e);
        const isCmdPressed = isCtrlKey(e);

        if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            // can't find a better workaround for this
            // cursor is already in a right position if we press ArrowRight key after accent
            if (!this.isDeadKeyPressed || e.key !== 'ArrowRight') {
                this.onArrowKeyDown(e.key, isShiftPressed, isAltPressed, isCmdPressed);
            }

            this.latestMovingAction.key = e.key;
            this.isDeadKeyPressed = false;

            return;
        }

        this.isDeadKeyPressed = false;

        if (isCmdPressed) {
            e.preventDefault();

            await this.handleCmdShortcuts(e.key, isShiftPressed);

            return;
        }

        if (e.ctrlKey) {
            this.handleMacCtrlShortcuts(e.key);

            return;
        }

        // allow only backspace repeating
        if (e.repeat && e.key !== 'Backspace') {
            this.repeatedKeyPressEvent = true;

            return;
        }

        if (e.ctrlKey) {
            return;
        }

        if (e.key === 'Unidentified') {
            // sometimes appears when we press capslock
            return;
        } else if (e.key === 'Dead') {
            // appears when we press accent
            // he handle it in handleInputChange
            this.isDeadKeyPressed = true;

            return;
        } else if (e.key === 'Enter') {
            this.insertText('\n', !isShiftPressed);
        } else if (e.key === 'Backspace') {
            if (e.keyCode === 229) {
                // appears when we press backspace on mac and we have an accent
                // it performs unndcessary additional delete action, so we ignore it
                return;
            }

            this.removeText(true);
        } else if (e.key === 'Delete') {
            this.removeText(false);
        } else {
            // ignore special keys
            const k = e.keyCode;

            if (
                k === 20 /* Caps lock */ ||
                k === 16 /* Shift */ ||
                k === 9 /* Tab */ ||
                k === 27 /* Escape Key */ ||
                k === 17 /* Control Key */ ||
                k === 91 /* Windows Command Key */ ||
                k === 19 /* Pause Break */ ||
                k === 18 /* Alt Key */ ||
                k === 93 /* Right Click Point Key */ ||
                (k >= 35 && k <= 40) /* Home, End, Arrow Keys */ ||
                k === 45 /* Insert Key */ ||
                (k >= 33 && k <= 34) /* Page Down, Page Up */ ||
                (k >= 112 && k <= 123) /* F1 - F12 */ ||
                (k >= 144 && k <= 145)
            ) {
                /* Num Lock, Scroll Lock */
                return false;
            }

            // this.insertText(e.key);
        }

        // do not clear the input in case of accent menu opened
        if (!this.repeatedKeyPressEvent) {
            (e.target as HTMLInputElement).value = '';
        }
    };

    private calculateMousePosition(e: MouseEvent) {
        const bounds: DOMRect = this.canvas.getBoundingClientRect();
        const x = e.clientX - bounds.left;
        const y = e.clientY - bounds.top;

        return [x / this.getScale(), y / this.getScale()];
    }

    private handleContainerMouseDown = (e: MouseEvent) => {
        this.checkForExternalInput(e);
    };

    private handleMouseDown = (e: MouseEvent) => {
        const isRightMouseClick = e.button === 2;
        let [x, y] = this.calculateMousePosition(e);

        // TODO: Refactor this
        if (!x || !y || !this.offsetX || !this.offsetY) {
            return;
        }

        x = x - this.offsetX;
        y = y - this.offsetY;
        this.mouseTracker.setDown(x, y);

        if (e.button === 0) {
            const currentTime = Date.now();

            if (currentTime - this.lastClickTime < MULTI_CLICK_DELAY) {
                this.clickCount++;
            } else {
                this.clickCount = 1;
            }

            this.lastClickTime = currentTime;
        } else {
            this.clickCount = 0;
        }

        const [startPos] = this.bText.getPositionsFromArea(...this.mouseTracker.getArea());

        if (!startPos) {
            return;
        }

        const positionsAreEqual = this.hasSelection()
            ? isSelectionInclude(startPos, this.selectStartPosition, this.selectEndPosition)
            : equals(startPos, this.cursorPosition);
        this.setCursor(startPos, this.hasSelection());

        if (isRightMouseClick) {
            this.mouseTracker.setUp(x, y);

            if (positionsAreEqual) {
                return;
            }

            this.onDoubleClick();
        } else if (this.clickCount === 1) {
            this.clearSelection();
        } else if (this.clickCount === 2) {
            this.onDoubleClick();
        } else if (this.clickCount === 3) {
            this.onTripleClick();
        } else if (this.clickCount === 4) {
            this.onQuadrupleClick();
        }
    };

    private handleMouseMove = (e: MouseEvent) => {
        if (e.button === 2) {
            return;
        }

        if (this.mouseTracker.isActive()) {
            let [x, y] = this.calculateMousePosition(e);

            // TODO: Refactor this
            if (!x || !y || !this.offsetX || !this.offsetY) {
                return;
            }

            x = x - this.offsetX;
            y = y - this.offsetY;
            this.mouseTracker.setMove(x, y);

            // disable cursor while dragging
            this.cursorEnabled = false;
            const selection = this.bText.getPositionsFromArea(...this.mouseTracker.getArea());
            this.setSelection(...(selection as [ShapedTextPosition, ShapedTextPosition]));
        }
    };

    private checkForExternalInput = (e, withTimeout = false) => {
        const fn = () => {
            const id = document.activeElement?.id;
            const tagName = document.activeElement?.tagName;

            if (tagName && id !== 'text-editor-input__hidden' && (tagName === 'INPUT' || tagName === 'TEXTBOX')) {
                this.isExternalInputFocused = true;
            } else {
                this.isExternalInputFocused = false;
                this.inputRef?.focus();
            }
        };

        if (withTimeout) {
            setTimeout(() => fn());
        } else {
            fn();
        }
    };

    private handleContainerMouseUp = (e: MouseEvent) => {
        const { tagName } = e.target as HTMLElement;

        // NOTE: This is a weird moment. Focus input but only in certain cases
        if (tagName !== 'CANVAS') {
            this.checkForExternalInput(e, true);

            return;
        }

        if (e.button === 2) {
            return;
        }

        let [x, y] = this.calculateMousePosition(e);

        if (!x || !y || !this.offsetX || !this.offsetY) {
            return;
        }

        x = x - this.offsetX;
        y = y - this.offsetY;
        this.mouseTracker.setUp(x, y);

        if (!this.bText) {
            return;
        }

        const [startPos, endPos] = this.bText.getPositionsFromArea(...this.mouseTracker.getArea());

        if (!startPos || !endPos) {
            return;
        }

        if (
            startPos.lineIdx !== endPos.lineIdx ||
            startPos.glyphIdx !== endPos.glyphIdx ||
            startPos.subTextIdx !== endPos.subTextIdx
        ) {
            // if a selection contains at least one glyph, display it
            this.setSelection(startPos, endPos);
            this.setCursor(endPos, true);
        } else if (this.clickCount <= 1) {
            // otherwise disable selection and show cursor
            this.setCursor(startPos);
        } else if (this.inputRef) {
            this.inputRef.focus();
        }
    };

    private handleContainerKeyUp = (e: KeyboardEvent) => {
        if (this.isExternalInputFocused) {
            this.checkForExternalInput(e, false);
        }
    };
}
