import { MouseEvent, RefObject, useCallback } from 'react';

type useRedLineFn = (
    redLineRef: RefObject<HTMLDivElement | null>,
    timelineWrapperRef: RefObject<HTMLDivElement | null>,
    pointerLineInterval?: number,
    timeTooltipRef?: RefObject<HTMLDivElement> | null,
) => { handleMouseMove: (ev: MouseEvent) => void };

export const useRedLine: useRedLineFn = (
    redLineRef,
    timelineWrapperRef,
    pointerLineInterval = 10,
    timeTooltipRef = null,
) => {
    const handleMouseMove = useCallback((ev: MouseEvent): void => {
        if (timelineWrapperRef.current) {
            const { x } = timelineWrapperRef.current.getBoundingClientRect();
            const posX = ev.pageX - x + pointerLineInterval;
            redLineRef.current?.setAttribute('style', `transform: translate3d(${posX}px,0,0); display: block;`);

            if (timeTooltipRef?.current) {
                timeTooltipRef.current?.setAttribute(
                    'style',
                    `transform: translate3d(${posX}px,0,0) translateX(-50%); display: flex;`,
                );
            }
        }
    }, []);

    return { handleMouseMove };
};
