import React, { useEffect, useState } from 'react';
import { Input, Thumbnail } from '@bynder/design-system';
import { isValidHexValue } from '../../utils/isValidHexValue';
import { makeHexWithOpacity } from '../../utils/makeHexWithOpacity';

interface Props {
    value: string;
    onChange: (values: string) => void;
    opacity?: number;
}

export function ColorHexInput({ value, onChange, opacity }: Props) {
    const [displayValue, setDisplayValue] = useState('');

    useEffect(() => {
        setDisplayValue(value);
    }, [value]);

    return (
        <Input
            maxLength={7}
            icon={
                <Thumbnail
                    variant="content"
                    backgroundColor={
                        typeof opacity === 'undefined' ? value : makeHexWithOpacity({ color: value, opacity })
                    }
                    shape="circle"
                    size="xs"
                />
            }
            value={displayValue}
            onChange={(newValue) => {
                const normalizedValue = normalize(newValue);

                setDisplayValue(normalizedValue);

                if (isValidHexValue(normalizedValue) || normalizedValue.length === 0) {
                    onChange(normalizedValue);
                }
            }}
            onBlur={() => {
                if (displayValue !== value) {
                    onChange(value);
                    setDisplayValue(value);
                }
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.blur();
                }
            }}
            placeholder="#ffffff"
            aria-label="Hex"
        />
    );
}

function normalize(value: string) {
    const newValue = '#' + value.replace(/([^0-9A-F]+)/gi, '');

    return newValue.length === 1 ? '' : newValue;
}
