import styled, { css } from 'styled-components';
import { token } from '@bynder/design-system';
import { PageBodyList } from './components/list/List.styled';
import { PageBodyProps } from './projects/types';

export const PageBody = styled.div`
    flex: 1;
    padding: ${token.spacing6};

    overflow-y: auto;
`;

export const StyledPageBody = styled(PageBody)<PageBodyProps>`
    height: calc(100% - 150px);

    & .ReactVirtualized__Masonry {
        padding: calc(${token.spacing6} - ${token.spacing2}) 0; // minus reserved space for outline
    }
    ${({ isListView }) =>
        isListView
            ? css`
                  ${PageBodyList}
              `
            : css`
                  padding: 0 0 0 calc(${token.spacing6} - ${token.spacing2}); // minus reserved space for outline
                  overflow: hidden;
              `}
`;

export const StyledNoneVirtualizedMasonryPageBody = styled(PageBody)<PageBodyProps>`
    height: calc(100% - 150px);
    flex: 1;
    padding: ${token.spacing6};

    ${({ isListView }) =>
        isListView
            ? css`
                  ${PageBodyList}
              `
            : css`
                  overflow-y: auto;
              `}
`;
