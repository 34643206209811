import React from 'react';
import { Dropdown } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { type BaseMultiPageModel, type TextElement } from '@bynder-studio/render-core';
import { DEFAULT_BG_TEXT_COLOR } from '@bynder-studio/misc';
import EditorColor from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor';
import { useShotColor } from 'packages/pages/design/sidebar/shots/useShotColor';
import { useColorStyleModal } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor/components/modals/useColorStylesModal';
import { IconPlaylistAdd } from '@bynder/icons';

type Props = {
    creativeModel: BaseMultiPageModel;
    selectedElement: TextElement;
    disabled?: boolean;
};

const TextBackgroundColor = ({ selectedElement, creativeModel, disabled = false }: Props) => {
    const { translate } = useTranslate();
    const { appliedCustomColors, elementColors, onAppliedClick, handleChangeColor } = useShotColor({
        selectedElement,
        brandColorProperty: 'textBackgroundBrandColors',
        propertyName: 'textBackground.color',
        creativeModel,
    });

    const { renderColorStylesModal, openConfigureColorsModal } = useColorStyleModal({
        selectedElement,
        brandColorProperty: 'textBackgroundBrandColors',
        propertyName: 'textBackground.color',
        title: translate('editor.sidebar.shots.text.background.configure-color.modal.title'),
    });

    const color = selectedElement?.textBackground?.color || DEFAULT_BG_TEXT_COLOR;

    return (
        <>
            <EditorColor
                property="textBackground.color"
                testId="text_bg_color"
                selectedElement={selectedElement}
                color={color}
                elementColors={elementColors}
                onChange={handleChangeColor}
                onAppliedClick={onAppliedClick}
                selectedCustomColors={appliedCustomColors}
                disabled={disabled}
                configureColorsButton={
                    <Dropdown.Item isDisabled={disabled} icon={<IconPlaylistAdd />} onClick={openConfigureColorsModal}>
                        {translate('editor.sidebar.shots.text.background.configure-color')}
                    </Dropdown.Item>
                }
            />
            {renderColorStylesModal()}
        </>
    );
};

export default TextBackgroundColor;
