import AuthenticationHelper from '~/helpers/AuthenticationHelper';
import authService from '~/services/authService';

export const isJobCompleted = (job) => job.jobVariations.every((jv) => jv.status === 'COMPLETED');
export const isJobFailed = (job) => job.jobVariations.some((jv) => ['FAILED', 'UPLOADING_FAILED'].includes(jv.status));
export const renditionToUrl = (rendition) => (rendition?.status === 'RENDERED' ? rendition.storagePath : undefined);

export const makeUrlToDownload = (jobId, params = {}) => {
    return {
        url: `${PROXY_PREFIX}/variation-sets/download/${jobId}/`,
        params,
    };
};

export const download =
    ({ url, params }) =>
    () => {
        authService.checkToken().then(({ status }) => {
            if (status !== 200) {
                return;
            }

            const token = AuthenticationHelper.getToken();
            const downloadUrl = `${url}?${new URLSearchParams({ ...params, token }).toString()}`;
            const a = document.createElement('a');
            a.setAttribute('href', downloadUrl);
            a.setAttribute('target', '_blank');
            a.setAttribute('download', '');
            a.click();
        });
    };

export const upperFirst = (str) => str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase();
