import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Buffer, Badge, token } from '@bynder/design-system';
import { IconStateIndicatorXL, IconStateIndicatorXLOutline } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { contentIconMap, getCreativeSharingIcon, getFormattedTime } from 'packages/pages/components/card/utils';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { getCreatives } from 'packages/store/creatives/creatives.selectors';
import useAccessRights from 'packages/hooks/useAccessRights';
import { ActionStatus, DesignAction, Entity } from 'packages/hooks/useAccessRights/types';
import features from '~/configs/features';
import { handleToggleItem } from '~/store/creatives/creatives.actions';
import { CreativeStatus } from 'packages/store/creatives/types';
import DesignContextMenu from '../ContextualMenu';
import { Props } from './types';
import { getDesignEditLink } from '../../utils';
import { DesignCardPlaceholder } from './DesignCardImage.styled';
import defaultImage from '../../../../../src/assets/images/placeholder-card.jpg';

const PROCESSED_STATUSES = ['PROCESSED', 'NOT_FOUND', 'FAILED'];

const DesignCard = (design: Props) => {
    const { menuActions, onClick } = design;
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { creatives: designs } = useSelector(getCreatives);
    const currentCompany = useSelector(customerIdSelector);
    const { isPlatformAllowed, isEntityActionAllowed } = useAccessRights();

    const isProcessingDone = PROCESSED_STATUSES.includes(design.thumbnailStatus);

    const handleSelect = () => {
        const allChecked = designs.every((i) => {
            return (i.checked && i.id !== design.id) || (!i.checked && i.id === design.id);
        });

        if (design.id) {
            dispatch(handleToggleItem(design.id, allChecked));
        }
    };

    const isMultiVariationEnabled = isPlatformAllowed([features.MULTI_VARIATION]);
    const isViewAllowed =
        isEntityActionAllowed(DesignAction.VIEW, Entity.DESIGN, design.grantedPermissions) === ActionStatus.ALLOWED ||
        isEntityActionAllowed(DesignAction.ADD_REVIEW, Entity.DESIGN, design.grantedPermissions) ===
            ActionStatus.ALLOWED;

    const getSubText = () => {
        if (isMultiVariationEnabled) {
            if (design.status === CreativeStatus.DRAFT && !design.videosCount) {
                return translate('pages.designs.grid.unpublished');
            }

            if (!design.id) {
                return translate('pages.designs.grid.duplicating');
            }

            return translate('pages.designs.grid.numberOfVariations', { count: design.videosCount });
        }

        return '';
    };

    const iconsMap = {
        DRAFT: <IconStateIndicatorXL style={{ color: token.gray400 }} />,
        IN_REVIEW: <IconStateIndicatorXL style={{ color: token.yellow500 }} />,
        EDITS_REQUESTED: <IconStateIndicatorXL style={{ color: token.red500 }} />,
        APPROVED: <IconStateIndicatorXL style={{ color: token.green500 }} />,
        NO_STATUS: <IconStateIndicatorXLOutline style={{ color: token.gray400 }} />,
    };

    const getIcon = (isInitialIcon) => {
        if (isPlatformAllowed([features.INTERNAL_ASSET]) && design?.approvalStatus && !isInitialIcon) {
            return (
                <Badge icon={iconsMap[design.approvalStatus]} color="black-alpha" data-testid="review status badge">
                    {translate(`pages.designs.grid.card.status.${design.approvalStatus}`)}
                </Badge>
            );
        }

        return contentIconMap[design.creativeType];
    };

    return (
        <Card
            variant="clean"
            as={Link}
            to={design.id && isViewAllowed ? getDesignEditLink(design.id) : '#'}
            onClick={onClick(design)}
            contextAction={
                design.id ? (
                    <DesignContextMenu companyId={currentCompany} design={design} menuActions={menuActions} />
                ) : null
            }
            aria-label={design.name}
            selectButtonAriaLabel={`Select design ${design.name}`}
            isSelected={design.checked}
            onSelect={handleSelect}
            data-testid="design card"
        >
            <Card.Frame
                height="fluid"
                icon={getIcon(false)}
                badge={getFormattedTime(design)}
                isCheckeredBackground={design.containsTransparency}
                progressBar={!isProcessingDone && <Buffer noCornerRadius />}
            >
                {design.thumbnailImage ? (
                    <Card.Images>
                        <Card.Img
                            key={`${design.thumbnailImage}${new Date()}`} // to trigger rerender on image change
                            src={design.thumbnailImage}
                            contentResize="fit"
                            onError={(e) => (e.target.src = defaultImage)}
                        />
                    </Card.Images>
                ) : (
                    <DesignCardPlaceholder>{getIcon(true)}</DesignCardPlaceholder>
                )}
            </Card.Frame>
            <Card.Header
                title={design.name}
                isNotTruncated
                detail={getSubText()}
                icon={getCreativeSharingIcon(design, false)}
            />
        </Card>
    );
};

export default DesignCard;
