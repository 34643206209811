import React, { forwardRef, Fragment, useCallback, useImperativeHandle, useMemo } from 'react';
import { ColorParams } from '@bynder-studio/render-core';
import { equals } from 'rambda';
import { Dropdown, Button, Thumbnail, Tooltip } from '@bynder/design-system';
import { isGradientColor } from 'packages/pages/editor/RightSideMenu/FormComponents/ColorPicker/utils';
import generateTestId from '~/helpers/testIdHelpers';
import { colorParams2Color } from '~/common/editor/editorHelper';
import { GradientThumbnail } from 'packages/ds/GradientThumbnail/GradientThumbnail';

type TextColorProps = {
    colors: { name: string; colors: { name: string; color: ColorParams }[] }[];
    elementId: string;
    onChange: ({ color }: { color: ColorParams }) => void;
    disabled?: boolean;
};

export type TextColorPRef = {
    setColors: (colors: ColorParams[]) => void;
};

const toRGBA = (c: ColorParams): string => `rgba(${c.red}, ${c.green}, ${c.blue}, ${c.opacity})`;

export const TextColor = forwardRef<TextColorPRef, TextColorProps>(({ colors, elementId, disabled, onChange }, ref) => {
    const testId = useMemo(() => generateTestId(`element_text_color_${elementId}`), [elementId]);
    const [values, setValues] = React.useState<ColorParams[]>([]);

    const handleClick = useCallback<(color: ColorParams) => void>(
        (color) => {
            setValues([color]);
            onChange({ color });
        },
        [onChange],
    );

    useImperativeHandle(ref, () => ({
        setColors(textColors: ColorParams[]) {
            setValues(textColors);
        },
    }));

    return (
        <Dropdown
            trigger={({ isOpen, ...triggerProps }) => (
                <Tooltip content="Text color">
                    <Button
                        variant="clean"
                        isSmall
                        isPressed={isOpen}
                        isDisabled={disabled}
                        {...triggerProps}
                        {...testId}
                        icon={
                            isGradientColor(values[0]) ? (
                                <GradientThumbnail size="xs" {...(colorParams2Color(values[0]) as object)} />
                            ) : (
                                <Thumbnail
                                    variant="content"
                                    backgroundColor={values.length === 1 ? toRGBA(values[0]) : undefined}
                                    shape="circle"
                                    size="xs"
                                />
                            )
                        }
                        aria-label="text color"
                    />
                </Tooltip>
            )}
            position="bottom-right"
        >
            {!colors.length && (
                <Dropdown.Item onClick={() => {}} isDisabled>
                    No colors
                </Dropdown.Item>
            )}
            {colors.map((group, i) => (
                <Fragment key={i}>
                    <Dropdown.SectionTitle key={`${i}-${group.name}`}>{group.name}</Dropdown.SectionTitle>
                    {group.colors.map((colorItem, j) => (
                        <Dropdown.Item
                            key={`${i}-${j}`}
                            isChecked={
                                (values.length === 1 && equals(values[0], colorItem.color)) ||
                                values.some((v) => equals(v, colorItem.color))
                            }
                            onClick={() => handleClick(colorItem.color)}
                            icon={
                                isGradientColor(colorItem.color) ? (
                                    <GradientThumbnail size="xs" {...(colorParams2Color(colorItem.color) as object)} />
                                ) : (
                                    <Thumbnail
                                        variant="content"
                                        backgroundColor={toRGBA(colorItem.color)}
                                        shape="circle"
                                        size="xs"
                                    />
                                )
                            }
                            checkedVariant="checkmark"
                        >
                            {colorItem.name}
                        </Dropdown.Item>
                    ))}
                    {colors.length - 1 !== i && <Dropdown.Divider />}
                </Fragment>
            ))}
        </Dropdown>
    );
});
