import { ColorStop, Gradient, LinearGradient, RadialGradient } from '../ColorPicker/types';
import { makeHexWithOpacity } from '../ColorPicker/utils/makeHexWithOpacity';

const GRADIENT_ID = 'gradient';

export function toDataUrl(mimeType: string, data: string) {
    const encoded = encodeURIComponent(data);

    return `data:${mimeType},${encoded}`;
}

export function getPreview(gradient: Gradient) {
    return `<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
<defs>
${gradient.type === 'linear' ? getLinearGradient(gradient) : ''}
${gradient.type === 'radial' ? getRadialGradient(gradient) : ''}
</defs>
<rect width="64" height="64" fill="url(#${GRADIENT_ID})" />
</svg>`;
}

export function getLinearGradient({ angle = 0, stops }: LinearGradient) {
    return `<linearGradient id="${GRADIENT_ID}" gradientTransform="rotate(${angle} 0.5 0.5)" x1="50%" y1="0%" x2="50%" y2="100%">
${getColorStops(stops)}
</linearGradient>`;
}

export function getRadialGradient({ stops }: RadialGradient) {
    return `<radialGradient id="${GRADIENT_ID}">
${getColorStops(stops)}
</radialGradient>`;
}

export function getColorStops(stops: ColorStop[]): string {
    return [...stops]
        .sort((a, b) => a.position - b.position)
        .map(
            ({ color, position: offset }) => `<stop offset="${offset}%" style="stop-color:${normalizeColor(color)}" />`,
        )
        .join('\n');
}

function normalizeColor(color: ColorStop['color']): string {
    if (typeof color === 'string') {
        return color;
    }

    return makeHexWithOpacity(color);
}
