import React, { useRef } from 'react';
import styled from 'styled-components';
import { ColorStop } from '../../types';
import { useGradient } from './GradientContext';

interface Props {
    children: JSX.Element[];
}

export function Track({ children }: Props) {
    const {
        dispatch,
        gradient: { stops },
    } = useGradient();
    const trackRef = useRef<HTMLButtonElement>(null);

    function onTrackClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (!trackRef.current || event.target !== trackRef.current) {
            return;
        }

        const { left, width } = trackRef.current.getBoundingClientRect();
        const rawPosition = event.clientX - left;

        if (rawPosition < 0 || rawPosition / width > 1) {
            return;
        }

        const position = Math.floor((rawPosition / width) * 100);

        dispatch({ type: 'add-stop', payload: position });
    }

    const backgroundImage = getCSSGradient(stops);

    return (
        <Container
            type="button"
            ref={trackRef}
            style={{ backgroundImage }}
            onClick={onTrackClick}
            // todo: how can we make it accessible?
            // role="slider"
            // aria-valuemin={0}
            // aria-valuemax={100}
            // aria-valuenow={}
        >
            {children}
        </Container>
    );
}

// copypaste from Slider/styles.tsx
// todo: shall we move it to a common location or try to update existing Slider component?
const Container = styled.button`
    position: relative;
    box-sizing: border-box;
    height: 24px;
    padding: 0;
    border: 8px solid transparent;
    border-radius: 4px / 50%;
    background-clip: padding-box;
    border-inline: none;
`;

function getCSSGradient(stops: ColorStop[]) {
    // spread stops to avoid mutation
    // gradient with a single stops is not rendered correctly
    const stopsToRender = stops.length > 1 ? [...stops] : [stops[0], stops[0]];

    const colorStops = stopsToRender
        .sort((a, b) => a.position - b.position)
        .map(({ color, position }) => `${typeof color === 'string' ? color : color.color} ${position}%`)
        .join(', ');

    return `linear-gradient(to right, ${colorStops})`;
}
