import React, { FC, useCallback } from 'react';
import type { ColorParams } from '@bynder-studio/render-core';
import { Dropdown, Thumbnail } from '@bynder/design-system';
import { brandColor2ColorParams, colorParams2Color, colorToCSS } from '~/common/editor/editorHelper';
import { hexToRgb, isGradientColor, rgbToHex } from '../../../ColorPicker/utils';
import type { BrandColorOnElement } from 'packages/pages/editor/RightSideMenu/FormComponents/EditorColor';
import type { BrandColor } from 'packages/store/brandColors/types';
import { GradientThumbnail } from 'packages/ds/GradientThumbnail/GradientThumbnail';

type ColorItemProps = {
    item: BrandColor | BrandColorOnElement | ColorParams;
    selected?: boolean;
    onClick: (item: any) => void;
};

export const ColorItem: FC<ColorItemProps> = ({ item, selected, onClick }) => {
    const handleClick = useCallback(() => {
        onClick(item);
    }, [item]);

    const convertedColor = 'id' in item ? colorParams2Color(brandColor2ColorParams(item)) : colorParams2Color(item);

    return (
        <Dropdown.Item
            isChecked={selected}
            icon={
                !isGradientColor(convertedColor) ? (
                    <Thumbnail
                        shape="circle"
                        backgroundColor={
                            (item as BrandColorOnElement).hexCode
                                ? hexToRgb((item as BrandColorOnElement).hexCode, (item as BrandColorOnElement).alpha)
                                : colorToCSS(item)
                        }
                        size="xs"
                        variant="content"
                    />
                ) : (
                    <GradientThumbnail {...convertedColor} shape="circle" size="xs" />
                )
            }
            onClick={handleClick}
            checkedVariant="checkmark"
        >
            {(item as BrandColorOnElement).name || rgbToHex(colorToCSS(item)).toUpperCase()}
        </Dropdown.Item>
    );
};
