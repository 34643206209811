import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'packages/store/user/user.selectors';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { catchResponseError } from 'packages/helpers/helpers';
import RenditionsService from '~/services/RenditionsService';
import { CreativeType } from 'packages/types/creative';

export enum RenditionType {
    CONSOLE = 'CONSOLE',
    PRESENTATION = 'PRESENTATION',
    PRESENTATION_LITE = 'PRESENTATION_LITE',
    CHANNEL = 'CHANNEL',
    CUSTOMER = 'CUSTOMER',
}
export enum RenditionConfigurationType {
    VIDEO = 'VIDEO',
    IMAGE = 'IMAGE',
}
export enum RenditionConfigurationContainerType {
    'video/mp4' = 'video/mp4',
    'video/webm' = 'video/webm',
    'video/flv' = 'video/flv',
    'video/3gp' = 'video/3gp',
    'video/ts' = 'video/ts',
    'gif' = 'gif',
}
export type RenditionConfiguration = {
    id: number;
    name: string;
    displayName: string;
    type: RenditionConfigurationType;
    tool: 'FFMPEG' | 'NUKE'; // deprecated?
    parameters: string;
    extension: string;
    dynamic: boolean;
    disabled?: boolean;
    containerType?: RenditionConfigurationContainerType;
    downloadable: boolean;
    displayOrder: number;
    targets?: any; // deprecated?
};
export type RenditionProfile = {
    id: number;
    name: string;
    type: RenditionType;
    creativeType: CreativeType;
    customer: number;
    configurations: RenditionConfiguration[];
};

const useRenditionProfiles = () => {
    const dispatch = useDispatch();
    const { currentCompany } = useSelector(getUser);
    const { creativeType } = useDesign();

    const [isLoadingRenditions, setIsLoadingRenditions] = useState(true);
    const [renditionProfiles, setRenditionProfiles] = useState<RenditionConfiguration[]>([]);
    const [selectedProfile, setSelectedProfile] = useState<RenditionConfiguration>(renditionProfiles[0]);

    const deselectProfile = () => {
        setSelectedProfile(renditionProfiles[0]);
    };

    useEffect(() => {
        RenditionsService.getRenditionProfiles(currentCompany)
            .then(({ status, renditionProfiles }: { status: number; renditionProfiles: RenditionProfile[] }) => {
                if (status !== 200) {
                    return;
                }

                const filteredRenditions = renditionProfiles
                    .filter((p) => p.creativeType === creativeType)
                    .flatMap((p) => p.configurations)
                    .filter(
                        (configuration, index, self) =>
                            index === self.findIndex((item) => item.id === configuration.id),
                    );

                const sortedRenditions = filteredRenditions;

                if (sortedRenditions.length) {
                    sortedRenditions.sort((a, b) => {
                        if (!a.displayOrder && !b.displayOrder) {
                            return 0;
                        }

                        if (a.displayOrder && !b.displayOrder) {
                            return -1;
                        }

                        if (!a.displayOrder && b.displayOrder) {
                            return 1;
                        }

                        return a.displayOrder - b.displayOrder;
                    });

                    setSelectedProfile(sortedRenditions[0]);
                }

                setRenditionProfiles(sortedRenditions);
                setIsLoadingRenditions(false);
            })
            .catch(catchResponseError);
    }, [dispatch, currentCompany, creativeType]);

    return {
        isLoadingRenditions,
        setIsLoadingRenditions,
        renditionProfiles,
        setRenditionProfiles,
        selectedProfile,
        setSelectedProfile,
        deselectProfile,
    };
};

export default useRenditionProfiles;
